import { createActions } from 'redux-actions';
import keyMirror from 'key-mirror';

export const constants = keyMirror({
    DELETE_TOKEN_REQUEST: null,
    DELETE_TOKEN_SUCCESS: null,

    SAVE_TOKEN_REQUEST: null,
    SAVE_TOKEN_SUCCESS: null,

    LOGIN_REQUEST: null,
    LOGIN_SUCCESS: null,
    LOGIN_FAILURE: null,

    REGISTER_REQUEST: null,
    REGISTER_SUCCESS: null,
    REGISTER_FAILURE: null,

    LOGOUT_REQUEST: null,
    LOGOUT_SUCCESS: null,
    LOGOUT_FAILURE: null,

    SAVE_USER_DATA_REQUEST: null,

    FORGOT_PASSWORD_REQUEST: null,
    FORGOT_PASSWORD_SUCCESS: null,
    FORGOT_PASSWORD_FAILURE: null,

    RESET_PASSWORD_REQUEST: null,
    RESET_PASSWORD_SUCCESS: null,
    RESET_PASSWORD_FAILURE: null,

    UPDATE_USER_REQUEST: null,
    UPDATE_USER_SUCCESS: null,
    UPDATE_USER_FAILURE: null,

    GET_USER_DATA_REQUEST: null,
    GET_USER_DATA_SUCCESS: null,
    GET_USER_DATA_FAILURE: null,

    CONTACT_REQUEST: null,
    CONTACT_SUCCESS: null,
    CONTACT_FAILURE: null,

    CONTACT_PUBLIC_REQUEST: null,
    CONTACT_PUBLIC_SUCCESS: null,
    CONTACT_PUBLIC_FAILURE: null,

    SET_REMEBER_PATH_REQUEST: null,

    LOGIN_GOOGLE_REQUEST: null,
    LOGIN_GOOGLE_SUCCESS: null,
    LOGIN_GOOGLE_FAILURE: null,
});

export const {
    deleteTokenRequest,
    deleteTokenSuccess,

    saveTokenRequest,
    saveTokenSuccess,

    loginRequest,
    loginSuccess,
    loginFailure,

    registerRequest,
    registerSuccess,
    registerFailure,

    logoutRequest,
    logoutSuccess,
    logoutFailure,

    saveUserDataRequest,

    forgotPasswordRequest,
    forgotPasswordSuccess,
    forgotPasswordFailure,

    resetPasswordRequest,
    resetPasswordSuccess,
    resetPasswordFailure,

} = createActions(
    constants.DELETE_TOKEN_REQUEST,
    constants.DELETE_TOKEN_SUCCESS,

    constants.SAVE_TOKEN_REQUEST,
    constants.SAVE_TOKEN_SUCCESS,

    constants.LOGIN_REQUEST,
    constants.LOGIN_SUCCESS,
    constants.LOGIN_FAILURE,

    constants.REGISTER_REQUEST,
    constants.REGISTER_SUCCESS,
    constants.REGISTER_FAILURE,

    constants.LOGOUT_REQUEST,
    constants.LOGOUT_SUCCESS,
    constants.LOGOUT_FAILURE,

    constants.SAVE_USER_DATA_REQUEST,

    constants.RESET_PASSWORD_REQUEST,
    constants.RESET_PASSWORD_SUCCESS,
    constants.RESET_PASSWORD_FAILURE,

    constants.FORGOT_PASSWORD_REQUEST,
    constants.FORGOT_PASSWORD_SUCCESS,
    constants.FORGOT_PASSWORD_FAILURE,
);

export const {
    updateUserRequest,
    updateUserSuccess,
    updateUserFailure,
} = createActions(
    constants.UPDATE_USER_REQUEST,
    constants.UPDATE_USER_SUCCESS,
    constants.UPDATE_USER_FAILURE,
);

export const {
    getUserDataRequest,
    getUserDataSuccess,
    getUserDataFailure,
} = createActions(
    constants.GET_USER_DATA_REQUEST,
    constants.GET_USER_DATA_SUCCESS,
    constants.GET_USER_DATA_FAILURE,
);

export const {
    contactRequest,
    contactSuccess,
    contactFailure,
} = createActions(
    constants.CONTACT_REQUEST,
    constants.CONTACT_SUCCESS,
    constants.CONTACT_FAILURE,
);

export const {
    setRemeberPathRequest,
} = createActions(
    constants.SET_REMEBER_PATH_REQUEST,
);

export const {
    contactPublicRequest,
    contactPublicSuccess,
    contactPublicFailure,
} = createActions(
    constants.CONTACT_PUBLIC_REQUEST,
    constants.CONTACT_PUBLIC_SUCCESS,
    constants.CONTACT_PUBLIC_FAILURE,
);

export const {
    loginGoogleRequest,
    loginGoogleSuccess,
    loginGoogleFailure,
} = createActions(
    constants.LOGIN_GOOGLE_REQUEST,
    constants.LOGIN_GOOGLE_SUCCESS,
    constants.LOGIN_GOOGLE_FAILURE,
);

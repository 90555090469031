import React, { useState, useEffect } from 'react';
import { Button, Col, Row, Card, Modal, Form, Input, Spin } from 'antd';
import { LeftOutlined, CheckOutlined } from '@ant-design/icons';
import { useDispatch, useSelector } from 'react-redux';

import Main from '../../components/layout/Main';
import CustomButton from '../../components/CustomButton';

import {
    createGraveRequest,
} from '../../core/grave/graveActions';

import {
    getPaymentPlansRequest,
    selectPlanRequest,
} from '../../core/payment/paymentActions';

import history from '../../core/utils/history';
import gravePlaceholder from '../../assets/images/Nice-QRCODE.webp';

import './paymentPlans.scss';

const PaymentPlans = () => {

    const dispatch = useDispatch();
    const [addGraveModalVisible, setAddGraveModalVisible] = useState(false);
    const grave = useSelector(state => state.grave);
    const payment = useSelector(state => state.payment);
    const [form] = Form.useForm();
    const toolbar = [{ label: 'Item 1', link: '/abc', iconName: 'home' }];
    const menu = [{ label: 'Item 1', link: '/abc', iconName: 'home', style: { fontSize: 24, color: 'red' } }];

    useEffect(() => {
        dispatch(getPaymentPlansRequest());
    }, []);

    const handleAddGrave = (values) => {
        dispatch(createGraveRequest(values));
    };

    const selectPlan = (singlePlan) => {
        dispatch(selectPlanRequest(singlePlan));
        history.push({ pathname: '/checkout' });
    };

    const renderSinglePlan = (singlePlan) => {

        console.log('singlePlan listing');

        return (
            <Col xs={24} sm={12} md={12} lg={12} xl={8} key={`single-grave-${singlePlan.id}`}>
                <div className="card-wrapper">
                    <Card className="payment-plan-card" onClick={() => selectPlan(singlePlan)}>
                        <div className="card-inner-wrapper">
                            <div className="price-block-wrapper">
                                <Row>
                                    <Col xs={24} sm={24} md={14} lg={14} xl={14} className="info-row">
                                        {singlePlan?.old_price > 0
                                            ? (
                                                <div className="old-price-block">
                                                    €{singlePlan.old_price}
                                                </div>
                                            )
                                            : null}
                                        <div className="price-block">
                                            €{singlePlan.price}
                                        </div>
                                        <div className="price-info">
                                            <div>
                                                <strong className="bold-text">Vienkartinis mokėjimas</strong>
                                            </div>
                                            <div>{singlePlan.period_length} Metų laikotarpiui</div>
                                        </div>
                                    </Col>
                                    <Col xs={24} sm={24} md={10} lg={10} xl={10} className="icon-row">
                                        <img src={gravePlaceholder} alt="Virtualus Kapas" className="" title="Kapaviečių kiekis" />
                                        <p className="number">x <span>{singlePlan.graves_allowance}</span></p>
                                    </Col>
                                </Row>
                            </div>
                            <p className="plan-titile">{singlePlan.title}</p>
                            <div className="plan-description" dangerouslySetInnerHTML={{ __html: singlePlan.description }} />
                            <div className="benefits-block">
                                {singlePlan?.paymentPlanBenefits?.map(
                                    (benefit) => (
                                        <div className="benefit-wrapper">
                                            <div className="benefit-icon">
                                                <CheckOutlined />
                                            </div>
                                            {benefit.title}
                                        </div>
                                    ),
                                )}
                            </div>
                            <div className="payment-bottom-wrapper">
                                <Button className="buy-basic-button">Pasirinkti {singlePlan.title} planą</Button>
                                <p className="bottom-subtitle">Vienkartinis mokėjimas už {singlePlan.period_length} {singlePlan.period_length > 9 ? 'metų' : 'metus'}.</p>
                                <p className="bottom-small">Pasibaigus plano laikotarpiui, mokėjimo planą galėsite pratęsti su 50% nuolaida.</p>
                            </div>
                        </div>
                    </Card>
                </div>
            </Col>
        );

    };

    function backLink() {
        return (
            <Button onClick={() => console.log('Back')}>
                <LeftOutlined /> BACK
            </Button>
        );
    }

    return (
        <Main
            className="payment-plans-page-private"
            title={<div>Mokėjimo Planai</div>}
            headerTitle={<div>Graves</div>}
            menu={menu}
            menuWidth={350}
            menuPlacement="right"
            toolbar={toolbar}
            floatingHeader={false}
            floatingFooter={false}
            showFooter={false}
            backLink={backLink}>
            <Spin spinning={payment.isFetching}>
                <div className="title-wrapper">
                    <h1>Mokėjimo planai</h1>
                </div>
                <div className="description-wrapper">
                    <p>
                        Jei norite išbandyti mūsų virtualaus kapo kūrimo įrankius, Jums suteikiama galimybė nemokamai sukurti vieną virtualią kapavietę
                        ir pridėti iki septynių asmenų. Tai leis jums peržiūrėti virtualią kapavietę ir išbandyti visus siūlomus įrankius.
                        Jeigu nuspręsite pridėti QR kodą prie kapavietės, galėsite pasirinkti vieną iš mūsų mokėjimo planų.
                    </p>
                    <p>
                        Norime, kad jūsų atminimo kūrimo patirtis būtų kuo lengvesnė. Todėl, įsigydami vieną iš mūsų planų,
                        mes siūlome nemokamą pagalbą – sukurti ir patalpinti nuotraukas bei aprašymus už jus.
                        Tiesiog susisiekite su mumis per mūsų <a href="https://www.facebook.com/profile.php?id=61554191562880" target="_blank" rel="noreferrer" className="custom-text-link">Facebook puslapį</a> arba el. paštu <spam className="text-link">info@virtualuskapas.lt</spam>,
                        ir mes pasirūpinsime visa kita. Be to, jūs visada turėsite galimybę savarankiškai administruoti sukurtas kapavietes.
                    </p>
                </div>
                <div className="payment-plans-wrapper">
                    <h2 className="board-title">Paprasti Planai</h2>
                    <Row className="payment-plans-row">
                        {payment.paymentPlans
                            .filter(item => item.category === 'simple')
                            .map((item) => renderSinglePlan(item))}
                    </Row>
                    <h2 className="board-title">Ultimate Planai</h2>
                    <Row className="payment-plans-row">
                        {payment.paymentPlans
                            .filter(item => item.category === 'ultimate')
                            .map((item) => renderSinglePlan(item))}
                    </Row>
                </div>
            </Spin>
            <Modal
                title="Pridėti kapą"
                centered
                width={540}
                footer={false}
                open={addGraveModalVisible}
                onOk={() => { setAddGraveModalVisible(false); form.submit(); console.log('ok'); }}
                onCancel={() => { setAddGraveModalVisible(false); }}>
                <div className="modal-form-wrap">
                    <Form onFinish={handleAddGrave} layout="vertical">
                        <Form.Item label="Pavadinimas" name="title" rules={[{ required: true, message: 'Prašome įvesti pavadinimą!' }]}>
                            <Input />
                        </Form.Item>
                        <div>
                            <Button className="link-green btn-empty underlined" onClick={() => { setAddGraveModalVisible(false); }}>
                                Atšaukti
                            </Button>
                            <CustomButton style={{ marginLeft: 15 }} type="primary" htmlType="submit" className="wide" isFetching={grave.isFetching}>
                                Sukurti
                            </CustomButton>
                        </div>
                    </Form>
                </div>
            </Modal>
        </Main>
    );
};

export default PaymentPlans;

PaymentPlans.propTypes = {};

import React, { useState, useEffect } from 'react';
import { Button, Col, Row, Card, Modal, Form, Input, Select } from 'antd';
import { LeftOutlined } from '@ant-design/icons';
import { useDispatch, useSelector } from 'react-redux';
import { size, isEmpty } from 'underscore';

import Main from '../../components/layout/Main';
import CustomButton from '../../components/CustomButton';
import BreadcrumbNav from '../../components/layout/BreadcrumbNav';

import {
    getGravesRequest,
    selectGraveRequest,
    createGraveRequest,
} from '../../core/grave/graveActions';

import {
    getPaymentsSummaryRequest,
} from '../../core/payment/paymentActions';

import history from '../../core/utils/history';
import gravePlaceholder from '../../assets/images/grave-placeholder-full.webp';

import './graves.scss';

const Graves = () => {

    const dispatch = useDispatch();
    const [addGraveModalVisible, setAddGraveModalVisible] = useState(false);
    const grave = useSelector(state => state.grave);
    const payment = useSelector(state => state.payment);
    const [form] = Form.useForm();
    const toolbar = [{ label: 'Item 1', link: '/abc', iconName: 'home' }];
    const menu = [{ label: 'Item 1', link: '/abc', iconName: 'home', style: { fontSize: 24, color: 'red' } }];
    const breadcrumbNavigation = [{ label: 'Mano Kapai' }];

    const planOptions = [];
    Object.keys(payment?.summary).forEach((item) => {
        const singleSummary = payment?.summary[item];
        let title = 'Planas Paprastas';

        if (item === 'advanced') {
            title = 'Planas Pažangus';
        }

        if (item === 'premium') {
            title = 'Planas Premium';
        }

        if (item === 'ultimate') {
            title = 'Planas Ultimate';
        }

        if (singleSummary?.left_graves_count > 0) {
            planOptions.push({
                value: item,
                label: `${title} (liko ${singleSummary?.left_graves_count})`,
            });
        }
    });

    if (size(grave?.graves) === 0 && isEmpty(planOptions)) {
        planOptions.push({
            value: 'nemokamas',
            label: 'Nemokamas',
        });
    }

    useEffect(() => {
        dispatch(getGravesRequest());
        dispatch(getPaymentsSummaryRequest());
    }, []);

    const handleAddGrave = (values) => {
        dispatch(createGraveRequest(values));
    };

    const openSingleGrave = (graveID) => {
        dispatch(selectGraveRequest(graveID));
        history.push({ pathname: '/single-grave' });
    };

    const renderSingleGrave = (singleGrave) => (
        <Col xs={24} sm={12} md={8} lg={6} xl={6} key={`single-grave-${singleGrave.id}`}>
            <div className="card-wrapper">
                <Card className="grave-card" onClick={() => openSingleGrave(singleGrave.id)}>
                    <div className="image-content" style={{ backgroundImage: `url("${singleGrave?.pictureUrlMedium || gravePlaceholder}")` }} />
                    <p className="grave-title">{singleGrave.title}</p>
                </Card>
            </div>
        </Col>
    );

    function backLink() {
        return (
            <Button onClick={() => console.log('Back')}>
                <LeftOutlined /> BACK
            </Button>
        );
    }

    return (
        <Main
            className="grave-page"
            title="Mano Kapai"
            headerTitle={<div>Graves</div>}
            menu={menu}
            menuWidth={350}
            menuPlacement="right"
            toolbar={toolbar}
            floatingHeader={false}
            floatingFooter={false}
            showFooter={false}
            backLink={backLink}>
            <div className="grave-page-content">
                <BreadcrumbNav separator=">" navigation={breadcrumbNavigation} />
                <h2 className="board-title">Mano Kapai</h2>
                <p className="board-subtitle">
                    Sveiki atvykę į mūsų virtualių kapų portalą. Šioje platformoje mes leidžiame klientams kurti ir personalizuoti virtualius kapus.
                    Sukurę virtualų kapą, galėsite pridėti asmenis, kurie yra palaidoti Jūsų pasirinktame kape.
                    Tai yra puiki vieta prisiminti mūsų artimuosius, dalintis jų istorijomis ir puoselėti jų prisiminimus.
                </p>
                <p className="board-subtitle">
                    Norime, kad Jūsų patirtis būtų geriausia įmanoma, todėl siūlome išbandyti mūsų sistemą sukuriant vieną nemokamą virtualų kapą.
                    Tai leis Jums išbandyti visus mūsų pasiūlytus įrankius ir funkcijas be jokios rizikos.
                </p>
                <Row className="graves-row">

                    {grave.graves.map(item => (
                        renderSingleGrave(item)
                    ))}

                    <Col xs={24} sm={12} md={8} lg={6} xl={6}>
                        <div className="card-wrapper">
                            <Card className="add-card" onClick={() => setAddGraveModalVisible(true)}>
                                <span className="scrum-board-add-card-icon">
                                    <svg stroke="currentColor" fill="currentColor" strokeWidth="0" viewBox="0 0 24 24" height="1em" width="1em" xmlns="http://www.w3.org/2000/svg">
                                        <path fill="none" d="M0 0h24v24H0z" />
                                        <path d="M19 13h-6v6h-2v-6H5v-2h6V5h2v6h6v2z" />
                                    </svg>
                                </span>
                                <p className="scrum-board-add-card-text">Sukurti naują kapą</p>
                            </Card>
                        </div>
                    </Col>
                </Row>
            </div>
            <Modal
                title="Pridėti kapą"
                centered
                width={540}
                footer={false}
                open={addGraveModalVisible}
                onOk={() => { setAddGraveModalVisible(false); form.submit(); console.log('ok'); }}
                onCancel={() => { setAddGraveModalVisible(false); }}>
                <div className="modal-form-wrap">
                    <Form onFinish={handleAddGrave} layout="vertical">
                        <Form.Item label="Pavadinimas" name="title" rules={[{ required: true, message: 'Prašome įvesti pavadinimą!' }]}>
                            <Input />
                        </Form.Item>
                        <Form.Item label="Pasirinkite turimą planą" name="payment_plan" rules={[{ required: true, message: 'Prašome pasirinkti planą!' }]}>
                            <Select
                                options={planOptions} />
                        </Form.Item>
                        <div>
                            <Button className="link-green btn-empty underlined" onClick={() => { setAddGraveModalVisible(false); }}>
                                Atšaukti
                            </Button>
                            <CustomButton style={{ marginLeft: 15 }} type="primary" htmlType="submit" className="wide" isFetching={grave.isFetching}>
                                Sukurti
                            </CustomButton>
                        </div>
                    </Form>
                </div>
            </Modal>
        </Main>
    );
};

export default Graves;

Graves.propTypes = {};

import React, { useState, useEffect, useRef } from 'react';
import { Button, Modal, Form, Input, Col, Row, Card, Spin, DatePicker, Image, message, Tabs, Popconfirm } from 'antd';
import { LeftOutlined, PlusOutlined, EyeOutlined, MinusOutlined, DeleteOutlined, EyeInvisibleOutlined } from '@ant-design/icons';
import { arrayMoveImmutable } from 'array-move';
import { useDispatch, useSelector } from 'react-redux';
import { isEmpty } from 'underscore';
import dayjs from 'dayjs';
import 'moment/locale/lt';
import 'dayjs/locale/lt';
import locale from 'antd/es/date-picker/locale/lt_LT';
import { Editor } from '@tinymce/tinymce-react';
import { Link } from 'react-router-dom';
import SortableList, { SortableItem } from 'react-easy-sort';

import Main from '../../components/layout/Main';
import CustomButton from '../../components/CustomButton';
import ImageUpload from '../../components/ImageUpload';
import EditableText from '../../components/EditableText';
import BreadcrumbNav from '../../components/layout/BreadcrumbNav';
import Timeline from '../../components/Timeline';
import GuestBook from '../../components/GuestBook';

import {
    getSinglePersonRequest,
    addGravePeopleRequest,
    updateSinglePeopleRequest,
    removePeoplePictureRequest,
    addPeopleVideoRequest,
    removePeopleVideoRequest,
    deletePersonRequest,
} from '../../core/grave/graveActions';

import './singleGrave.scss';

const SingleGravePeople = () => {

    const dispatch = useDispatch();
    const [addGraveModalVisible, setAddGraveModalVisible] = useState(false);
    const [youtubeModalVisible, setYoutubeModalVisible] = useState(false);
    const [birthDate, setBirthDate] = useState(null);
    const [deathDate, setDeathDate] = useState(null);
    const [inputYoutube, setInputYoutube] = useState('');
    const grave = useSelector(state => state.grave);
    const editorRef = useRef(null);
    const toolbar = [{ label: 'Item 1', link: '/abc', iconName: 'home' }];
    const menu = [{ label: 'Item 1', link: '/abc', iconName: 'home', style: { fontSize: 24, color: 'red' } }];
    const selectedGravePeople = grave?.selectedGravePeople;
    const selectedGravePeopleId = grave?.selectedGravePeople?.id;
    const breadcrumbNavigation = [{ label: 'Mano Kapai', link: '/graves' }, { label: grave?.selectedGrave?.title, link: '/single-grave' }, { label: selectedGravePeople?.title }];
    const [galleryPictures, setGalleryPictures] = useState(selectedGravePeople.galleryPictures);

    useEffect(() => {
        dispatch(getSinglePersonRequest({ grave_people_id: grave?.selectedGravePeople?.id }));
    }, []);

    useEffect(() => {
        setGalleryPictures(selectedGravePeople.galleryPictures);
    }, [grave.isFetching]);

    useEffect(() => {
        if (!isEmpty(selectedGravePeople?.birth_date)) {
            setBirthDate(dayjs(selectedGravePeople.birth_date, 'YYYY-MM-DD HH:mm:ss'));
        } else {
            setBirthDate(dayjs());
        }

        if (!isEmpty(selectedGravePeople?.death_date)) {
            setDeathDate(dayjs(selectedGravePeople.death_date, 'YYYY-MM-DD HH:mm:ss'));
        } else {
            setDeathDate(dayjs());
        }
    }, [selectedGravePeopleId]);

    const handleAddGravePeople = (values) => {

        const data = values;
        data.grave_id = grave?.selectedGrave?.id;

        dispatch(addGravePeopleRequest(data));
        console.log('add grave people values', values);
    };

    function backLink() {
        return (
            <Button onClick={() => console.log('Back')}>
                <LeftOutlined /> BACK
            </Button>
        );
    }

    const updateSingleGravePeople = (passValue) => {
        dispatch(updateSinglePeopleRequest({ grave_people_id: grave?.selectedGravePeople?.id, ...passValue }));
    };

    const setBirthDateLocal = (newBirthDateModel) => {
        updateSingleGravePeople({ birth_date: newBirthDateModel?.format('YYYY-MM-DD 00:00:00') });
        setBirthDate(newBirthDateModel);
    };

    const setDeathDateLocal = (newBirthDateModel) => {
        updateSingleGravePeople({ death_date: newBirthDateModel?.format('YYYY-MM-DD 00:00:00') });
        setDeathDate(newBirthDateModel);
    };

    const updateDescription = () => {
        if (editorRef.current) {
            updateSingleGravePeople({ description: editorRef.current.getContent() });
        }
    };

    const renderGalleryPicture = (galleryPic, key) => (
        <SortableItem className="gallery-listing-item" key={`gallery-tiem-${key}`}>
            <div className="gallery-listing-item">
                <Image
                    src={galleryPic.thumbUrl}
                    preview={{
                        src: galleryPic.fullUrl,
                        mask: <div><EyeOutlined /> Peržiūrėti</div>,
                    }}
                    alt="galleryPic" />
                <Button
                    onClick={() => dispatch(removePeoplePictureRequest({ grave_people_id: grave?.selectedGravePeople?.id, image_id: galleryPic.id }))}
                    className="remove-image-button"
                    title="Pašalinti nuotrauką">
                    <MinusOutlined />
                </Button>
            </div>
        </SortableItem>
    );

    const renderVideoThumb = (singleVideo, key) => (
        <div key={`gallery-tiem-${key}`} className="video-listing-item">
            <iframe
                width="300"
                height="168"
                src={`https://www.youtube.com/embed/${singleVideo.video_code}`}
                title="YouTube video player"
                frameBorder="0"
                allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"
                allowFullScreen />

            <Button
                onClick={() => dispatch(removePeopleVideoRequest({ grave_people_video_id: singleVideo.id }))}
                className="remove-image-button"
                title="Pašalinti video">
                <MinusOutlined />
            </Button>
        </div>
    );

    const handleChangeYoutubeInput = (e) => {
        setInputYoutube(e.target.value);
    };

    const handleCancel = () => {
        setYoutubeModalVisible(false);
        setInputYoutube('');
    };

    const extractYoutubeCode = (input) => {
        const regex = /(?:v=|youtu\.be\/)([\w-_]+)/;
        const match = input.match(regex);
        return match ? match[1] : null;
    };

    const validateYouTubeCode = (code) => {
        if (typeof code !== 'string') return false;
        return /^[0-9a-zA-Z_-]{11}$/g.test(code);
    };

    const handleOk = () => {
        const videoCode = extractYoutubeCode(inputYoutube);

        if (videoCode) {
            dispatch(addPeopleVideoRequest({ grave_people_id: grave?.selectedGravePeople?.id, video_code: videoCode }));
        } else if (validateYouTubeCode(inputYoutube)) {
            dispatch(addPeopleVideoRequest({ grave_people_id: grave?.selectedGravePeople?.id, video_code: inputYoutube }));
        } else {
            message.error('Neteisingas YouTube adresas ar kodas');
        }

        setYoutubeModalVisible(false);
        setInputYoutube('');
    };

    const onTabChange = (key) => {
        console.log(key);
    };

    const onSortEnd = (oldIndex, newIndex) => {
        // Arrange items with new order
        const updatedItems = arrayMoveImmutable(galleryPictures, oldIndex, newIndex);

        // Set the state with the reordered array
        setGalleryPictures(updatedItems);

        // Fake sending the data to a server
        console.log('Sending following data to the server: ', updatedItems);
        updateSingleGravePeople({ images: updatedItems });
    };

    const renderGeneralTab = () => (
        <Row>
            <Col xs={24} sm={24} md={12} lg={9} xl={9}>
                <div className="col-wrapper">
                    <Card>
                        <div className="picture-wrapper">
                            <h3>Pagrindinė nuotrauka</h3>
                            {!isEmpty(grave.selectedGravePeople?.pictureUrlMedium) ? (
                                <Image
                                    label="test"
                                    preview={{
                                        src: grave.selectedGravePeople?.pictureUrl,
                                        mask: <div><EyeOutlined /> Peržiūrėti</div>,
                                    }}
                                    src={grave.selectedGravePeople?.pictureUrlMedium} />
                            ) : ''}
                            <ImageUpload
                                style={{ marginTop: 15 }}
                                makeMainImage
                                gravePeopleId={grave.selectedGravePeople?.id} />
                        </div>
                    </Card>
                </div>
                <div className="col-wrapper">
                    <Card>
                        <div className="picture-wrapper">
                            <h3>Nuotraukų galerija</h3>
                            <div className="image-gallery-listing">
                                <ImageUpload
                                    multiple
                                    gravePeopleId={grave.selectedGravePeople?.id}
                                    addIcon={<PlusOutlined />}
                                    label="Pridėti" />
                                <Image.PreviewGroup
                                    preview={{
                                        onChange: (current, prev) => console.log(`current index: ${current}, prev index: ${prev}`),
                                    }}>
                                    <SortableList onSortEnd={onSortEnd} className="list" draggedItemClassName="dragged">
                                        {!isEmpty(galleryPictures)
                                            ? galleryPictures.map((singleGalleryPicture, key) => renderGalleryPicture(singleGalleryPicture, key))
                                            : null}
                                    </SortableList>
                                </Image.PreviewGroup>
                            </div>
                        </div>
                    </Card>
                </div>
                <div className="col-wrapper">
                    <Card>
                        <div className="picture-wrapper">
                            <h3>Video galerija</h3>
                            <div className="image-gallery-listing">
                                <div className="add-youtube-button" onClick={() => setYoutubeModalVisible(true)}>
                                    <PlusOutlined />
                                    <span>Pridėti</span>
                                </div>
                                <Image.PreviewGroup
                                    preview={{
                                        onChange: (current, prev) => console.log(`current index: ${current}, prev index: ${prev}`),
                                    }}>
                                    {!isEmpty(selectedGravePeople?.videos)
                                        ? selectedGravePeople.videos.map((singleVideo, key) => renderVideoThumb(singleVideo, key))
                                        : null}
                                </Image.PreviewGroup>
                            </div>
                        </div>
                    </Card>
                </div>
            </Col>
            <Col xs={24} sm={24} md={12} lg={15} xl={15}>
                <div className="col-wrapper">
                    <Card>
                        <h3>Istorija</h3>
                        <Editor
                            apiKey="wv16eot1r79145cp3emesp6tl48ofnln4uj4anfakclv2otc"
                            onInit={(evt, editor) => { editorRef.current = editor; }}
                            initialValue={selectedGravePeople?.description}
                            init={{
                                height: 500,
                                menubar: false,
                                plugins: [
                                    'advlist autolink lists link image charmap print preview anchor',
                                    'searchreplace visualblocks code fullscreen',
                                    'insertdatetime media table paste code help wordcount',
                                ],
                                toolbar: 'undo redo | formatselect | '
                                        + 'bold italic backcolor | fontsize |alignleft aligncenter '
                                        + 'alignright alignjustify | bullist numlist outdent indent | '
                                        + 'removeformat | help',
                                content_style: 'body { font-family:Helvetica,Arial,sans-serif; font-size:14px }',
                            }} />
                        <Button type="primary" className="save-button" onClick={updateDescription}>Išsaugoti</Button>
                    </Card>
                </div>
            </Col>
        </Row>
    );

    const renderLifeEventsTab = () => (
        <Row>
            <Col span={24}>
                <div className="col-wrapper">
                    <Card>
                        <h2 className="block-title">Gyvenimo įvykiai</h2>
                        <Timeline />
                    </Card>
                </div>
            </Col>
        </Row>
    );

    const renderGuestBookTab = () => (
        <Row>
            <Col span={24}>
                <div className="col-wrapper">
                    <Card>
                        <h2 className="block-title">Svečių knyga</h2>
                        <GuestBook />
                    </Card>
                </div>
            </Col>
        </Row>
    );

    const tabs = [
        {
            key: '1',
            label: 'Pagrindinis',
            children: renderGeneralTab(),
        },
        {
            key: '2',
            label: 'Gyvenimo įvykiai',
            children: renderLifeEventsTab(),
        },
        {
            key: '3',
            label: 'Svečių knyga',
            children: renderGuestBookTab(),
        },
    ];

    return (
        <Main
            className="grave-page"
            title="Mano Kapai"
            headerTitle={<div>Graves</div>}
            menu={menu}
            menuWidth={350}
            menuPlacement="right"
            toolbar={toolbar}
            floatingHeader={false}
            floatingFooter={false}
            showFooter={false}
            backLink={backLink}>
            <Spin spinning={grave.isFetching}>
                <div className="grave-page-content">
                    <Row>
                        <Col xs={24} sm={24} md={24} lg={12} xl={12}>
                            <BreadcrumbNav separator=">" navigation={breadcrumbNavigation} />
                        </Col>
                        <Col xs={24} sm={24} md={24} lg={12} xl={12}>
                            <div className="grave-action-col">
                                <Popconfirm
                                    title={<div><h3 style={{ marginBottom: 0 }}>Ar tikrai norite pašalinti?</h3>Tai padarius visa informacija susijusi su šiuo asmeniu<br /> bus ištrinta ir nebebus galimybės atstatyti?</div>}
                                    onConfirm={() => { dispatch(deletePersonRequest({ grave_people_id: grave?.selectedGravePeople?.id })); }}
                                    okText="Taip"
                                    cancelText="Ne">
                                    <Button icon={<DeleteOutlined />}>Pašalinti</Button>
                                </Popconfirm>

                                {parseInt(selectedGravePeople?.status, 10) === 1
                                    ? <Button onClick={() => updateSingleGravePeople({ status: 0 })} icon={<EyeInvisibleOutlined />}>Paslėpti</Button>
                                    : <Button onClick={() => updateSingleGravePeople({ status: 1 })} type="primary" icon={<EyeOutlined />}>Publikuoti</Button>}

                                <Link to={`/kapaviete/${grave?.selectedGrave?.slug}/${grave?.selectedGravePeople?.id}`} target="_blank" className="btn btn-primary view-button">Peržiūrėti pakeitimus <EyeOutlined /></Link>
                            </div>
                        </Col>
                    </Row>
                    <h2 className="person-title">
                        <EditableText
                            onChange={(gravePersonTitle) => updateSingleGravePeople({ title: gravePersonTitle })}
                            value={selectedGravePeople?.title}
                            inputStyle={{ maxWidth: 300 }} />
                    </h2>
                    <div className="year-title">
                        <DatePicker
                            locale={locale}
                            onChange={(e) => setBirthDateLocal(e)}
                            value={birthDate} />
                        <div className="date-divider" />
                        <DatePicker
                            locale={locale}
                            onChange={(e) => setDeathDateLocal(e)}
                            value={deathDate} />
                    </div>
                    <Tabs
                        className="grave-tabs"
                        onChange={onTabChange}
                        type="card"
                        items={tabs} />
                </div>
            </Spin>
            <Modal
                title="Pridėti kapą"
                centered
                width={540}
                footer={false}
                open={addGraveModalVisible}
                onOk={() => { setAddGraveModalVisible(false); }}
                onCancel={() => { setAddGraveModalVisible(false); }}>
                <div className="modal-form-wrap">
                    <Form onFinish={handleAddGravePeople} layout="vertical">
                        <Form.Item label="Vardas pavardė" name="title" rules={[{ required: true, message: 'Prašome įvesti pavadinimą!' }]}>
                            <Input />
                        </Form.Item>
                        <div>
                            <Button className="link-green btn-empty underlined" onClick={() => { setAddGraveModalVisible(false); }}>
                                Atšaukti
                            </Button>
                            <CustomButton style={{ marginLeft: 15 }} type="primary" htmlType="submit" className="wide" isFetching={grave.isFetching}>
                                Sukurti
                            </CustomButton>
                        </div>
                    </Form>
                </div>
            </Modal>
            <Modal
                title="Įveskite „YouTube“ URL arba video kodą"
                visible={youtubeModalVisible}
                onOk={handleOk}
                okText="Pridėti"
                cancelText="Atšaukti"
                onCancel={handleCancel}>
                <Input placeholder="YouTube URL arba video kodas" value={inputYoutube} onChange={handleChangeYoutubeInput} />
            </Modal>
        </Main>
    );
};

export default SingleGravePeople;

SingleGravePeople.propTypes = {};

import React, { useRef, useState, useEffect } from 'react';
import { PropTypes } from 'prop-types';
import { Spin, Input, Button } from 'antd';
import { EditOutlined, SaveOutlined } from '@ant-design/icons';

const EditableText = ({ value, isFetching, style, onChange, inputStyle }) => {

    const [editable, setEditable] = useState(false);
    const [currentValue, setCurrentValue] = useState(value);
    const wrapperRef = useRef(null);

    const saveTextValue = () => {
        setEditable(false);
        onChange(currentValue);
    };

    useEffect(() => {
        function handleClickOutside(event) {
            if (wrapperRef.current && !wrapperRef.current.contains(event.target)) {
                setTimeout(() => {
                    setEditable(false);
                }, 200);
            }
        }
        // Bind the event listener
        document.addEventListener('mousedown', handleClickOutside);
        return () => {
            // Unbind the event listener on clean up
            document.removeEventListener('mousedown', handleClickOutside);
        };
    }, [wrapperRef]);

    return (
        <span className="editable-upper-wrapper">
            <span ref={wrapperRef} onClick={() => { setEditable(true); }} disabled={isFetching} style={style} className="editable-wrapper">
                {editable === true ? (
                    <div>
                        <Input
                            placeholder="Basic usage"
                            value={currentValue}
                            onChange={(e) => setCurrentValue(e.target.value)}
                            onPressEnter={() => { saveTextValue(); }}
                            style={inputStyle} />
                    </div>
                ) : <div>{currentValue} <EditOutlined /></div> }
                {isFetching ? <Spin style={{ marginLeft: 10 }} /> : null}
            </span>
            {editable === true ? (
                <Button
                    onClick={() => saveTextValue()}
                    title="Išsaugoti"
                    className="editable-text-save-button"
                    shape="circle"
                    icon={<SaveOutlined />} />
            ) : null }
        </span>
    );
};

EditableText.propTypes = {
    children: PropTypes.any,
    style: PropTypes.object,
    inputStyle: PropTypes.object,
    disabled: PropTypes.bool,
    label: PropTypes.string,
    onChange: PropTypes.func,
    isFetching: PropTypes.bool,
    className: PropTypes.string,
    value: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
};

EditableText.defaultProps = {
    children: '',
    disabled: false,
    label: '',
    value: '',
    onChange: () => {},
    isFetching: false,
    className: 'link-green btn-empty underlined',
    style: {},
    inputStyle: {},
};

export default EditableText;

import { createActions } from 'redux-actions';
import keyMirror from 'key-mirror';

export const constants = keyMirror({
    GET_PAYMENT_PLANS_REQUEST: null,
    GET_PAYMENT_PLANS_SUCCESS: null,
    GET_PAYMENT_PLANS_FAILURE: null,

    CONFIRM_PLAN_REQUEST: null,
    CONFIRM_PLAN_SUCCESS: null,
    CONFIRM_PLAN_FAILURE: null,

    SELECT_PLAN_REQUEST: null,

    GET_PAYMENTS_SUMMARY_REQUEST: null,
    GET_PAYMENTS_SUMMARY_SUCCESS: null,
    GET_PAYMENTS_SUMMARY_FAILURE: null,
});

export const {
    getPaymentPlansRequest,
    getPaymentPlansSuccess,
    getPaymentPlansFailure,
} = createActions(
    constants.GET_PAYMENT_PLANS_REQUEST,
    constants.GET_PAYMENT_PLANS_SUCCESS,
    constants.GET_PAYMENT_PLANS_FAILURE,
);

export const {
    confirmPlanRequest,
    confirmPlanSuccess,
    confirmPlanFailure,
} = createActions(
    constants.CONFIRM_PLAN_REQUEST,
    constants.CONFIRM_PLAN_SUCCESS,
    constants.CONFIRM_PLAN_FAILURE,
);

export const {
    selectPlanRequest,
} = createActions(
    constants.SELECT_PLAN_REQUEST,
);

export const {
    getPaymentsSummaryRequest,
    getPaymentsSummarySuccess,
    getPaymentsSummaryFailure,
} = createActions(
    constants.GET_PAYMENTS_SUMMARY_REQUEST,
    constants.GET_PAYMENTS_SUMMARY_SUCCESS,
    constants.GET_PAYMENTS_SUMMARY_FAILURE,
);

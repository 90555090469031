import React from 'react';
import Helmet from 'react-helmet';
import { Link, useParams } from 'react-router-dom'; // import useParams
import { ArrowLeftOutlined } from '@ant-design/icons';
import iliustration1 from '../../assets/images/hp-img-5.webp';

import './confirmOrder.scss';

const CancelOrder = () => {

    const { orderId } = useParams();
    return (
        <div className="ui container not-found">
            <Helmet title="Puslapis nerastas" />
            <div className="ui icon message">
                <i className="warning sign icon" />
                <div className="content">
                    <img src={iliustration1} alt="Virtualus kapas" />
                    <h1>Užsakymas patvirtintas!</h1>
                    <h3>Užsakymo numeris: {orderId}</h3>
                    <p>Jūsų užsakymas sėkmingai gautas ir patvirtintas.<br />Išsiųsime Jums užsakyto plano numatytą QR kodą (kodus) per artimiausias 5 darbo dienas.</p>
                    <Link className="btn btn-primary" to="/dashboard"><ArrowLeftOutlined /> Grįžti į darbalaukį</Link>
                </div>
            </div>
        </div>
    );
};

export default CancelOrder;

import React from 'react';
import { Link } from 'react-router-dom';
import './siteFooter.scss';

import useUser from '../../core/user/useUser';
import logoImage from '../../assets/images/tvg-lotus-white-3.svg';

const SiteFooter = () => {

    const { user } = useUser();

    return (
        <div className="footer">
            <Link to="/" className="logo-link w-nav-brand">
                <div className="logo-wrap-footer">
                    <img src={logoImage} loading="lazy" alt="" className="lotus-img" />
                    <div className="logo-text white">Virtualus Kapas</div>
                </div>
            </Link>
            <div className="footer-nav-menu">
                <Link to="/" className="nav-link footer-nav">Pagrindinis</Link>
                <Link to="/video" className="nav-link footer-nav">Video</Link>
                <Link to="/apie-mus" className="nav-link footer-nav">Apie mus</Link>
                <Link to="/dazniausiai-uzduodami-klausimai" className="nav-link footer-nav">D.U.K.</Link>
                <Link to="/privatumo-politika" className="nav-link footer-nav">Privatumo Politika</Link>
                <Link to="/pirkimo-taisykles" className="nav-link footer-nav">Pirkimo Taisyklės</Link>
                {user?.isLoggedIn
                    ? <Link to="/dashboard" className="nav-link footer-nav sign-in">Darbalaukis</Link>
                    : <Link to="/prisijungti" className="nav-link footer-nav sign-in">Prisijungti</Link>}
            </div>
        </div>
    );
};

export default SiteFooter;

import React, { useEffect, useState } from 'react';
import { Link } from 'react-router-dom';
import { Drawer } from 'antd';

import {
    MenuOutlined,
    CloseOutlined,
} from '@ant-design/icons';

import './siteNavigation.scss';
import useUser from '../../core/user/useUser';
import logoImage from '../../assets/images/tvg-lotus-3.svg';

const SiteNavigation = () => {

    const [lastScrollTop, setLastScrollTop] = useState(0);
    const [navbarClass, setNavbarClass] = useState('navbar w-nav');
    const [open, setOpen] = useState(false);
    const { user } = useUser();

    const endOfUrl = window.location.pathname.split('/').pop();

    const showDrawer = () => {
        setOpen(true);
    };

    const onClose = () => {
        setOpen(false);
    };

    useEffect(() => {
        const navbarHeight = document.querySelector('.navbar').offsetHeight;
        const onScroll = () => {
            const st = window.pageYOffset || document.documentElement.scrollTop;
            if (Math.abs(lastScrollTop - st) <= 5) return;

            if (st > lastScrollTop && st > navbarHeight && lastScrollTop !== 0) {
                setNavbarClass('navbar w-nav nav-up');
            } else if (st + window.innerHeight < document.documentElement.scrollHeight) {
                setNavbarClass('navbar w-nav');
            }
            setLastScrollTop(st <= 0 ? 0 : st);
        };

        window.addEventListener('scroll', onScroll);
        return () => {
            window.removeEventListener('scroll', onScroll);
        };
    }, [lastScrollTop]);

    useEffect(() => {
        window.scrollTo(0, 0);
    }, [endOfUrl]);

    return (
        <>
            <div className={navbarClass}>
                <div className="nav-container dekstop-navigation">
                    <nav role="navigation" className="nav-menu w-nav-menu">
                        <Link to="/" aria-current="page" className={`nav-link w-nav-link ${endOfUrl === '' ? 'w--current' : ''}`}>Pagrindinis</Link>
                        <Link to="/apie-mus" className={`nav-link w-nav-link ${endOfUrl === 'apie-mus' ? 'w--current' : ''}`}>Apie mus</Link>
                        <Link to="/video" className={`nav-link w-nav-link ${endOfUrl === 'video' ? 'w--current' : ''}`}>Video</Link>
                        <Link to="/dazniausiai-uzduodami-klausimai" className={`nav-link w-nav-link ${endOfUrl === 'dazniausiai-uzduodami-klausimai' ? 'w--current' : ''}`}>D.U.K.</Link>
                        { /* <Link to="/kapavietes" className={`nav-link w-nav-link ${endOfUrl === 'kapavietes' ? 'w--current' : ''}`}>Kapavietės</Link> */ }
                    </nav>
                    <Link to="/" className="logo-link nav w-nav-brand">
                        <div className="logo-wrap">
                            <img src={logoImage} loading="lazy" alt="" className="lotus-img" />
                            <div className="logo-text">Virtualus Kapas</div>
                        </div>
                    </Link>
                    <nav role="navigation" className="nav-menu w-nav-menu">
                        <Link to="/mokejimo-planai" className={`nav-link w-nav-link ${endOfUrl === 'mokejimo-planai' ? 'w--current' : ''}`}>Mokėjimo planai</Link>
                        <Link to="/kontaktai" className={`nav-link w-nav-link ${endOfUrl === 'kontaktai' ? 'w--current' : ''}`}>Kontaktai</Link>
                        { /* <Link to="/kapaviete" className="nav-link w-nav-link">Contact</Link> */ }
                        {user?.isLoggedIn
                            ? <Link to="/dashboard" className="nav-link sign-in w-nav-link">Darbalaukis</Link>
                            : <Link to="/prisijungti" className="nav-link sign-in w-nav-link">Prisijungti</Link>}
                    </nav>
                    <div className="w-nav-button" onClick={() => showDrawer()}>
                        <MenuOutlined />
                    </div>
                </div>
            </div>
            <Drawer
                placement="left"
                closable={false}
                onClose={onClose}
                open={open}>
                <div className="main-site-drawer">
                    <div className="side-menu-header">
                        <div className="close-button" onClick={() => onClose()}>
                            <CloseOutlined />
                        </div>
                    </div>
                    <div className="drawer-header">
                        <Link to="/" className="logo-link">
                            <div className="logo-wrap">
                                <img src={logoImage} loading="lazy" alt="" className="lotus-img" />
                                <div className="logo-text">Virtualus Kapas</div>
                            </div>
                        </Link>
                    </div>
                    <div className="drawer-menu">
                        <Link to="/" aria-current="page" className={`nav-link w-nav-link ${endOfUrl === '' ? 'w--current' : ''}`}>Pagrindinis</Link>
                        <Link to="/apie-mus" className={`nav-link w-nav-link ${endOfUrl === 'apie-mus' ? 'w--current' : ''}`}>Apie mus</Link>
                        <Link to="/video" className={`nav-link w-nav-link ${endOfUrl === 'video' ? 'w--current' : ''}`}>Video</Link>
                        <Link to="/dazniausiai-uzduodami-klausimai" className={`nav-link w-nav-link ${endOfUrl === 'dazniausiai-uzduodami-klausimai' ? 'w--current' : ''}`}>D.U.K.</Link>
                        <Link to="/mokejimo-planai" className={`nav-link w-nav-link ${endOfUrl === 'mokejimo-planai' ? 'w--current' : ''}`}>Mokėjimo planai</Link>
                        <Link to="/kontaktai" className={`nav-link w-nav-link ${endOfUrl === 'kontaktai' ? 'w--current' : ''}`}>Kontaktai</Link>
                        {user?.isLoggedIn
                            ? <Link to="/dashboard" className="nav-link sign-in w-nav-link">Darbalaukis</Link>
                            : <Link to="/prisijungti" className="nav-link sign-in w-nav-link">Prisijungti</Link>}
                    </div>
                </div>
            </Drawer>
        </>
    );
};

export default SiteNavigation;

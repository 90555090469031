import React, { useEffect } from 'react';
import { Helmet } from 'react-helmet';
import { Col, Row } from 'antd';

import SiteNavigation from '../../components/SiteNavigation';
import SiteFooter from '../../components/SiteFooter';
import ItsFuture from '../../components/ItsFuture';

import useUser from '../../core/user/useUser';
// import history from '../../core/utils/history';
import icon from '../../assets/favicon.ico';

import './privacyPolicy.scss';

const { APP_NAME } = require('../../core/constants').default;

function PrivacyPolicy() {

    const { user } = useUser();

    useEffect(() => {

        if (user?.isLoggedIn === true) {
            // history.push('/dashboard');
        }
    }, []);

    return (
        <div className="homepage-wrap">
            <Helmet
                title="Privatumo Politika – Virtualuskapas.lt | Svarbu Mums Saugoti Jūsų Privatumą"
                titleTemplate={`${APP_NAME} - %s`}
                defaultTitle={`${APP_NAME}`}
                meta={[
                    { name: 'description', content: 'Susipažinkite su virtualuskapas.lt privatumo politika. Mes išlaikome geriausias privatumo standartas ir tvarkome Jūsų duomenis atsakingai. Mūsų komitmentas - ne tik suteikti jums galimybę kurti virtualius kapus ir užsakyti QR kodus, bet ir saugoti Jūsų asmeninę informaciją.' },
                    { property: 'og:type', content: 'website' },
                ]}>
                <link rel="shortcut icon" href={icon} />
            </Helmet>
            <SiteNavigation />
            <div className="top-faq-block">
                <div className="general-container">
                    <Row>
                        <Col xs={24} sm={24} md={24} lg={24} xl={24}>
                            <h1>Privatumo Politika</h1>
                            <p>Ši privatumo politika skirta padėti jums suprasti, kokius asmens duomenis Virtualus Kapas renka, kodėl juos renkame ir kaip tvarkome jūsų informaciją. Ši politika aiškiai taikoma mūsų svetainei https://virtualuskapas.lt ir yra integruota į mūsų paslaugų teikimo sąlygas.</p>
                            <h2>
                                Mūsų renkamos informacijos tipas
                            </h2>
                            <p>Mes renkame tiek asmeninius, tiek neasmeninius duomenis, siekdami palengvinti ir pagerinti jūsų apsipirkimo patirtį, teikti klientų aptarnavimą ir tinkamai įvykdyti jūsų užsakymus. Ši informacija apima:</p>
                            <h2>Mūsų renkamos informacijos tipas</h2>
                            <p>Mes renkame tiek asmeninius, tiek neasmeninius duomenis, siekdami palengvinti ir pagerinti jūsų apsipirkimo patirtį, teikti klientų aptarnavimą ir tinkamai įvykdyti jūsų užsakymus. Ši informacija apima:</p>
                            <ul>
                                <li>Asmens duomenys: tai informacija, kuri tiesiogiai identifikuoja jus arba gali būti netiesiogiai su jumis susijusi. Tai apima jūsų vardą, el. pašto adresą, mobiliojo telefono numerį ir pristatymo adresą.</li>
                                <li>Neasmeniniai duomenys: tai duomenys apie jūsų veiklą mūsų svetainėje ir gali apimti jūsų IP adresą, naršyklės tipą, orientaciją ir veiklą, jūsų įrenginio operacinę sistemą ir kitą techninę informaciją.</li>
                                <li>Mokėjimo informacija: perkant galime reikalauti kredito kortelės ar kitų mokėjimo duomenų. Tačiau mokėjimo duomenis tvarko saugūs trečiųjų šalių mokėjimo paslaugų teikėjai. Mes nesaugome šių duomenų savo svetainėje.</li>
                            </ul>

                            <h2>Kaip naudojame informaciją</h2>
                            <p>Jūsų Asmens duomenis naudojame užsakymų apdorojimui, pirkinių pristatymui, bendravimui apie užsakymus, produktus, paslaugas ir reklaminius pasiūlymus, mūsų parduotuvės ir platformos tobulinimui bei sukčiavimo prevencijai ar aptikimui.</p>
                            <p>Neasmeniniai duomenys naudojami analitikai ir svetainės naudojimo tobulinimui, įskaitant svetainės veiklos logistikos valdymą ir techninių problemų šalinimą.</p>

                            <h2>Slapukų politika</h2>
                            <p>Kaip ir daugelis svetainių, mes naudojame „slapukus“, kad pagerintume vartotojo patirtį. Slapukai yra nedideli failai, patalpinti jūsų įrenginyje, leidžiantys atpažinti jūsų naršyklę ir rinkti informaciją apie jūsų sąveiką su mūsų svetaine. Naršyklės nustatymuose galite pasirinkti išjungti slapukus, tačiau tai gali turėti įtakos svetainės funkcionalumui.</p>

                            <h2>Dalijimasis informacija</h2>
                            <p>Mes neparduodame ir neatskleidžiame jūsų asmeninės informacijos trečiosioms šalims, išskyrus atvejus, kai tai būtina norint įvykdyti jūsų užsakymą (pvz., pasidalyti jūsų adresu su mūsų pristatymo partneriais), laikytis teisinių reikalavimų arba apsaugoti mūsų verslo vientisumą.</p>

                            <h2>Saugumas</h2>
                            <p>Mūsų svetainė yra įdiegta naudojant naujausias technologijas, užtikrinančias jūsų duomenų saugumą. Nors nė vienas internetinis sandoris nėra 100% saugus, mes sunkiai dirbame, kad apsaugotume jūsų informaciją ir imamės priemonių mūsų svetainės ir jūsų duomenų saugumui užtikrinti.</p>

                            <h2 className="wp-block-heading">Politikos atnaujinimas</h2>
                            <p>Mes galime retkarčiais keisti šią privatumo politiką, kad atspindėtų mūsų praktikos ar paslaugų pokyčius arba teisinius ar reguliavimo įsipareigojimus. Rekomenduojame periodiškai peržiūrėti mūsų privatumo politiką, kad gautumėte naujausios informacijos.</p>

                            <h2 className="wp-block-heading">Sutikimas</h2>
                            <p>Naudodamiesi mūsų svetaine ir mūsų paslaugomis patvirtinate, kad perskaitėte ir supratote šią privatumo politiką ir sutinkate su jūsų informacijos rinkimu, naudojimu ir dalijimusi, kaip aprašyta čia.</p>

                            <h2 className="wp-block-heading">Atsiliepimų knyga</h2>
                            <p>Kai lankytojai palieka įrašus atsiliepimų knygoje, mes renkame duomenis, rodomus atsiliepimų knygos formoje, taip pat lankytojo IP adresą ir naršyklės vartotojo agento eilutę, kad būtų lengviau aptikti netinkamą informaciją.</p>
                        </Col>
                    </Row>
                </div>
            </div>
            <ItsFuture />
            <SiteFooter />
        </div>
    );
}
export default PrivacyPolicy;

import React from 'react';
import Helmet from 'react-helmet';
import { Link } from 'react-router-dom';
import { ArrowLeftOutlined } from '@ant-design/icons';
import iliustration1 from '../../assets/images/hp-img-3.png';

import './notFound.scss';

const NotFound = () => (
    <div className="ui container not-found">
        <Helmet title="Puslapis nerastas" />
        <div className="ui icon message">
            <i className="warning sign icon" />
            <div className="content">
                <img src={iliustration1} alt="Virtualus kapas" />
                <h1>Puslapis nerastas...</h1>
                <h3>Puslapis kurio ieškote neegzistuoja.</h3>
                <Link className="btn btn-primary" to="/"><ArrowLeftOutlined /> Grįžti į pradinį</Link>
            </div>
        </div>
    </div>
);

export default NotFound;

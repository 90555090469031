import { Record } from 'immutable';
// import { findIndex } from 'underscore';

const {
    GET_PAYMENT_PLANS_REQUEST,
    GET_PAYMENT_PLANS_SUCCESS,
    GET_PAYMENT_PLANS_FAILURE,

    CONFIRM_PLAN_REQUEST,
    CONFIRM_PLAN_SUCCESS,
    CONFIRM_PLAN_FAILURE,

    SELECT_PLAN_REQUEST,

    GET_PAYMENTS_SUMMARY_REQUEST,
    GET_PAYMENTS_SUMMARY_SUCCESS,
    GET_PAYMENTS_SUMMARY_FAILURE,
} = require('./paymentActions').constants;

const InitialState = Record({
    isFetching: null,
    paymentPlans: [],
    singlePlan: {},
    summary: {},
    error: null,
    updated: 1,
});

const initialState = new InitialState();

/**
 * ## galleryReducer function
 * @param {Object} state - initialState
 * @param {Object} action - type and payload
 */
export default function galleryReducer(state = initialState, { payload, type }) {
    if (!(state instanceof InitialState)) return initialState.mergeDeep(state);

    switch (type) {
    case GET_PAYMENT_PLANS_REQUEST:
    case CONFIRM_PLAN_REQUEST:
    case GET_PAYMENTS_SUMMARY_REQUEST:
        return state.set('isFetching', true)
            .set('error', null);

    case GET_PAYMENT_PLANS_SUCCESS: {
        return state.set('isFetching', false)
            .set('paymentPlans', payload.data);
    }

    case CONFIRM_PLAN_SUCCESS:
        return state.set('isFetching', true);

    case SELECT_PLAN_REQUEST:
        return state.set('singlePlan', payload);

    case GET_PAYMENTS_SUMMARY_SUCCESS:
        return state.set('isFetching', false)
            .set('summary', payload.data);

    case GET_PAYMENT_PLANS_FAILURE:
    case CONFIRM_PLAN_FAILURE:
    case GET_PAYMENTS_SUMMARY_FAILURE:
        return state.set('isFetching', false)
            .set('error', payload);

    default:
        return state;
    }
}

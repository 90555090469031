import React from 'react';
import Helmet from 'react-helmet';
import { Link } from 'react-router-dom'; // import useParams
import { ArrowLeftOutlined } from '@ant-design/icons';
import iliustration1 from '../../assets/images/hp-img-3.png';

import './cancelOrder.scss';

const CancelOrder = () => (
    <div className="ui container not-found">
        <Helmet title="Puslapis nerastas" />
        <div className="ui icon message">
            <i className="warning sign icon" />
            <div className="content">
                <img src={iliustration1} alt="Virtualus kapas" />
                <h1>Užsakymas atšauktas!</h1>
                <Link className="btn btn-primary" to="/dashboard"><ArrowLeftOutlined /> Grįžti į darbalaukį</Link>
            </div>
        </div>
    </div>
);

export default CancelOrder;

import React, { useEffect, useState } from 'react';
import { Helmet } from 'react-helmet';
import { Col, Row, Image, Button, Modal, Tabs, Card, Typography } from 'antd';
import { useSelector, useDispatch } from 'react-redux';
import { EyeOutlined, ArrowLeftOutlined } from '@ant-design/icons';
import PropTypes from 'prop-types';
import ReactHtmlParser from 'react-html-parser';
import { Link } from 'react-router-dom';
import { isEmpty } from 'underscore';

import dayjs from 'dayjs';
import 'moment/locale/lt';
import 'dayjs/locale/lt';

import {
    findGraveBySlugRequest,
    selectGravePeopleRequest,
} from '../../core/grave/graveActions';

// import history from '../../core/utils/history';
import { GraveIcon } from '../../components/Elements/CustomSVGIcon';
import SiteNavigation from '../../components/SiteNavigation';
import SiteFooter from '../../components/SiteFooter';
import ItsFuture from '../../components/ItsFuture';
import MapMarker from '../../components/MapMarker';
import Timeline from '../../components/Timeline';
import GuestBook from '../../components/GuestBook';

import useUser from '../../core/user/useUser';
// import history from '../../core/utils/history';
import icon from '../../assets/favicon.ico';

import personAvatar1 from '../../assets/images/person-avatar-1.webp';
import personAvatar2 from '../../assets/images/person-avatar-2.webp';
import personAvatar3 from '../../assets/images/person-avatar-3.webp';
import personAvatar4 from '../../assets/images/person-avatar-4.webp';
import personAvatar5 from '../../assets/images/person-avatar-5.webp';
import personAvatar6 from '../../assets/images/person-avatar-6.webp';
import personAvatar7 from '../../assets/images/person-avatar-7.webp';
import personAvatar8 from '../../assets/images/person-avatar-8.webp';
import personAvatar9 from '../../assets/images/person-avatar-9.webp';
import personAvatar10 from '../../assets/images/person-avatar-10.webp';

import flowersTop from '../../assets/images/flowers-top.webp';
import flowersBottom from '../../assets/images/flowers-bottom.webp';

import './singleGravePeoplePublic.scss';

const avatarPictures = [
    personAvatar1,
    personAvatar2,
    personAvatar3,
    personAvatar4,
    personAvatar5,
    personAvatar6,
    personAvatar7,
    personAvatar8,
    personAvatar9,
    personAvatar10,
];

const { APP_NAME } = require('../../core/constants').default;

function SingleGravePeoplePublic({ match }) {
    const { user } = useUser();
    const dispatch = useDispatch();
    const grave = useSelector(state => state.grave);
    const [mapModalVisible, setMapModalVisible] = useState(false);
    const { Paragraph } = Typography;

    useEffect(() => {
        if (user?.isLoggedIn === true) {
            // history.push('/dashboard');
        }

        if (grave.selectedGrave?.slug !== match?.params?.slug || grave.selectedGrave?.slug) {
            dispatch(findGraveBySlugRequest({ slug: match?.params?.slug }));
            setTimeout(() => dispatch(selectGravePeopleRequest(parseInt(match?.params?.id, 10))),
                1000);
        }

        dispatch(selectGravePeopleRequest(parseInt(match?.params?.id, 10)));
    }, []);

    const gravePicture = grave.selectedGravePeople?.pictureUrl ? grave.selectedGravePeople?.pictureUrl : avatarPictures[0];
    const gravePictureMedium = grave.selectedGravePeople?.pictureUrlSquare ? grave.selectedGravePeople?.pictureUrlSquare : avatarPictures[0];

    const renderGalleryPicture = (galleryPic, key) => (
        <div key={`gallery-tiem-${key}`} className="single-image-wrap">
            <Image
                alt="best image description ever"
                src={window.innerWidth > 586 ? galleryPic.thumbUrl : galleryPic.thumbMediumUrl}
                preview={{
                    src: galleryPic.fullUrl,
                    mask: <div><EyeOutlined /> Peržiūrėti</div>,
                    imageRender: () => (
                        <div>
                            <Paragraph>Type your description here  df dg sdg dsg dsg sdg sdg</Paragraph>
                        </div>
                    ),
                    toolbarRender: () => null,
                }} />
        </div>
    );

    const renderVideoThumb = (singleVideo, key) => (
        <div key={`gallery-tiem-${key}`} className="video-listing-item">
            <iframe
                width="300"
                height="168"
                src={`https://www.youtube.com/embed/${singleVideo.video_code}`}
                title="YouTube video player"
                frameBorder="0"
                allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"
                allowFullScreen />
        </div>
    );

    const renderFotoGalleryTab = () => (
        <Card className="image-gallery-card">
            <h2 className="block-title">Nuotraukų galerija</h2>
            {!isEmpty(grave.selectedGravePeople?.galleryPictures)
                ? (
                    <Image.PreviewGroup>
                        <div className="additional-images-block">
                            {grave.selectedGravePeople?.galleryPictures.map((singleGalleryPicture, key) => renderGalleryPicture(singleGalleryPicture, key))}
                        </div>
                    </Image.PreviewGroup>
                )
                : null}
        </Card>
    );

    const renderVideoGalleryTab = () => (
        <Row>
            <Col span={24}>
                <div className="col-wrapper">
                    <Card>
                        <h2 className="block-title">Video galerija</h2>
                        <div className="additional-videos-block">
                            {!isEmpty(grave.selectedGravePeople?.videos)
                                ? grave.selectedGravePeople.videos.map((singleVideo, key) => renderVideoThumb(singleVideo, key))
                                : null}
                        </div>
                    </Card>
                </div>
            </Col>
        </Row>
    );

    const renderLifeEventsTab = () => (
        <Row>
            <Col span={24}>
                <div className="col-wrapper">
                    <Card>
                        <h2 className="block-title">Gyvenimo įvykiai</h2>
                        <Timeline disableAdmin />
                    </Card>
                </div>
            </Col>
        </Row>
    );

    const renderGuestBookTab = () => (
        <Row>
            <Col span={24}>
                <div className="col-wrapper">
                    <Card>
                        <h2 className="block-title">Svečių knyga</h2>
                        <p>Čia galite dalytis savo prisiminimais, istorijomis ar mintimis apie šį asmenį. Jūsų žodžiai gali suteikti paguodos, palaikymo ir pasidalinti svarbiomis akimirkomis, kurias jūs kartu patyrėte. Neišmeskite šios progos pasidalinti ir atminti brangų jums asmenį.</p>
                        <GuestBook disableAdmin />
                    </Card>
                </div>
            </Col>
        </Row>
    );

    const tabs = [
        {
            key: '1',
            label: 'Nuotraukos',
            children: renderFotoGalleryTab(),
        },
        {
            key: '2',
            label: 'Video',
            children: renderVideoGalleryTab(),
        },
        {
            key: '3',
            label: 'Gyvenimo įvykiai',
            children: renderLifeEventsTab(),
        },
        {
            key: '4',
            label: 'Svečių knyga',
            children: renderGuestBookTab(),
        },
    ];

    const onTabChange = (key) => {
        console.log(key);
    };

    return (
        <div className="homepage-wrap">
            <Helmet
                title={`${grave?.selectedGravePeople?.title} - Kapavietė`}
                titleTemplate={`${APP_NAME} - %s`}
                defaultTitle={`${APP_NAME}`}
                meta={[
                    { name: 'description', content: `${grave?.selectedGravePeople?.title} - Kapavietė` },
                    { property: 'og:type', content: 'website' },
                    { property: 'og:image', content: gravePicture },
                ]}>
                <link rel="shortcut icon" href={icon} />
            </Helmet>
            <SiteNavigation />
            <div className="single-grave-people-top-block">
                <div className="breadcrumb-block">
                    <Link to={`/kapaviete/${match?.params?.slug}`}>
                        <GraveIcon w={18} h={18} /> <ArrowLeftOutlined /> <span>Atgal į kapavietę</span>
                    </Link>
                </div>
                <div className="general-container">
                    <img src={flowersTop} alt="flowers" className="flowers-top" />
                    <img src={flowersBottom} alt="flowers" className="flowers-bottom" />
                    <div className="general-container-inner">
                        <Row>
                            <Col xs={24} sm={24} md={24} lg={24} xl={24}>
                                <Image.PreviewGroup>
                                    <div className="main-image-wrapper">
                                        <div className="image-block">
                                            <Image
                                                label={grave?.selectedGravePeople?.title}
                                                preview={{
                                                    src: gravePicture,
                                                    mask: <div><EyeOutlined /> Peržiūrėti</div>,
                                                }}
                                                src={gravePictureMedium} />
                                        </div>
                                    </div>
                                </Image.PreviewGroup>
                            </Col>
                            <Col xs={24} sm={24} md={24} lg={24} xl={24}>
                                <div className="top-block-content">
                                    <div className="title-wrap">
                                        <h1>{grave?.selectedGravePeople?.title}</h1>
                                    </div>
                                    <p className="person-date">
                                        {dayjs(grave?.selectedGravePeople?.birth_date, 'YYYY-MM-DD HH:mm:ss').format('YYYY')} m.{' '}
                                        <span className="month">
                                            {dayjs(grave?.selectedGravePeople?.birth_date, 'YYYY-MM-DD HH:mm:ss').locale('lt').format('MMMM D')}{' '}
                                        </span>
                                        d.{' '}
                                        -{' '}
                                        {dayjs(grave?.selectedGravePeople?.death_date, 'YYYY-MM-DD HH:mm:ss').format('YYYY')} m.{' '}
                                        <span className="month">
                                            {dayjs(grave?.selectedGravePeople?.death_date, 'YYYY-MM-DD HH:mm:ss').locale('lt').format('MMMM D')}{' '}
                                        </span>
                                        d.
                                    </p>
                                    <div>{ReactHtmlParser(grave?.selectedGravePeople?.description)}</div>
                                </div>
                            </Col>
                            <Col xs={24} sm={24} md={24} lg={24} xl={24} className="additional-content-block">
                                <Tabs
                                    className="grave-tabs"
                                    onChange={onTabChange}
                                    type="card"
                                    items={tabs} />
                            </Col>
                        </Row>
                    </div>
                </div>
            </div>
            <ItsFuture />
            <SiteFooter />
            <Modal
                className="map-modal"
                title="Kapo vieta žemėlapyje"
                centered
                width={1200}
                footer={false}
                open={mapModalVisible}
                onOk={() => { setMapModalVisible(false); }}
                onCancel={() => { setMapModalVisible(false); }}>
                <div className="modal-form-wrap">
                    <MapMarker location={{ lat: parseFloat(grave?.selectedGrave?.latitude), lng: parseFloat(grave?.selectedGrave?.longitude) }} controls={false} />
                    <Button type="primary" onClick={() => { setMapModalVisible(false); }} className="map-modal-button">Uždaryti</Button>
                </div>
            </Modal>
        </div>
    );
}

SingleGravePeoplePublic.propTypes = {
    match: PropTypes.object.isRequired,
};

export default SingleGravePeoplePublic;

import React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Form, Input, Button, Spin, Divider } from 'antd';
import './contactFormPublic.scss';

import {
    contactPublicRequest,
} from '../../../core/user/userActions';

const SettingsForm = () => {

    const dispatch = useDispatch();
    const user = useSelector(state => state.user);
    const { TextArea } = Input;
    const formRef = React.useRef(null);

    const [form] = Form.useForm();

    const onFinish = (formData) => {
        const validate = true;

        if (validate) {
            const data = {
                subject: formData.subject,
                email: formData.email,
                message: formData.message,
            };
            dispatch(contactPublicRequest(data));
            setTimeout(() => formRef.current?.resetFields(), 100);
        }
    };

    const onFinishFailed = ({ errorFields }) => {
        form.scrollToField(errorFields[0].name);
    };

    return (
        <>
            <Spin spinning={user.isFetching}>
                <div className="settings-form">
                    <Form
                        form={form}
                        ref={formRef}
                        initialValues={{
                            subject: '',
                            message: '',
                        }}
                        onFinish={onFinish}
                        onFinishFailed={onFinishFailed}
                        layout="vertical">

                        <Divider />

                        <h3>Jeigu turite klausimų ar kilo kokių neaiškumų, visada galite kreiptis užpildę šią formą:</h3>

                        <Form.Item
                            name="subject"
                            label="Tema"
                            initialValue=""
                            rules={[{ required: true, message: 'Prašome įvesti temą' }]}
                            style={{ marginTop: '30px' }}>
                            <Input placeholder="Tema" />
                        </Form.Item>
                        <Form.Item
                            label="El paštas"
                            name="email"
                            rules={[{ type: 'email', message: 'Įvestis netinkamas el. pašto adresas!' }, { required: true, message: 'Prašome įvesti savo el. paštą' }]}>
                            <Input placeholder="Įrašykite savo el. paštą" />
                        </Form.Item>
                        <Form.Item
                            name="message"
                            label="Žinutė"
                            rules={[{ required: true, message: 'Prašome įvesti žinutę' }]}
                            style={{ marginTop: '30px' }}>
                            <TextArea rows={4} placeholder="Jūsų tekstas" />
                        </Form.Item>

                        <Form.Item>
                            <Button type="primary" size="large" htmlType="submit" block className="mt-3 primary-btn-style" loading={user.isFetching}>
                                Susisiekti
                            </Button>
                        </Form.Item>
                    </Form>
                </div>
            </Spin>
        </>
    );
};

export default SettingsForm;

import React, { useState, useRef } from 'react';
import { GoogleMap, LoadScript, Marker, StandaloneSearchBox } from '@react-google-maps/api';
import { PropTypes } from 'prop-types';
import { Button } from 'antd';
import { SaveOutlined } from '@ant-design/icons';

import './mapMarker.scss';

const containerStyle = {
    width: '100%',
    height: '800px',
};
const searchBoxStyle = {
    width: '300px',
    height: '40px',
    borderRadius: '5px',
    padding: '10px',
    position: 'absolute',
    top: '10px',
    left: '50%',
    marginLeft: '-150px',
    zIndex: 5,
    fontSize: 16,
};

const MapMarker = ({ saveLocation, location, controls }) => {
    const searchBox = useRef();
    const mapRef = useRef();

    let usingLocation = location;
    if (!location.lat || !location.lng) {
        usingLocation = {
            lat: 54.74290763833643,
            lng: 25.396282865806203,
        };
    }
    const [position, setPosition] = useState(usingLocation);

    const onDragEnd = (e) => {
        const newPosition = { lat: e.latLng.lat(), lng: e.latLng.lng() };
        setPosition(newPosition);
    };

    const onMapClick = (e) => {
        const newPosition = { lat: e.latLng.lat(), lng: e.latLng.lng() };
        setPosition(newPosition);
    };

    const onLoad = (map) => {
        mapRef.current = map;
        mapRef.current.setCenter(position);
    };

    const onPlacesChanged = () => {
        const place = searchBox.current.getPlaces()?.[0];
        if (place) {
            const newPosition = {
                lat: place.geometry.location.lat(),
                lng: place.geometry.location.lng(),
            };
            setPosition(newPosition);
            mapRef.current.setCenter(newPosition);
        }
    };

    return (
        <div className="guest-book-wrapper">
            {controls === true
                ? (
                    <h2 className="block-title">Pažymėkite kapo vietą žemėlapyje</h2>
                )
                : null}
            {controls === true
                ? (
                    <div className="button-wrapper">
                        <Button onClick={() => saveLocation(position)} icon={<SaveOutlined />}>Išsaugoti</Button>
                    </div>
                )
                : null}
            <LoadScript googleMapsApiKey="AIzaSyC95e9iPX2ApabSnP3FlJUnJ8S0OZkcKfU" libraries={['places']}>
                <GoogleMap
                    mapTypeId="satellite"
                    mapContainerStyle={containerStyle}
                    onClick={onMapClick}
                    zoom={16}
                    onLoad={onLoad}>
                    <>
                        {/* eslint-disable */}
                        {controls === true
                            ? (
                                <StandaloneSearchBox onLoad={(ref) => (searchBox.current = ref)}
                                    onPlacesChanged={onPlacesChanged}>
                                    <input
                                        type="text"
                                        placeholder="Ieškoti žemėlapyje"
                                        style={searchBoxStyle} />
                                </StandaloneSearchBox>
                            )
                            : null}
                        {/* eslint-enable */}
                        <Marker
                            position={position}
                            onDragEnd={onDragEnd}
                            draggable />
                    </>
                </GoogleMap>
            </LoadScript>
        </div>
    );
};

MapMarker.propTypes = {
    location: PropTypes.object,
    saveLocation: PropTypes.func,
    controls: PropTypes.bool,
};

MapMarker.defaultProps = {
    location: {
        lat: 54.74290763833643,
        lng: 25.396282865806203,
    },
    saveLocation: () => {},
    controls: true,
};

export default MapMarker;

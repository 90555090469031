import React, { useState } from 'react';
import { PropTypes } from 'prop-types';
import { message, Upload } from 'antd';
import lockr from 'lockr';
import { CameraOutlined, LoadingOutlined } from '@ant-design/icons';
import { useDispatch } from 'react-redux';

import './imageUploadGrave.css';

import {
    updateSelectedGraveImageRequest,
} from '../../core/grave/graveActions';

const getBase64 = (img, callback) => {
    const reader = new FileReader();
    reader.addEventListener('load', () => callback(reader.result));
    reader.readAsDataURL(img);
};
const beforeUpload = (file) => {
    const isJpgOrPng = file.type === 'image/jpeg' || file.type === 'image/png';
    if (!isJpgOrPng) {
        message.error('You can only upload JPG/PNG file!');
    }
    const isLt2M = file.size / 1024 / 1024 < 6;
    if (!isLt2M) {
        message.error('Image must smaller than 6MB!');
    }
    return isJpgOrPng && isLt2M;
};

const {
    API_URL,
    AUTH_KEY,
} = require('../../core/constants').default;

const apiUrl = `${API_URL}/api`;
const getAuthKey = () => lockr.get(AUTH_KEY);
const getAuthHeaders = () => ({ Authorization: `Bearer ${getAuthKey()}` });

const ImageUploadGrave = ({ isFetching, style, graveId, label, addIcon, makeMainImage }) => {
    const [loading, setLoading] = useState(false);
    const dispatch = useDispatch();
    const handleChange = (info) => {
        if (info.file.status === 'uploading') {
            setLoading(true);
            return;
        }
        if (info.file.status === 'done') {
            // Get this url from response in real world.
            if (makeMainImage) {
                getBase64(info.file.originFileObj, (url) => {
                    dispatch(updateSelectedGraveImageRequest(url));
                });
            }

            // dispatch(getSinglePersonRequest({ grave_people_id: graveId }));
            setLoading(false);
        }
    };
    const uploadButton = (
        <div>
            {loading ? <LoadingOutlined /> : addIcon}
            <div
                style={{
                    marginTop: 8,
                }}>
                {label}
            </div>
        </div>
    );
    return (
        <div style={style}>
            <Upload
                name="image"
                listType="picture-card"
                className="avatar-uploader"
                showUploadList={false}
                action={`${apiUrl}/file/image-upload?graveId=${graveId}&makeMainImage=${makeMainImage}`}
                beforeUpload={beforeUpload}
                onChange={handleChange}
                headers={getAuthHeaders()}>
                {uploadButton}
                {isFetching ? <p>Loading</p> : ''}
            </Upload>
        </div>
    );
};

ImageUploadGrave.propTypes = {
    style: PropTypes.object,
    isFetching: PropTypes.bool,
    graveId: PropTypes.oneOfType([PropTypes.string, PropTypes.number]).isRequired,
    label: PropTypes.string,
    addIcon: PropTypes.object,
    makeMainImage: PropTypes.bool,
};

ImageUploadGrave.defaultProps = {
    isFetching: false,
    style: {},
    label: 'Redaguoti',
    addIcon: <CameraOutlined />,
    makeMainImage: false,
};

export default ImageUploadGrave;

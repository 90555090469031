import React, { useEffect } from 'react';
import { Helmet } from 'react-helmet';
import { Col, Row } from 'antd';

import SiteNavigation from '../../components/SiteNavigation';
import SiteFooter from '../../components/SiteFooter';
import ItsFuture from '../../components/ItsFuture';

import useUser from '../../core/user/useUser';
// import history from '../../core/utils/history';
import icon from '../../assets/favicon.ico';

import './buyingRules.scss';

const { APP_NAME } = require('../../core/constants').default;

function BuyingRules() {

    const { user } = useUser();

    useEffect(() => {

        if (user?.isLoggedIn === true) {
            // history.push('/dashboard');
        }
    }, []);

    return (
        <div className="homepage-wrap">
            <Helmet
                title="Pirkimo Taisyklės. Suprask ir Sek Įgyvendinimo Procesą"
                titleTemplate={`${APP_NAME} - %s`}
                defaultTitle={`${APP_NAME}`}
                meta={[
                    { name: 'description', content: 'Peržiūrėkite mūsų pirkimo taisykles virtualuskapas.lt. Mes siekiame užtikrinti skaidrumą ir patogumą perkant mūsų produktyvumą ir paslaugas, įskaitant virtualių kapų kūrimą ir QR kodų užsakymą.' },
                    { property: 'og:type', content: 'website' },
                ]}>
                <link rel="shortcut icon" href={icon} />
            </Helmet>
            <SiteNavigation />
            <div className="top-faq-block">
                <div className="general-container">
                    <Row>
                        <Col xs={24} sm={24} md={24} lg={24} xl={24}>
                            <h1>Pirkimo Taisyklės</h1>
                            <ul>
                                <li>&Scaron;ios pirkimo taisyklės nustato įsipareigojimus ir teises, kurių laikosi tiek pirkėjas, tiek pardavėjas atliekant pirkimus mūsų svetainėje.</li>
                                <li>Mūsų svetaineje įsigyti gali tik asmenys, sulaukę 18 metų ir turintys teisę atlikti mokėjimus pasirinktais būdais.</li>
                            </ul>
                            <h2>Įsipareigojimai</h2>
                            <ul>
                                <li>Pirkėjui užsakant prekę (-es) sutinka su &scaron;iomis taisyklėmis.</li>
                                <li>Pardavėjas įsipareigoja pristatyti užsakytą (-as) prekę (-es) laikantis nurodytų prekių pristatymo sąlygų.</li>
                            </ul>
                            <h2>Prekių užsakymas ir pristatymas</h2>
                            <ul>
                                <li>Prekių užsakymas vyksta elektroniniu pavidalu naudojant mūsų svetainę, o apmokėjimas atliekamas naudojant Paysera mokėjimo sistemą.</li>
                                <li>Prekės pristatymą atliekame per DPD, LPEXPRESS, OMNIVA kurjerių paslaugas arba pa&scaron;tomatus.</li>
                                <li>Nuoroda į užsakymo patvirtinimo puslapį su visais užsakymo duomenimis el. pa&scaron;tu siunčiama po to, kai užsakymas yra patvirtintas ir jo apmokėjimas yra gautas.</li>
                                <li>Prekių pristatymo terminas yra 5 darbo dienos nuo užsakymo apmokėjimo.</li>
                            </ul>
                            <h2>Grąžinimo taisyklės</h2>
                            <ul>
                                <li>Užsakovui turint teisę grąžinti prekę, jis turi prane&scaron;ti pardavėjui per 14 dienų nuo prekės gavimo dienos.</li>
                                <li>Grąžinant prekes, jos turi būti nepažeistos, nepakeistos, su originalia pakuote ir etiketėmis.</li>
                                <li>Prekes grąžinti galima per 14 dienų nuo prekės gavimo dienos pirkėjo sąskaita.</li>
                                <li>Priklauso nuo pardavėjo priimti arba ne priimti grąžintą prekę, atsižvelgiant į tai, kokioje būsenoje ji yra grąžinama.</li>
                                <li>Grąžinant pinigus, jie bus grąžinti tokiu pačiu būdu, kokiame buvo gauti per 7 darbo dienas.</li>
                            </ul>
                            <h2>Atsakomybė ir teisės</h2>
                            <ul>
                                <li>Pirkėjas yra atsakingas už teisingos informacijos pateikimą užsakant prekes mūsų svetainėje.</li>
                                <li>Pardavėjas neatsako už prekių pristatymo trukdymus, atsiradusius dėl nepriklausančių nuo pardavėjo priežasčių.</li>
                                <li>Pardavėjas turi teisę at&scaron;aukti užsakymą, jei prekė yra nebegaminama arba dėl kitų nepriklausančių nuo pardavėjo priežasčių. Tokiu atveju pardavėjas įsipareigoja grąžinti pirkėjui apmokėtą užsakymo sumą.</li>
                            </ul>
                            <h2>Konfidencialumas ir asmeninės duomenų apsauga</h2>
                            <ul>
                                <li>Pirkėjo pateiktos asmeninės duomenys naudojami tik užsakymo apdorojimui; jie nėra perduodami trečiosioms &scaron;alims, i&scaron;skyrus atvejus, kai tai yra reikalinga siekiant įvykdyti užsakymą arba tai yra numatyta įstatymuose.</li>
                                <li>Pirkėjas, atlikdamas pirkimą, sutinka su jo asmeninių duomenų tvarkymu nurodytais tikslais.</li>
                            </ul>
                            <h2>Kiti aspektai</h2>
                            <ul>
                                <li>Bendravimas tarp pirkėjo ir pardavėjo atliekamas elektroniniu pa&scaron;tu, telefonu ar bet kuriuo kitu pirkėjo ir pardavėjo susitarimu penkiu būdu.</li>
                                <li>&Scaron;ios pirkimo taisyklės gali būti keičiamos ar papildomos pardavėjo be i&scaron;ankstinio įspėjimo, tačiau pakeitimai ar papildymai įsigalioja nuo to momento, kai jie yra paskelbti mūsų internetinėje svetainėje.</li>
                            </ul>
                        </Col>
                    </Row>
                </div>
            </div>
            <ItsFuture />
            <SiteFooter />
        </div>
    );
}
export default BuyingRules;

/* eslint-disable quote-props */
import { put, call, takeEvery } from 'redux-saga/effects';
import { message } from 'antd';
// import { message } from 'antd';
import { fetchApi, fetchApiAuth } from '../utils/api';
// import history from '../utils/history';

import {
    getPaymentPlansSuccess,
    getPaymentPlansFailure,

    confirmPlanSuccess,
    confirmPlanFailure,

    getPaymentsSummarySuccess,
    getPaymentsSummaryFailure,
} from './paymentActions';

const {
    GET_PAYMENT_PLANS_REQUEST,
    CONFIRM_PLAN_REQUEST,
    GET_PAYMENTS_SUMMARY_REQUEST,
} = require('./paymentActions').constants;

function* getPaymentPlans() {
    try {
        const response = yield call(fetchApi, {
            method: 'GET',
            url: '/payment-plan/get-payment-plans',
        });

        yield put(getPaymentPlansSuccess(response));
    } catch (e) {
        yield put(getPaymentPlansFailure(e.response ? e.response.data.message : e));
    }
}

function* confirmPlan({ payload }) {
    try {
        const response = yield call(fetchApiAuth, {
            method: 'POST',
            url: '/payment-plan/start-payment',
            body: payload,
        });

        if (response?.data?.paymentUrl) {
            window.location.replace(response?.data?.paymentUrl);
        }
        message.success('Pirkimas sėkmingai pradėtas');
        yield put(confirmPlanSuccess(response));
    } catch (e) {
        console.log('errorr');
        message.error(e.response ? e.response.data.message : e);
        yield put(confirmPlanFailure(e.response ? e.response.data.message : e));
    }
}

function* getPaymentsSummary() {
    try {
        const response = yield call(fetchApiAuth, {
            method: 'GET',
            url: '/payment-plan/get-payments-summary',
        });

        yield put(getPaymentsSummarySuccess(response));
    } catch (e) {
        message.error(e.response ? e.response.data.message : e);
        yield put(getPaymentsSummaryFailure(e.response ? e.response.data.message : e));
    }
}

export default function* authSaga() {
    yield* [
        takeEvery(GET_PAYMENT_PLANS_REQUEST, getPaymentPlans),
        takeEvery(CONFIRM_PLAN_REQUEST, confirmPlan),
        takeEvery(GET_PAYMENTS_SUMMARY_REQUEST, getPaymentsSummary),
    ];
}

import React from 'react';
import { Button, Card } from 'antd';
import { LeftOutlined } from '@ant-design/icons';

import Main from '../../components/layout/Main';
import BreadcrumbNav from '../../components/layout/BreadcrumbNav';
import ContactForm from '../../components/Form/ContactForm';

import './help.scss';

const Help = () => {

    const toolbar = [{ label: 'Item 1', link: '/abc', iconName: 'home' }];
    const menu = [{ label: 'Item 1', link: '/abc', iconName: 'home', style: { fontSize: 24, color: 'red' } }];
    const breadcrumbNavigation = [{ label: 'Pagalba' }];

    function backLink() {
        return (
            <Button onClick={() => console.log('Back')}>
                <LeftOutlined /> BACK
            </Button>
        );
    }

    return (
        <Main
            className="settings-page"
            title="Pagalba"
            headerTitle={<div>Graves</div>}
            menu={menu}
            menuWidth={350}
            menuPlacement="right"
            toolbar={toolbar}
            floatingHeader={false}
            floatingFooter={false}
            showFooter={false}
            backLink={backLink}>
            <div className="grave-page-content">
                <BreadcrumbNav separator=">" navigation={breadcrumbNavigation} />
                <h2 className="board-title">Pagalba</h2>

                <Card className="add-card">
                    <ContactForm />
                </Card>

            </div>
        </Main>
    );
};

export default Help;

Help.propTypes = {};

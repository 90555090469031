import React, { useEffect } from 'react';
import { Button, Col, Row, Card, Spin } from 'antd';
import { Link } from 'react-router-dom';
import { CheckOutlined } from '@ant-design/icons';
import { useDispatch, useSelector } from 'react-redux';
import { Helmet } from 'react-helmet';
import useUser from '../../core/user/useUser';

import SiteNavigation from '../../components/SiteNavigation';
import SiteFooter from '../../components/SiteFooter';
import ItsFuture from '../../components/ItsFuture';

import {
    getPaymentPlansRequest,
    selectPlanRequest,
} from '../../core/payment/paymentActions';

import history from '../../core/utils/history';
import gravePlaceholder from '../../assets/images/Nice-QRCODE.webp';
import icon from '../../assets/favicon.ico';
import flowersLeft from '../../assets/images/contact_left.webp';
import flowersRight from '../../assets/images/contact_right.webp';
import hpImg4 from '../../assets/images/hp-img-4.webp';

import './paymentPlansPublic.scss';

const { APP_NAME } = require('../../core/constants').default;

const PaymentPlansPublic = () => {

    const dispatch = useDispatch();
    const payment = useSelector(state => state.payment);
    const { user } = useUser();

    useEffect(() => {
        dispatch(getPaymentPlansRequest());
    }, []);

    const selectPlan = (singlePlan) => {
        dispatch(selectPlanRequest(singlePlan));
        history.push({ pathname: '/checkout' });
    };

    const renderSinglePlan = (singlePlan) => (
        <Col xs={24} sm={12} md={12} lg={12} xl={8} key={`single-grave-${singlePlan.id}`}>
            <div className="card-wrapper">
                <Card className="payment-plan-card" onClick={() => selectPlan(singlePlan)}>
                    <div className="card-inner-wrapper">
                        <div className="price-block-wrapper">
                            <Row>
                                <Col xs={24} sm={24} md={14} lg={14} xl={14} className="info-row">
                                    {singlePlan?.old_price > 0
                                        ? (
                                            <div className="old-price-block">
                                                €{singlePlan.old_price}
                                            </div>
                                        )
                                        : null}
                                    <div className="price-block">
                                        €{singlePlan.price}
                                    </div>
                                    <div className="price-info">
                                        <div>
                                            <strong className="bold-text">Vienkartinis mokėjimas</strong>
                                        </div>
                                        <div>{singlePlan.period_length} Metų laikotarpiui</div>
                                    </div>
                                </Col>
                                <Col xs={24} sm={24} md={10} lg={10} xl={10} className="icon-row">
                                    <img src={gravePlaceholder} alt="Virtualus Kapas" className="" title="Kapaviečių kiekis" />
                                    <p className="number">x <span>{singlePlan.graves_allowance}</span></p>
                                </Col>
                            </Row>
                        </div>
                        <p className="plan-titile">{singlePlan.title}</p>
                        <div className="plan-description" dangerouslySetInnerHTML={{ __html: singlePlan.description }} />
                        <div className="benefits-block">
                            {singlePlan?.paymentPlanBenefits?.map(
                                (benefit) => (
                                    <div className="benefit-wrapper">
                                        <div className="benefit-icon">
                                            <CheckOutlined />
                                        </div>
                                        {benefit.title}
                                    </div>
                                ),
                            )}
                        </div>
                        <div className="payment-bottom-wrapper">
                            <Button className="buy-basic-button">Pasirinkti {singlePlan.title} planą</Button>
                            <p className="bottom-subtitle">Vienkartinis mokėjimas už {singlePlan.period_length} {singlePlan.period_length > 9 ? 'metų' : 'metus'}.</p>
                            <p className="bottom-small">Pasibaigus plano laikotarpiui, mokėjimo planą galėsite pratęsti su 50% nuolaida.</p>
                        </div>
                    </div>
                </Card>
            </div>
        </Col>
    );

    return (
        <div className="payment-plans-page">
            <Helmet
                title="Mokėjimo planai"
                titleTemplate={`${APP_NAME} - %s`}
                defaultTitle={`${APP_NAME}`}
                meta={[
                    { name: 'description', content: 'Prisijunkite prie Virtualaus Kapo svetainės ir atraskite mūsų mokejimo planus. Sukurkite ilgalaikius atminimus su virtualiais kapais. Individualizavimas, interaktyvūs žemėlapiai, QR kodo generavimas ir visuotinė prieiga leis Jums puoselėti prisiminimus apie jūsų artimuosius kur bebūtumėte.' },
                    { property: 'og:type', content: 'website' },
                    { property: 'og:image', content: hpImg4 },
                ]}>
                <link rel="shortcut icon" href={icon} />
            </Helmet>
            <SiteNavigation />
            <div className="contact-us-block">
                <div className="block-inner">
                    <img src={flowersLeft} alt="flowers" className="flowers-left" />
                    <img src={flowersRight} alt="flowers" className="flowers-right" />
                    <Row>
                        <Col xs={24} sm={24} md={24} lg={24} xl={24}>
                            <div className="title-wrapper">
                                <h1>Mokėjimo planai</h1>
                            </div>

                            <div className="description-wrapper">
                                <p>
                                    Jei norite išbandyti mūsų virtualaus kapo kūrimo įrankius, Jums suteikiama galimybė nemokamai sukurti vieną virtualią kapavietę
                                    ir pridėti iki septynių asmenų. Tai leis jums peržiūrėti virtualią kapavietę ir išbandyti visus siūlomus įrankius.
                                    Jeigu nuspręsite pridėti QR kodą prie kapavietės, galėsite pasirinkti vieną iš mūsų mokėjimo planų.
                                </p>
                                <p>
                                    Norime, kad jūsų atminimo kūrimo patirtis būtų kuo lengvesnė. Todėl, įsigydami vieną iš mūsų planų,
                                    mes siūlome nemokamą pagalbą – sukurti ir patalpinti nuotraukas bei aprašymus už jus.
                                    Tiesiog susisiekite su mumis per mūsų <a href="https://www.facebook.com/profile.php?id=61554191562880" target="_blank" rel="noreferrer" className="custom-text-link">Facebook puslapį</a> arba el. paštu <spam className="text-link">info@virtualuskapas.lt</spam>,
                                    ir mes pasirūpinsime visa kita. Be to, jūs visada turėsite galimybę savarankiškai administruoti sukurtas kapavietes.
                                </p>
                                {user?.isLoggedIn
                                    ? <Link to="/dashboard" className="custom-link">Darbalaukis</Link>
                                    : <Link to="/prisijungti" className="custom-link">Prisijungti</Link>}
                            </div>

                            <Spin spinning={payment.isFetching}>
                                <div className="payment-plans-wrapper">
                                    <h2 className="board-title">Paprasti Planai</h2>
                                    <Row className="payment-plans-row">
                                        {payment.paymentPlans
                                            .filter(item => item.category === 'simple')
                                            .map((item) => renderSinglePlan(item))}
                                    </Row>
                                    <h2 className="board-title">Ultimate Planai</h2>
                                    <Row className="payment-plans-row">
                                        {payment.paymentPlans
                                            .filter(item => item.category === 'ultimate')
                                            .map((item) => renderSinglePlan(item))}
                                    </Row>
                                </div>
                            </Spin>

                        </Col>
                    </Row>
                </div>
            </div>
            <ItsFuture />
            <SiteFooter />
        </div>
    );
};

export default PaymentPlansPublic;

PaymentPlansPublic.propTypes = {};

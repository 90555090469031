import React, { useEffect } from 'react';
import { Helmet } from 'react-helmet';
import { Col, Row } from 'antd';
import Lottie from 'lottie-react';

import SiteNavigation from '../../components/SiteNavigation';
import SiteFooter from '../../components/SiteFooter';
import ItsFuture from '../../components/ItsFuture';

import useUser from '../../core/user/useUser';
// import history from '../../core/utils/history';
import icon from '../../assets/favicon.ico';
import aboutUsImage from '../../assets/images/about-us-1.webp';
import qrCodeAnimationTop from '../../assets/documents/animation_lna0wnub.json';

import './gravesListPublic.scss';

const { APP_NAME } = require('../../core/constants').default;

function GravesListPublic() {

    const { user } = useUser();

    useEffect(() => {

        if (user?.isLoggedIn === true) {
            // history.push('/dashboard');
        }
    }, []);

    return (
        <div className="homepage-wrap">
            <Helmet
                title={`${APP_NAME}`}
                titleTemplate={`${APP_NAME} - %s`}
                defaultTitle={`${APP_NAME}`}
                meta={[
                    { name: 'description', content: `${APP_NAME} website` },
                    { property: 'og:type', content: 'website' },
                ]}>
                <link rel="shortcut icon" href={icon} />
            </Helmet>
            <SiteNavigation />
            <div className="top-about-us-block">
                <div className="general-container">
                    <Row>
                        <Col xs={24} sm={24} md={24} lg={8} xl={8} className="left-container">
                            <h1>Kapavietės</h1>
                            <p>Esame komanda, pasiryžusi panaudoti technologijų galią iš naujo apibrėžti memorializacijos kraštovaizdį. Mūsų virtualioji kapų svetainė gimė iš gilios pagarbos prisiminimų išsaugojimui ir mūsų siekio sukurti ilgalaikį palikimą tiems, kurių nebėra su mumis. Mūsų novatoriška platforma skirta sujungti fizinį ir skaitmeninį pasaulį, suteikdama prasmingą, ilgalaikę duoklę jūsų artimiesiems, kurią galima pasiekti bet kada ir bet kur.</p>
                            <p>Mūsų kelionė prasidėjo, kai supratome tradicinių kapų lankymo ribotumą. Supratome, kad dėl fizinio atstumo ir laiko kai kuriems žmonėms gali būti sudėtinga lankytis kapinėse ir reguliariai pagerbti. Tikėjome, kad ryšys tarp gyvųjų ir praėjusiųjų neturi būti ribojamas geografijos ar aplinkybių.</p>
                            <div className="parallax-roundel-wrap">
                                <Lottie animationData={qrCodeAnimationTop} loop />
                            </div>
                        </Col>
                        <Col xs={24} sm={24} md={24} lg={16} xl={16} className="right-container">
                            <img src={aboutUsImage} alt="Virtualaus Kapo Komanda" className="img-responsive side-logo" />
                        </Col>
                        <Col xs={24} sm={24} md={24} lg={24} xl={24}>
                            <p>Atsižvelgdami į šią viziją, sukūrėme savo Virtualus Kapas svetainę – novatorišką platformą, kuri be pastangų sujungia fizinę ir virtualią sferą. Šiandien siūlome daugybę visapusiškų funkcijų, leidžiančių asmenims kurti individualizuotus virtualius kapus, tiksliai nustatyti kapų vietas interaktyviame žemėlapyje, generuoti pasirinktinius QR kodus fiziniams kapams sujungti su virtualiais ir dar daugiau.</p>
                            <p>Mūsų platforma skatina interaktyvų pasakojimą, leisdama vartotojams labai patraukliai dalytis brangiais prisiminimais, nuotraukomis ir vaizdo įrašais. Tai prideda atminimui gilumo ir turtingumo, kurio negalėjo suteikti tradicinė kapų lankymo praktika. Be to, kurdami palankią vartotojų bendruomenę, mūsų platforma siūlo erdvę, kurioje asmenys gali susisiekti, dalytis ir rasti paguodą sudėtingais laikais.</p>
                            <p>Mes ne tik esame atminimo platforma, bet pasitelkiame technologijas, kad prisidėtume prie aplinkos tvarumo. Sumažindami priklausomybę nuo fizinių medžiagų, manome, kad atliekame savo vaidmenį kuriant ekologiškesnę ateitį.</p>
                            <p>Kviečiame prisijungti prie mūsų gailestingos bendruomenės ir patirti naują, įtraukiantį būdą pagerbti, prisiminti ir švęsti savo artimuosius. Kartu kurkime atminimo patirtį, kuri būtų ne tik naujoviška, bet ir labai asmeniška, įsimintina ir, svarbiausia, amžina. Sveiki atvykę į mūsų Virtualus Kapas svetainę.</p>
                        </Col>
                    </Row>
                </div>
            </div>
            <ItsFuture />
            <SiteFooter />
        </div>
    );
}
export default GravesListPublic;

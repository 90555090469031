import React, { useState, useEffect } from 'react';
import { Button, Card, Col, Row, Modal, Empty, Divider } from 'antd';
import { LeftOutlined } from '@ant-design/icons';
import { useDispatch, useSelector } from 'react-redux';
import { isEmpty } from 'underscore';

import history from '../../core/utils/history';
import Main from '../../components/layout/Main';
import { IconGrave } from '../../components/Icons';
import iliustration1 from '../../assets/images/iliustration-2.png';
import gravePlaceholder from '../../assets/images/grave-placeholder-full.webp';

import {
    getPaymentsSummaryRequest,
} from '../../core/payment/paymentActions';

import {
    selectGraveRequest,
} from '../../core/grave/graveActions';

import './dashboard.scss';

const Dashboard = () => {

    const dispatch = useDispatch();
    const payment = useSelector(state => state.payment);
    const [infoModalVisible, setInfoModalVisible] = useState(false);
    const [infoKey, setInfoKey] = useState(false);
    const [selectedSummary, setSelectedSummary] = useState({});

    const menu = [{ label: 'Item 1', link: '/abc', iconName: 'home', style: { fontSize: 24, color: 'red' } }];

    useEffect(() => {
        dispatch(getPaymentsSummaryRequest());
    }, []);

    const openSingleGrave = (graveID) => {
        dispatch(selectGraveRequest(graveID));
        history.push({ pathname: '/single-grave' });
    };

    const backLink = () => (
        <Button onClick={() => console.log('Back')}>
            <LeftOutlined /> BACK
        </Button>
    );

    const openInfoModal = (category) => {
        const singleSummary = payment?.summary[category];
        setInfoKey(category);
        setInfoModalVisible(true);
        setSelectedSummary(singleSummary);
    };

    const getColor = (category) => {
        let color = 'grey';
        if (category === 'advanced') {
            color = '#32a852';
        }

        if (category === 'premium') {
            color = '#FFD700';
        }

        if (category === 'ultimate') {
            color = '#9B59B6';
        }

        return color;
    };

    const renderStatisticCard = (item) => {
        const singleSummary = payment?.summary[item];

        const color = getColor(item);
        let title = 'Paprasti';

        if (item === 'advanced') {
            title = 'Pažangūs';
        }

        if (item === 'premium') {
            title = 'Premium';
        }

        if (item === 'ultimate') {
            title = 'Ultimate';
        }

        return (
            <Card className="statistic-card" bordered={false} onClick={() => openInfoModal(item)}>
                <Row>
                    <Col span={14}>
                        <p className="statistic-title">Jūs turite nepanaudotų kapų:</p>
                        <p className="statistic-number">{singleSummary?.left_graves_count}</p>
                        <span className="spacer" />
                        <p className="statistic-title">Aktyvūs kapai:</p>
                        <p className="statistic-number">{singleSummary?.created_graves_count}</p>
                    </Col>
                    <Col span={10}>
                        <div className="right-statistic-col">
                            <IconGrave f={color} />
                            <p>{title}</p>
                        </div>
                    </Col>
                </Row>
            </Card>
        );
    };

    const renderStatisticListItem = (item, index) => (
        <Col xs={24} sm={12} md={12} lg={12} xl={8} key={`statistic-item-${index}`} className="statistic-list-item">
            {renderStatisticCard(item, index)}
        </Col>
    );

    const renderSingleGrave = (singleGrave) => (
        <div className="single-grave-item" key={`single-grave-${singleGrave?.id}`} onClick={() => openSingleGrave(singleGrave?.id)}>
            <div className="image-content" style={{ backgroundImage: `url("${singleGrave?.pictureUrlMedium || gravePlaceholder}")` }} />
            <div className="title">
                {singleGrave?.title}
            </div>
        </div>
    );

    return (
        <Main
            className="dashboard-page"
            title="Darbalaukis"
            headerTitle={<div>Darbalaukis</div>}
            menu={menu}
            menuWidth={350}
            menuPlacement="right"
            floatingHeader={false}
            footerContent={<div style={{ lineHeight: '30px', fontSize: '16px', float: 'right' }}>&copy; copyright 2022</div>}
            floatingFooter={false}
            backLink={backLink}
            showFooter={false}>
            <div className="dashboard-content-wrapper" style={{ backgroundImage: `url(${iliustration1})` }}>
                <h2>Darbalaukis</h2>
                <div className="graves-statistic-wrapper">
                    <Row gutter={16} justify="center">
                        {Object.keys(payment?.summary).map((item, index) => renderStatisticListItem(item, index))}
                    </Row>
                </div>
            </div>
            <Modal
                title="Informacija"
                className="statistic-modal"
                centered
                width={800}
                footer={false}
                open={infoModalVisible}
                onOk={() => { setInfoModalVisible(false); }}
                onCancel={() => { setInfoModalVisible(false); }}>
                <div className="statistic-modal-wrap">
                    <div className="info-card-wrapper">
                        {renderStatisticCard(infoKey)}
                    </div>
                    <div className="graves-list-wrapper">
                        <h3>Aktyvūs kapai</h3>
                        <div className="graves-listing-block">
                            {!isEmpty(selectedSummary?.graves_list)
                                ? selectedSummary?.graves_list.map(singleGrave => renderSingleGrave(singleGrave))
                                : <Empty description="Šiuo metu neturite aktyvių kapų" />}
                        </div>
                    </div>
                    <Divider />
                    <div className="modal-footer">
                        <Button type="primary" onClick={() => { history.push({ pathname: '/payment-plans' }); }}>
                            Įsigyti papildomai kapų
                        </Button>
                        <Button className="secondary-button" onClick={() => { setInfoModalVisible(false); }}>
                            Uždaryti
                        </Button>
                    </div>
                </div>
            </Modal>
        </Main>
    );
};

export default Dashboard;

Dashboard.propTypes = {};

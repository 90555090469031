import { combineReducers } from 'redux';
import storage from 'redux-persist/lib/storage';
import global from './global/globalReducer';
import user from './user/userReducer';
import grave from './grave/graveReducer';
import payment from './payment/paymentReducer';

/**
 * ## CombineReducers
 *
 * the rootReducer will call each and every reducer with the state and action
 * EVERY TIME there is a basic action
 */
const appReducers = combineReducers({
    // ... your other reducers here ...
    global,
    user,
    grave,
    payment,
});

const rootReducer = (state, action) => {
    // ... your other reducers here ...
    if ((action.type).indexOf('_FAILURE') !== -1) {
        const statusCode = (action && action.payload && action.payload.response && action.payload.response.status) || 404;
        console.log('>>>>>>>>>>>>>', statusCode, action);
        if (statusCode === 401 || action?.payload === 'Token expired. Please login again.' || action?.payload === 'Your request was made with invalid credentials.') {
            // Session expired log the user out
            // Set all state to initial state
            storage.removeItem('persist:root');
            window.location.href = '/prisijungti';
            return appReducers(undefined, action);
            // const states = Object.keys(state);
            // states.forEach((singleState) => {
            //     if (typeof initialStates[singleState] !== 'undefined') {
            //         state[singleState] = initialStates[singleState];
            //     }
            // });
            // localStorage.clear();
        }
    }

    return appReducers(state, action);
};

export default rootReducer;

import React, { useEffect } from 'react';
import { Button, Col, Row, Card, Spin } from 'antd';
import { LeftOutlined, CheckOutlined } from '@ant-design/icons';
import { useDispatch, useSelector } from 'react-redux';

import Main from '../../components/layout/Main';
import CheckoutForm from '../../components/Form/CheckoutForm';

import {
    getPaymentPlansRequest,
} from '../../core/payment/paymentActions';
import gravePlaceholder from '../../assets/images/Nice-QRCODE.webp';

import './checkout.scss';

const Checkout = () => {

    const dispatch = useDispatch();
    const payment = useSelector(state => state.payment);
    const toolbar = [{ label: 'Item 1', link: '/abc', iconName: 'home' }];
    const menu = [{ label: 'Item 1', link: '/abc', iconName: 'home', style: { fontSize: 24, color: 'red' } }];

    useEffect(() => {
        dispatch(getPaymentPlansRequest());
    }, []);

    const renderSinglePlan = (singlePlan) => {

        console.log('singlePlan listing');

        return (
            <Col xs={24} sm={24} md={12} lg={12} xl={8} key={`single-grave-${singlePlan.id}`}>
                <div className="card-wrapper">
                    <Card className="payment-plan-card">
                        <div className="card-inner-wrapper">
                            <div className="price-block-wrapper">
                                <Row>
                                    <Col xs={24} sm={14} md={14} lg={14} xl={14} className="info-row">
                                        <div className="price-block">
                                            €{singlePlan.price}
                                        </div>
                                        <div className="price-info">
                                            <div>
                                                <strong className="bold-text">Vienkartinis mokėjimas</strong>
                                            </div>
                                            <div>{singlePlan.period_length} Metų laikotarpiui</div>
                                        </div>
                                    </Col>
                                    <Col xs={24} sm={10} md={10} lg={10} xl={10} className="icon-row">
                                        <img src={gravePlaceholder} alt="Virtualus Kapas" className="" title="Kapaviečių kiekis" />
                                        <p className="number">x <span>{singlePlan.graves_allowance}</span></p>
                                    </Col>
                                </Row>
                            </div>
                            <p className="plan-titile">{singlePlan.title}</p>
                            <div className="plan-description" dangerouslySetInnerHTML={{ __html: singlePlan.description }} />
                            <div className="benefits-block">
                                {singlePlan?.paymentPlanBenefits?.map(
                                    (benefit) => (
                                        <div className="benefit-wrapper">
                                            <div className="benefit-icon">
                                                <CheckOutlined />
                                            </div>
                                            {benefit.title}
                                        </div>
                                    ),
                                )}
                            </div>
                            <div className="payment-bottom-wrapper">
                                <p className="bottom-subtitle">Vienkartinis mokėjimas už {singlePlan.period_length} {singlePlan.period_length > 9 ? 'metų' : 'metus'}.</p>
                            </div>
                        </div>
                    </Card>
                </div>
            </Col>
        );

    };

    function backLink() {
        return (
            <Button onClick={() => console.log('Back')}>
                <LeftOutlined /> BACK
            </Button>
        );
    }

    return (
        <Main
            className="checkout-page"
            title={<div>Mokėjimo Planai</div>}
            headerTitle={<div>Graves</div>}
            menu={menu}
            menuWidth={350}
            menuPlacement="right"
            toolbar={toolbar}
            floatingHeader={false}
            floatingFooter={false}
            showFooter={false}
            backLink={backLink}>
            <Spin spinning={payment.isFetching}>
                <div className="grave-page-content">
                    <Row className="payment-plans-row">
                        {renderSinglePlan(payment.singlePlan)}
                        <Col xs={24} sm={24} md={12} lg={12} xl={12}>
                            <div className="card-wrapper">
                                <Card className="payment-plan-card">
                                    <div className="card-inner-wrapper">
                                        <CheckoutForm />
                                    </div>
                                </Card>
                            </div>
                        </Col>
                    </Row>
                </div>
            </Spin>
        </Main>
    );
};

export default Checkout;

Checkout.propTypes = {};

/* eslint-disable quote-props */
import { put, call, takeEvery } from 'redux-saga/effects';
import { message } from 'antd';
import { fetchApiAuth, fetchApi } from '../utils/api';
import history from '../utils/history';

import {
    getGravesSuccess,
    getGravesFailure,

    getSingleGraveSuccess,
    getSingleGraveFailure,

    createGraveSuccess,
    createGraveFailure,

    getGravePeopleSuccess,
    getGravePeopleFailure,

    addGravePeopleSuccess,
    addGravePeopleFailure,

    updateSinglePeopleSuccess,
    updateSinglePeopleFailure,

    getSinglePersonSuccess,
    getSinglePersonFailure,

    removePeoplePictureSuccess,
    removePeoplePictureFailure,

    addPeopleVideoSuccess,
    addPeopleVideoFailure,

    removePeopleVideoSuccess,
    removePeopleVideoFailure,

    saveLifeEventsSuccess,
    saveLifeEventsFailure,

    removeGuestBookRecordSuccess,
    removeGuestBookRecordFailure,

    deletePersonSuccess,
    deletePersonFailure,

    updateSingleGraveSuccess,
    updateSingleGraveFailure,

    findByQrCodeSuccess,
    findByQrCodeFailure,

    findGraveBySlugSuccess,
    findGraveBySlugFailure,

    sendGuestBookRecordSuccess,
    sendGuestBookRecordFailure,

    generateQrCodeForGraveSuccess,
    generateQrCodeForGraveFailure,
} from './graveActions';

const {
    GET_GRAVES_REQUEST,
    GET_SINGLE_GRAVE_REQUEST,
    CREATE_GRAVE_REQUEST,
    GET_GRAVE_PEOPLE_REQUEST,
    ADD_GRAVE_PEOPLE_REQUEST,
    UPDATE_SINGLE_PEOPLE_REQUEST,
    GET_SINGLE_PERSON_REQUEST,
    REMOVE_PEOPLE_PICTURE_REQUEST,
    ADD_PEOPLE_VIDEO_REQUEST,
    REMOVE_PEOPLE_VIDEO_REQUEST,
    SAVE_LIFE_EVENTS_REQUEST,
    REMOVE_GUEST_BOOK_RECORD_REQUEST,
    DELETE_PERSON_REQUEST,
    UPDATE_SINGLE_GRAVE_REQUEST,
    FIND_BY_QR_CODE_REQUEST,
    FIND_GRAVE_BY_SLUG_REQUEST,
    SEND_GUEST_BOOK_RECORD_REQUEST,
    GENERATE_QR_CODE_FOR_GRAVE_REQUEST,
} = require('./graveActions').constants;

function* getGraves() {
    try {
        const response = yield call(fetchApiAuth, {
            method: 'GET',
            url: '/grave/get-graves',
        });

        yield put(getGravesSuccess(response));
    } catch (e) {
        yield put(getGravesFailure(e.response ? e.response.data.message : e));
    }
}

function* getSingleGrave({ payload }) {
    try {
        const response = yield call(fetchApiAuth, {
            method: 'GET',
            url: '/grave/get-single-grave',
            params: payload,
        });

        yield put(getSingleGraveSuccess(response));
    } catch (e) {
        yield put(getSingleGraveFailure(e.response ? e.response.data.message : e));
    }
}

function* createGrave({ payload }) {
    try {
        const response = yield call(fetchApiAuth, {
            method: 'POST',
            url: '/grave/create-grave',
            body: payload,
        });

        yield put(createGraveSuccess(response));
        history.push({ pathname: '/single-grave' });
        message.success('Kapas sėkmingai sukurtas');
    } catch (e) {
        message.error(e.response ? e.response.data.message : e);
        yield put(createGraveFailure(e.response ? e.response.data.message : e));
    }
}

function* getGravePeople({ payload }) {
    try {
        const response = yield call(fetchApiAuth, {
            method: 'POST',
            url: '/people/get-grave-people',
            body: payload,
        });

        yield put(getGravePeopleSuccess(response));
    } catch (e) {
        message.error('Error', e.response?.data?.message || 'Unable to crerate grave');
        yield put(getGravePeopleFailure(e.response ? e.response.data.message : e));
    }
}

function* addGravePeople({ payload }) {
    try {
        const response = yield call(fetchApiAuth, {
            method: 'POST',
            url: '/people/add-grave-people',
            body: payload,
        });

        yield put(addGravePeopleSuccess(response));
        history.push({ pathname: '/single-grave-people' });
    } catch (e) {
        message.error(e.response?.data?.message || 'Unable to crerate grave people');
        yield put(addGravePeopleFailure(e.response ? e.response.data.message : e));
    }
}

function* updateSinglePeople({ payload }) {
    try {
        const response = yield call(fetchApiAuth, {
            method: 'POST',
            url: '/people/update-single-people',
            body: payload,
        });

        yield put(updateSinglePeopleSuccess(response));
        message.success('Informacija sėkmingai atnaujinta');
    } catch (e) {
        message.error(e.response?.data?.message || 'Negalima atnaujiti informacijos');
        yield put(updateSinglePeopleFailure(e.response ? e.response.data.message : e));
    }
}

function* getSinglePerson({ payload }) {
    try {
        const response = yield call(fetchApiAuth, {
            method: 'GET',
            url: '/people/get-single-person',
            params: payload,
        });

        yield put(getSinglePersonSuccess(response));
    } catch (e) {
        yield put(getSinglePersonFailure(e.response ? e.response.data.message : e));
    }
}

function* removePeoplePicture({ payload }) {
    try {
        const response = yield call(fetchApiAuth, {
            method: 'POST',
            url: '/people/remove-people-picture',
            body: payload,
        });
        message.success('Nuotrauka sėkmingai pašalinta');
        yield put(removePeoplePictureSuccess(response));
    } catch (e) {
        message.error(e.response?.data?.message || 'Nepavyko pašalinti nuotraukos');
        yield put(removePeoplePictureFailure(e.response ? e.response.data.message : e));
    }
}

function* addPeopleVideo({ payload }) {
    try {
        const response = yield call(fetchApiAuth, {
            method: 'POST',
            url: '/people/add-people-video',
            body: payload,
        });
        message.success('Video sėkmingai pridėtas');
        yield put(addPeopleVideoSuccess(response));
    } catch (e) {
        message.error(e.response?.data?.message || 'Nepavyko pašalinti video');
        yield put(addPeopleVideoFailure(e.response ? e.response.data.message : e));
    }
}

function* removePeopleVideo({ payload }) {
    try {
        const response = yield call(fetchApiAuth, {
            method: 'POST',
            url: '/people/remove-people-video',
            body: payload,
        });

        message.success('Video sėkmingai pašalintas');
        yield put(removePeopleVideoSuccess(response));
    } catch (e) {
        message.error(e.response?.data?.message || 'Nepavyko pašalinti video');
        yield put(removePeopleVideoFailure(e.response ? e.response.data.message : e));
    }
}

function* saveLifeEvents({ payload }) {
    try {
        const response = yield call(fetchApiAuth, {
            method: 'POST',
            url: '/people/save-life-events',
            body: payload,
        });

        message.success('Gyvenimo įvikiai sėkmingai išsaugoti');
        yield put(saveLifeEventsSuccess(response));
    } catch (e) {
        message.error(e.response?.data?.message || 'Nepavyko išsaugoti');
        yield put(saveLifeEventsFailure(e.response ? e.response.data.message : e));
    }
}

function* removeGuestBookRecord({ payload }) {
    try {
        const response = yield call(fetchApiAuth, {
            method: 'POST',
            url: '/people/remove-guest-book-record',
            body: payload,
        });

        message.success('Įrašas sėkmingai pašalintas');
        yield put(removeGuestBookRecordSuccess(response));
    } catch (e) {
        message.error(e.response?.data?.message || 'Nepavyko pašalinti');
        yield put(removeGuestBookRecordFailure(e.response ? e.response.data.message : e));
    }
}

function* deletePerson({ payload }) {
    try {
        const response = yield call(fetchApiAuth, {
            method: 'POST',
            url: '/people/delete-person',
            body: payload,
        });

        yield put(deletePersonSuccess(response));
        message.success('Asmuo sėkmingai pašalintas');
        history.push({ pathname: '/single-grave' });
    } catch (e) {
        message.error(e.response?.data?.message || 'Nepavyko pašalinti');
        yield put(deletePersonFailure(e.response ? e.response.data.message : e));
    }
}

function* updateSingleGrave({ payload }) {
    try {
        const response = yield call(fetchApiAuth, {
            method: 'POST',
            url: '/grave/update-single-grave',
            body: payload,
        });

        yield put(updateSingleGraveSuccess(response));
        message.success('Informacija sėkmingai atnaujinta');
    } catch (e) {
        message.error(e.response?.data?.message || 'Negalima atnaujiti informacijos');
        yield put(updateSingleGraveFailure(e.response ? e.response.data.message : e));
    }
}

function* findByQrCode({ payload }) {
    try {
        const response = yield call(fetchApi, {
            method: 'GET',
            url: '/grave/get-single-grave-by-qr-code',
            params: payload,
        });

        if (response?.data?.grave?.slug) {
            history.push({
                pathname: `/kapaviete/${response?.data?.grave?.slug}`,
                state: { stopLoading: true },
            });
        }

        yield put(findByQrCodeSuccess(response));
    } catch (e) {
        history.push('/not-found');
        yield put(findByQrCodeFailure(e.response ? e.response.data.message : e));
    }
}

function* findGraveBySlug({ payload }) {
    try {
        const response = yield call(fetchApi, {
            method: 'GET',
            url: '/grave/get-single-grave-by-slug',
            params: payload,
        });

        yield put(findGraveBySlugSuccess(response));
    } catch (e) {
        yield put(findGraveBySlugFailure(e.response ? e.response.data.message : e));
    }
}

function* sendGuestBookRecord({ payload }) {
    try {
        const response = yield call(fetchApiAuth, {
            method: 'POST',
            url: '/grave/send-guest-book-record',
            body: payload,
        });

        yield put(sendGuestBookRecordSuccess(response));
    } catch (e) {
        message.error(e.response?.data?.message || 'Nepavyko pridėti įrašo');
        yield put(sendGuestBookRecordFailure(e.response ? e.response.data.message : e));
    }
}

function* generateQrCodeForGrave({ payload }) {
    try {
        const response = yield call(fetchApiAuth, {
            method: 'POST',
            url: '/grave/generate-qr-code-for-grave',
            body: payload,
        });

        yield put(generateQrCodeForGraveSuccess(response));
    } catch (e) {
        yield put(generateQrCodeForGraveFailure(e.response ? e.response.data.message : e));
    }
}

export default function* authSaga() {
    yield* [
        takeEvery(GET_GRAVES_REQUEST, getGraves),
        takeEvery(GET_SINGLE_GRAVE_REQUEST, getSingleGrave),
        takeEvery(CREATE_GRAVE_REQUEST, createGrave),
        takeEvery(GET_GRAVE_PEOPLE_REQUEST, getGravePeople),
        takeEvery(ADD_GRAVE_PEOPLE_REQUEST, addGravePeople),
        takeEvery(UPDATE_SINGLE_PEOPLE_REQUEST, updateSinglePeople),
        takeEvery(GET_SINGLE_PERSON_REQUEST, getSinglePerson),
        takeEvery(REMOVE_PEOPLE_PICTURE_REQUEST, removePeoplePicture),
        takeEvery(ADD_PEOPLE_VIDEO_REQUEST, addPeopleVideo),
        takeEvery(REMOVE_PEOPLE_VIDEO_REQUEST, removePeopleVideo),
        takeEvery(SAVE_LIFE_EVENTS_REQUEST, saveLifeEvents),
        takeEvery(REMOVE_GUEST_BOOK_RECORD_REQUEST, removeGuestBookRecord),
        takeEvery(DELETE_PERSON_REQUEST, deletePerson),
        takeEvery(UPDATE_SINGLE_GRAVE_REQUEST, updateSingleGrave),
        takeEvery(FIND_BY_QR_CODE_REQUEST, findByQrCode),
        takeEvery(FIND_GRAVE_BY_SLUG_REQUEST, findGraveBySlug),
        takeEvery(SEND_GUEST_BOOK_RECORD_REQUEST, sendGuestBookRecord),
        takeEvery(GENERATE_QR_CODE_FOR_GRAVE_REQUEST, generateQrCodeForGrave),
    ];
}

import React from 'react';
import PropTypes from 'prop-types';
import { Link } from 'react-router-dom';

import './logoWrap.css';

import logoImage from '../../assets/images/tvg-lotus-3.svg';

const LogoWrap = ({ link }) => (
    <Link to={link} className="logo-link">
        <div className="logo-wrap-custom">
            <img src={logoImage} loading="lazy" alt="" className="lotus-img" />
            <div className="logo-text">Virtualus Kapas</div>
        </div>
    </Link>
);

LogoWrap.defaultProps = {
    link: '/dashboard',
};

LogoWrap.propTypes = {
    link: PropTypes.string,
};

export default LogoWrap;

import React from 'react';
import { PropTypes } from 'prop-types';
import { Button as AntButton, Spin } from 'antd';

const CustomButton = ({ onClick, children, className, isFetching, style }) => (
    <AntButton type="primary" htmlType="submit" className={className} onClick={() => { onClick(); }} disabled={isFetching} style={style}>
        {children}
        {isFetching ? <Spin style={{ marginLeft: 10 }} /> : null}
    </AntButton>
);

CustomButton.propTypes = {
    children: PropTypes.any,
    style: PropTypes.object,
    disabled: PropTypes.bool,
    label: PropTypes.string,
    value: PropTypes.bool,
    onClick: PropTypes.func,
    isFetching: PropTypes.bool,
    className: PropTypes.string,
};

CustomButton.defaultProps = {
    children: '',
    disabled: false,
    label: '',
    value: false,
    onClick: () => {},
    isFetching: false,
    className: 'link-green btn-empty underlined',
    style: {},
};

export default CustomButton;

/* eslint-disable */
import React from 'react';

export const IconGrave = ({ h, w, f, s, style }) => (
    <svg height={h || 70} width={w || 70} version="1.1" id="_x32_" xmlns="http://www.w3.org/2000/svg" xlink="http://www.w3.org/1999/xlink"
	 viewBox="0 0 512 512"  space="preserve">
        <g>
        	<path fill={f || '#000000'} className="st0" d="M428.466,386.01V164.97C428.451,73.851,354.608,0.008,263.493,0H248.51
        		C157.392,0.008,83.548,73.851,83.541,164.97v221.04H44.166V512h423.668v-14.997V386.01H428.466z M113.535,164.97
        		c0.008-37.318,15.086-70.974,39.529-95.446c24.473-24.444,58.129-39.521,95.446-39.529h14.983
        		c37.32,0.008,70.962,15.085,95.435,39.529c24.459,24.473,39.529,58.129,39.544,95.446v221.04H113.535V164.97z M74.161,416.004
        		h363.679v66.001H74.161V416.004z"/>
                <rect x="160" y="140" width="200" height="15" fill={f || '#000000'}/>
                <rect x="160" y="200" width="200" height="15" fill={f || '#000000'}/>
                <rect x="160" y="260" width="200" height="15" fill={f || '#000000'}/>
                <rect x="160" y="320" width="200" height="15" fill={f || '#000000'}/>
        </g>
    </svg>
);

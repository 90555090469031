import React from 'react';
import { Button, Card } from 'antd';
import { LeftOutlined } from '@ant-design/icons';

import Main from '../../components/layout/Main';
import BreadcrumbNav from '../../components/layout/BreadcrumbNav';
import SettingsForm from '../../components/Form/SettingsForm';

import './settings.scss';

const Settings = () => {

    const toolbar = [{ label: 'Item 1', link: '/abc', iconName: 'home' }];
    const menu = [{ label: 'Item 1', link: '/abc', iconName: 'home', style: { fontSize: 24, color: 'red' } }];
    const breadcrumbNavigation = [{ label: 'Nustatymai' }];

    function backLink() {
        return (
            <Button onClick={() => console.log('Back')}>
                <LeftOutlined /> BACK
            </Button>
        );
    }

    return (
        <Main
            className="settings-page"
            title="Nustatymai"
            headerTitle={<div>Graves</div>}
            menu={menu}
            menuWidth={350}
            menuPlacement="right"
            toolbar={toolbar}
            floatingHeader={false}
            floatingFooter={false}
            showFooter={false}
            backLink={backLink}>
            <div className="grave-page-content">
                <BreadcrumbNav separator=">" navigation={breadcrumbNavigation} />
                <h2 className="board-title">Nustatymai</h2>

                <Card className="add-card">
                    <SettingsForm />
                </Card>

            </div>
        </Main>
    );
};

export default Settings;

Settings.propTypes = {};

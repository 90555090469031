import React, { useEffect, useState } from 'react';
import { Helmet } from 'react-helmet';
import { Col, Row, Card, Image, Button, Modal } from 'antd';
import { useSelector, useDispatch } from 'react-redux';
import { EyeOutlined } from '@ant-design/icons';
import { useLocation } from 'react-router-dom';
import PropTypes from 'prop-types';

import dayjs from 'dayjs';
import 'moment/locale/lt';
import 'dayjs/locale/lt';

import {
    findGraveBySlugRequest,
} from '../../core/grave/graveActions';
import SiteNavigation from '../../components/SiteNavigation';
import SiteFooter from '../../components/SiteFooter';
import ItsFuture from '../../components/ItsFuture';
import MapMarker from '../../components/MapMarker';

import history from '../../core/utils/history';
import icon from '../../assets/favicon.ico';
import gravePlaceholder from '../../assets/images/grave-placeholder-full-cropped.webp';
import googleMap from '../../assets/images/google-map.svg';

import personAvatar1 from '../../assets/images/person-avatar-1.webp';
import personAvatar2 from '../../assets/images/person-avatar-2.webp';
import personAvatar3 from '../../assets/images/person-avatar-3.webp';
import personAvatar4 from '../../assets/images/person-avatar-4.webp';
import personAvatar5 from '../../assets/images/person-avatar-5.webp';
import personAvatar6 from '../../assets/images/person-avatar-6.webp';
import personAvatar7 from '../../assets/images/person-avatar-7.webp';
import personAvatar8 from '../../assets/images/person-avatar-8.webp';
import personAvatar9 from '../../assets/images/person-avatar-9.webp';
import personAvatar10 from '../../assets/images/person-avatar-10.webp';
import flowersTop from '../../assets/images/flowers-top-custom.webp';

import './singleGravePublic.scss';

const avatarPictures = [
    personAvatar1,
    personAvatar2,
    personAvatar3,
    personAvatar4,
    personAvatar5,
    personAvatar6,
    personAvatar7,
    personAvatar8,
    personAvatar9,
    personAvatar10,
];

const { APP_NAME } = require('../../core/constants').default;

function SingleGravePublic({ match }) {
    const dispatch = useDispatch();
    const location = useLocation();
    const grave = useSelector(state => state.grave);
    const [mapModalVisible, setMapModalVisible] = useState(false);

    useEffect(() => {
        if (!location?.state?.stopLoading) {
            if (match?.params?.slug) {
                dispatch(findGraveBySlugRequest({ slug: match?.params?.slug }));
            }
        }
    }, []);

    const openSingleGravePeople = (singleGravePeopleId) => {
        console.log('single', singleGravePeopleId);
        history.push({ pathname: `/kapaviete/${match?.params?.slug}/${singleGravePeopleId}` });
    };

    const renderSingleGravePeople = (singleGravePeople, key) => {
        console.log('singleGrave');
        return (
            <Col xs={24} sm={12} md={8} lg={8} xl={8} key={`single-grave-${singleGravePeople.id}`}>
                <div className="card-wrapper">
                    <Card className="grave-card" onClick={() => openSingleGravePeople(singleGravePeople.id)}>
                        <div className="image-content">
                            <img src={`${singleGravePeople?.pictureUrlSquare || avatarPictures[key % 10]}`} alt={singleGravePeople.title} />
                        </div>
                        <div className="description-content">
                            <h4 className="person-name">{singleGravePeople.title}</h4>
                            <div className="year-block">
                                {dayjs(singleGravePeople.birth_date, 'YYYY-MM-DD HH:mm:ss').format('YYYY')} - {dayjs(singleGravePeople.death_date, 'YYYY-MM-DD HH:mm:ss').format('YYYY')}
                            </div>
                            <div className="actions-block">
                                <Button onPress={() => openSingleGravePeople(singleGravePeople.id)} type="primary">Plačiau</Button>
                            </div>
                        </div>
                    </Card>
                </div>
            </Col>
        );

    };

    const gravePicture = grave.selectedGrave?.pictureUrl ? grave.selectedGrave?.pictureUrl : gravePlaceholder;
    const gravePictureMedium = grave.selectedGrave?.pictureUrlMedium ? grave.selectedGrave?.pictureUrlMedium : gravePlaceholder;

    return (
        <div className="homepage-wrap">
            <Helmet
                title={`${grave.selectedGrave?.title} - Kapavietė`}
                titleTemplate={`${APP_NAME} - %s`}
                defaultTitle={`${APP_NAME}`}
                meta={[
                    { name: 'description', content: `${grave.selectedGrave?.title} - Kapavietė` },
                    { property: 'og:type', content: 'website' },
                    { property: 'og:image', content: gravePicture },
                ]}>
                <link rel="shortcut icon" href={icon} />
            </Helmet>
            <SiteNavigation />
            <div className="single-grave-top-block">
                <div className="general-container">
                    <img src={flowersTop} alt="flowers" className="flowers-top" />
                    <div className="general-container-inner">
                        <div className="map-block">
                            <Button
                                type="primary"
                                className="map-button"
                                onClick={() => setMapModalVisible(true)}>
                                Žemėlapis
                                <img src={googleMap} alt="map icon" className="google-map-icon" />
                            </Button>
                        </div>
                        <Row>
                            <Col xs={24} sm={24} md={24} lg={24} xl={24} className="right-container">
                                <div className="title-wrap">
                                    <h1>{grave.selectedGrave?.title}</h1>
                                </div>
                                <div className="top-image-block">
                                    <Image
                                        label="test"
                                        preview={{
                                            src: gravePicture,
                                            mask: <div><EyeOutlined /> Peržiūrėti</div>,
                                        }}
                                        src={gravePictureMedium} />
                                </div>
                                <div className="grave-description-text">
                                    {grave?.selectedGrave?.description}
                                </div>
                            </Col>
                            <Col xs={24} sm={24} md={24} lg={24} xl={24} className="graves-listing-block">
                                <h2 className="graves-listing-block-title">Šiame kape palaidoti:</h2>
                                <Row className="graves-row">
                                    {grave.gravePeople.map((item, key) => (
                                        renderSingleGravePeople(item, key)
                                    ))}
                                </Row>
                            </Col>
                        </Row>
                    </div>

                </div>
            </div>
            <ItsFuture />
            <SiteFooter />
            <Modal
                className="map-modal"
                title="Kapo vieta žemėlapyje"
                centered
                width={1200}
                footer={false}
                open={mapModalVisible}
                onOk={() => { setMapModalVisible(false); }}
                onCancel={() => { setMapModalVisible(false); }}>
                <div className="modal-form-wrap">
                    <MapMarker location={{ lat: parseFloat(grave?.selectedGrave?.latitude), lng: parseFloat(grave?.selectedGrave?.longitude) }} controls={false} />

                    <Button type="primary" onClick={() => { setMapModalVisible(false); }} className="map-modal-button">Uždaryti</Button>
                </div>
            </Modal>
        </div>
    );
}

SingleGravePublic.propTypes = {
    match: PropTypes.object.isRequired,
};

export default SingleGravePublic;

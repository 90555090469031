import React, { useEffect } from 'react';
import { Helmet } from 'react-helmet';
import { Col, Row, Spin } from 'antd';
import PropTypes from 'prop-types';
import { useDispatch } from 'react-redux';

import SiteNavigation from '../../components/SiteNavigation';
import SiteFooter from '../../components/SiteFooter';
import ItsFuture from '../../components/ItsFuture';

// import history from '../../core/utils/history';
import icon from '../../assets/favicon.ico';

import {
    findByQrCodeRequest,
} from '../../core/grave/graveActions';

import './qrRedirect.scss';

const { APP_NAME } = require('../../core/constants').default;

function QrRedirect({ match }) {
    const dispatch = useDispatch();
    useEffect(() => {
        if (match?.params?.slug) {
            dispatch(findByQrCodeRequest({ qr_code: match?.params?.slug }));
        }
        // history.push('/not-found');
    }, []);

    return (
        <div className="qr-code-search-wrap">
            <Helmet
                title={`${APP_NAME}`}
                titleTemplate={`${APP_NAME} - %s`}
                defaultTitle={`${APP_NAME}`}
                meta={[
                    { name: 'description', content: `${APP_NAME} website` },
                    { property: 'og:type', content: 'website' },
                ]}>
                <link rel="shortcut icon" href={icon} />
            </Helmet>
            <SiteNavigation />
            <div className="qr-code-search-block">
                <div className="general-container">
                    <Row>
                        <Col xs={24} sm={24} md={24} lg={24} xl={24}>
                            <h1>Qr kodo paieška... <Spin size="large" /></h1>
                        </Col>
                    </Row>
                </div>
            </div>
            <ItsFuture />
            <SiteFooter />
        </div>
    );
}

QrRedirect.propTypes = {
    match: PropTypes.object.isRequired,
};

export default QrRedirect;

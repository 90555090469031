import React, { useState } from 'react';
import { Form, Input, Button, Modal, Space, message, Divider } from 'antd';
import { useDispatch } from 'react-redux';
import { GoogleOAuthProvider, GoogleLogin } from '@react-oauth/google';
import useUser from '../../../core/user/useUser';
import './loginForm.scss';

import {
    registerRequest,
} from '../../../core/user/userActions';

const LoginForm = () => {

    const { user } = useUser();
    const dispatch = useDispatch();

    const [forgetPasswordModalVisible, setForgetPasswordModalVisible] = useState(false);
    const [signupModalVisible, setSignupModalVisible] = useState(false);

    const [form] = Form.useForm();

    const { loginRequest, forgotPasswordRequest, loginGoogleRequest } = useUser();

    const onFinish = (values) => {
        loginRequest({
            email: values.email,
            password: values.password,
        });
    };

    const onFinishFailed = ({ errorFields }) => {
        form.scrollToField(errorFields[0].name);
    };

    const handleForgotFinish = (values) => {
        forgotPasswordRequest({
            email: values.email,
        });
        setTimeout(() => { setForgetPasswordModalVisible(); }, 1500);

    };

    const forgotFinishFailed = ({ errorFields }) => {
        form.scrollToField(errorFields[0].name);
    };

    const handleSignUp = (values) => {
        let error = false;

        if (values.new_password !== values.password_repeat) {
            error = true;
            message.warning('Slaptažodžiai nesutampa!');
        }

        if (!error) {
            dispatch(registerRequest(values));
            setForgetPasswordModalVisible(false);
        }
    };

    return (
        <>
            <div className="login-form-box">
                <div className="form">
                    <h3 className="form-title h-1">Prisijungti</h3>

                    <div className="login-google-container">
                        <GoogleOAuthProvider clientId="896630228497-tffldnoani6rapo82asdpr6crr52ri73.apps.googleusercontent.com">
                            <GoogleLogin
                                onSuccess={credentialResponse => {
                                    loginGoogleRequest(credentialResponse);
                                }}
                                onError={() => {
                                    console.log('Login Failed');
                                }} />
                        </GoogleOAuthProvider>
                    </div>
                    <Divider>arba</Divider>
                    <Form
                        onFinish={onFinish}
                        onFinishFailed={onFinishFailed}
                        layout="vertical">
                        <Form.Item
                            label="El paštas"
                            name="email"
                            rules={[{ type: 'email', message: 'Įvestis netinkamas el. pašto adresas!' }, { required: true, message: 'Prašome įvesti savo el. paštą' }]}>
                            <Input placeholder="Įrašykite savo el. paštą" />
                        </Form.Item>

                        <Form.Item
                            label="Slaptažodis"
                            name="password"
                            rules={[{ required: true, message: 'Įveskite slaptažodį.' }]}>
                            <Input.Password placeholder="Įveskite slaptažodį" />
                        </Form.Item>

                        <Form.Item>
                            <Button type="primary" size="large" htmlType="submit" block className="mt-3 primary-btn-style" loading={user.isFetching}>
                                Prisijungti
                            </Button>
                            <Button onClick={() => setSignupModalVisible(true)} className="secondary-button">
                                Registruotis
                            </Button>
                        </Form.Item>
                    </Form>
                    <div className="text-center">
                        <Button onClick={setForgetPasswordModalVisible} type="link" className="button-link mlp-25" href="#">
                            Pamiršote slaptažodį?
                        </Button>
                    </div>
                </div>
            </div>

            <Modal
                className="forgot-password-modal"
                centered
                width={540}
                footer={null}
                visible={forgetPasswordModalVisible}
                onOk={() => { setForgetPasswordModalVisible(false); }}
                onCancel={() => { setForgetPasswordModalVisible(false); }}>
                <h4 className="h-3 title-text-md  text-center">Atstatyti slaptažodį</h4>
                <div className="form-wrap">
                    <Form onFinish={handleForgotFinish} onFinishFailed={forgotFinishFailed} layout="vertical">
                        <Form.Item label="El paštas" name="email" rules={[{ type: 'email', message: 'Įvestis neteisinga El. paštas!' }, { required: true, message: 'Prašome įvesti savo el. paštą' }]}>
                            <Input />
                        </Form.Item>
                        <Space>
                            <Button type="primary" htmlType="submit" className="wide">
                                Atnaujinti slaptažodį
                            </Button>
                            <Button className="link-green btn-empty underlined" onClick={() => { setForgetPasswordModalVisible(false); }}>
                                atšaukti
                            </Button>
                        </Space>
                    </Form>
                </div>
            </Modal>

            <Modal
                className="signup-modal"
                centered
                width={540}
                footer={null}
                visible={signupModalVisible}
                onOk={() => { setSignupModalVisible(false); }}
                onCancel={() => { setSignupModalVisible(false); }}>
                <h4 className="h-3 title-text-md  text-center">Registruotis</h4>
                <div className="form-wrap">
                    <Form onFinish={handleSignUp} onFinishFailed={forgotFinishFailed} layout="vertical">
                        <Form.Item
                            name="full_name"
                            label="Vardas Pavardė"
                            style={{ marginTop: '30px' }}
                            rules={[{ required: true, message: 'Prašome įvesti savo vardą, pavardę.' }]}>
                            <Input placeholder="Vardas Pavardė" />
                        </Form.Item>
                        <Form.Item
                            label="El paštas"
                            name="email"
                            rules={[{ type: 'email', message: 'Įvestis neteisinga El. paštas!' }, { required: true, message: 'Prašome įvesti savo el. paštą' }]}>
                            <Input />
                        </Form.Item>
                        <Form.Item
                            label="Naujas slaptažodis"
                            name="new_password"
                            rules={[
                                { required: true, message: 'Prašome įvesti naują slaptažodį' },
                                { min: 7, message: 'Slaptažodį turi sudaryti minimum 7 ženklai' },
                                { pattern: /^(?=.*[a-zA-Z])(?=.*[0-9])/, message: 'Slaptažodį turi sudaryti raidės ir skaitmenys' },
                            ]}>
                            <Input.Password />
                        </Form.Item>
                        <Form.Item
                            label="Pakartokite naują slaptažodį"
                            name="password_repeat"
                            rules={[
                                { required: true, message: 'Prašome pakartoti naują slaptažodį' },
                                { min: 7, message: 'Slaptažodį turi sudaryti minimum 7 ženklai' },
                                { pattern: /^(?=.*[a-zA-Z])(?=.*[0-9])/, message: 'Slaptažodį turi sudaryti raidės ir skaitmenys' },
                            ]}>
                            <Input.Password />
                        </Form.Item>
                        <Space>
                            <Button type="primary" htmlType="submit" className="wide" loading={user.isFetching}>
                                Registruotis
                            </Button>
                            <Button className="link-green btn-empty underlined" onClick={() => { setSignupModalVisible(false); }}>
                                atšaukti
                            </Button>
                        </Space>
                    </Form>
                </div>
            </Modal>

        </>
    );
};

export default LoginForm;

import React, { useState, useEffect } from 'react';
import { Button, Modal, Form, Input, Col, Row, Card, DatePicker, Spin, Tabs, Image, Popover, QRCode, Select } from 'antd';
import { LeftOutlined, EyeOutlined, DragOutlined } from '@ant-design/icons';
import { useDispatch, useSelector } from 'react-redux';
import dayjs from 'dayjs';
import { isEmpty, size } from 'underscore';
import 'moment/locale/lt';
import 'dayjs/locale/lt';
import locale from 'antd/es/date-picker/locale/lt_LT';
import { Link } from 'react-router-dom';
import SortableList, { SortableItem } from 'react-easy-sort';
import { arrayMoveImmutable } from 'array-move';

import Main from '../../components/layout/Main';
import CustomButton from '../../components/CustomButton';
import BreadcrumbNav from '../../components/layout/BreadcrumbNav';
import EditableText from '../../components/EditableText';
import MapMarker from '../../components/MapMarker';
import ImageUploadGrave from '../../components/ImageUploadGrave';

import personAvatar1 from '../../assets/images/person-avatar-1.webp';
import personAvatar2 from '../../assets/images/person-avatar-2.webp';
import personAvatar3 from '../../assets/images/person-avatar-3.webp';
import personAvatar4 from '../../assets/images/person-avatar-4.webp';
import personAvatar5 from '../../assets/images/person-avatar-5.webp';
import personAvatar6 from '../../assets/images/person-avatar-6.webp';
import personAvatar7 from '../../assets/images/person-avatar-7.webp';
import personAvatar8 from '../../assets/images/person-avatar-8.webp';
import personAvatar9 from '../../assets/images/person-avatar-9.webp';
import personAvatar10 from '../../assets/images/person-avatar-10.webp';

import {
    getSingleGraveRequest,
    selectGravePeopleRequest,
    getGravePeopleRequest,
    addGravePeopleRequest,
    updateSingleGraveRequest,
    generateQrCodeForGraveRequest,
} from '../../core/grave/graveActions';
import history from '../../core/utils/history';

import './singleGrave.scss';

const { TextArea } = Input;
const avatarPictures = [
    personAvatar1,
    personAvatar2,
    personAvatar3,
    personAvatar4,
    personAvatar5,
    personAvatar6,
    personAvatar7,
    personAvatar8,
    personAvatar9,
    personAvatar10,
];

const Graves = () => {

    const dispatch = useDispatch();
    const [addGraveModalVisible, setAddGraveModalVisible] = useState(false);
    const grave = useSelector(state => state.grave);
    const payment = useSelector(state => state.payment);
    const [description, setDescription] = useState(grave?.selectedGrave?.description);
    const toolbar = [{ label: 'Item 1', link: '/abc', iconName: 'home' }];
    const menu = [{ label: 'Item 1', link: '/abc', iconName: 'home', style: { fontSize: 24, color: 'red' } }];
    const breadcrumbNavigation = [{ label: 'Mano Kapai', link: '/graves' }, { label: grave?.selectedGrave?.title }];
    const [gravePeople, setGravePeople] = useState(grave.gravePeople);

    useEffect(() => {
        setGravePeople(grave.gravePeople);
    }, [grave.gravePeople]);

    const planOptions = [];
    Object.keys(payment?.summary).forEach((item) => {
        const singleSummary = payment?.summary[item];
        let title = 'Planas Paprastas';

        if (item === 'advanced') {
            title = 'Planas Pažangus';
        }

        if (item === 'premium') {
            title = 'Planas Premium';
        }

        if (item === 'ultimate') {
            title = 'Planas Ultimate';
        }

        if (singleSummary?.left_graves_count > 0) {
            planOptions.push({
                value: item,
                label: `${title} (liko ${singleSummary?.left_graves_count})`,
            });
        }
    });

    dayjs.locale('LT');

    useEffect(() => {
        dispatch(getGravePeopleRequest({ grave_id: grave?.selectedGrave?.id }));
        dispatch(getSingleGraveRequest({ grave_id: grave?.selectedGrave?.id }));
    }, []);

    const updateSingleGrave = (passValue) => {
        dispatch(updateSingleGraveRequest({ grave_id: grave?.selectedGrave?.id, ...passValue }));
    };

    const handleAddGravePeople = (values) => {
        const data = values;
        data.grave_id = grave?.selectedGrave?.id;
        data.birth_date = data.birth_date.format('YYYY-MM-DD 00:00:00');
        data.death_date = data.death_date.format('YYYY-MM-DD 00:00:00');

        dispatch(addGravePeopleRequest(data));
    };

    const openSingleGravePeople = (graveID) => {
        dispatch(selectGravePeopleRequest(graveID));
        history.push({ pathname: '/single-grave-people' });
    };

    const renderSingleGravePeople = (singleGravePeople, key) => {

        console.log('singleGrave listing');

        return (
            <SortableItem key={`single-grave-${singleGravePeople.id}`}>
                <Col xs={24} sm={12} md={8} lg={6} xl={6}>
                    <div className="card-wrapper">
                        <Card className="grave-card" onClick={() => openSingleGravePeople(singleGravePeople.id)}>
                            <div className="image-content" style={{ backgroundImage: `url("${singleGravePeople?.pictureUrlMedium || avatarPictures[key % 10]}")` }} />
                            <div className="description-content">
                                <p className="person-name">{singleGravePeople.title}</p>
                                <div className="year-block">
                                    {dayjs(singleGravePeople.birth_date, 'YYYY-MM-DD HH:mm:ss').format('MMMM DD, YYYY')} -
                                    <br />
                                    {dayjs(singleGravePeople.death_date, 'YYYY-MM-DD HH:mm:ss').format('MMMM DD, YYYY')}
                                </div>
                                <div className="order-block">
                                    <DragOutlined />
                                </div>
                            </div>
                        </Card>
                    </div>
                </Col>
            </SortableItem>
        );

    };

    function backLink() {
        return (
            <Button onClick={() => console.log('Back')}>
                <LeftOutlined /> BACK
            </Button>
        );
    }

    const updateDescription = () => {
        dispatch(updateSingleGraveRequest({ grave_id: grave?.selectedGrave?.id, description }));
    };

    const onTextAreaChange = (e) => {
        setDescription(e.target.value);
    };

    const saveLocation = (position) => {
        dispatch(updateSingleGraveRequest({ grave_id: grave?.selectedGrave?.id, latitude: position.lat, longitude: position.lng }));
    };

    const handleGenerateQRCode = (values) => {
        const planValues = values;
        planValues.grave_id = grave?.selectedGrave?.id;

        dispatch(generateQrCodeForGraveRequest(planValues));
    };

    const onSortEnd = (oldIndex, newIndex) => {
        // Arrange items with new order
        const updatedItems = arrayMoveImmutable(gravePeople, oldIndex, newIndex);

        // Set the state with the reordered array
        setGravePeople(updatedItems);

        const updatedItemsFiltered = updatedItems.map(item => ({
            id: item.id,
            grave_id: item.grave_id,
        }));

        updateSingleGrave({ gravePeople: updatedItemsFiltered });
    };

    const renderGeneralTab = () => (
        <Row>
            <Col span={24}>
                <Card>
                    <div className="">
                        <h2 className="board-title">Šiame kape palaidoti:</h2>
                        <p className="board-subtitle">
                            Šioje skiltyje pridėkite asmenis kurie yra palaidoti šiame kape. Galite pridėti iki 7 asmenų.
                        </p>
                        <SortableList onSortEnd={onSortEnd} className="list" draggedItemClassName="dragged">
                            <Row className="graves-listing-row">
                                {gravePeople.map((item, key) => (
                                    renderSingleGravePeople(item, key)
                                ))}
                                {size(grave.gravePeople) < 7 ? (
                                    <Col xs={24} sm={12} md={8} lg={6} xl={6}>
                                        <div className="card-wrapper">
                                            <Card className="add-card" onClick={() => setAddGraveModalVisible(true)}>
                                                <span className="scrum-board-add-card-icon">
                                                    <svg stroke="currentColor" fill="currentColor" strokeWidth="0" viewBox="0 0 24 24" height="1em" width="1em" xmlns="http://www.w3.org/2000/svg">
                                                        <path fill="none" d="M0 0h24v24H0z" />
                                                        <path d="M19 13h-6v6h-2v-6H5v-2h6V5h2v6h6v2z" />
                                                    </svg>
                                                </span>
                                                <p className="scrum-board-add-card-text">Pridėti brangų žmogų</p>
                                            </Card>
                                        </div>
                                    </Col>
                                ) : null}
                            </Row>
                        </SortableList>
                    </div>
                </Card>
            </Col>
        </Row>
    );

    const renderInformationTab = () => (
        <Row>
            <Col xs={24} sm={12} md={9} lg={9} xl={9}>
                <div className="col-wrapper">
                    <Card>
                        <div className="picture-wrapper">
                            <h3>Kapavietės nuotrauka</h3>
                            {!isEmpty(grave.selectedGrave?.pictureUrlMedium) ? (
                                <Image
                                    label="test"
                                    preview={{
                                        src: grave.selectedGrave?.pictureUrl,
                                        mask: <div><EyeOutlined /> Peržiūrėti</div>,
                                    }}
                                    src={grave.selectedGrave?.pictureUrlMedium} />
                            ) : ''}
                            <ImageUploadGrave
                                style={{ marginTop: 15 }}
                                makeMainImage
                                graveId={grave.selectedGrave?.id} />
                        </div>
                    </Card>
                </div>
            </Col>
            <Col xs={24} sm={12} md={15} lg={15} xl={15}>
                <div className="col-wrapper">
                    <Card>
                        <h3>Aprašymas
                            <Popover content="Kapavietės aprašymas. Taip pat čia galite nurodyti kaip pasiekti kapavietę" trigger="hover">
                                <span className="question-popover">?</span>
                            </Popover>
                        </h3>
                        <TextArea
                            onChange={onTextAreaChange}
                            value={description}
                            rows={4}
                            placeholder="Kapavietės aprašymas. Taip pat čia galite nurodyti kaip pasiekti kapavietę" />
                        <Button type="primary" className="save-button" onClick={updateDescription}>Išsaugoti</Button>
                    </Card>
                </div>
            </Col>
        </Row>
    );

    const renderMapTab = () => (
        <Row>
            <Col span={24}>
                <div className="col-wrapper">
                    <Card>
                        <MapMarker location={{ lat: parseFloat(grave?.selectedGrave?.latitude), lng: parseFloat(grave?.selectedGrave?.longitude) }} saveLocation={(location) => saveLocation(location)} />
                    </Card>
                </div>
            </Col>
        </Row>
    );

    const renderQrCodeTab = () => (
        <Row>
            <Col span={24}>
                <div className="col-wrapper">
                    <Card>
                        <h2 className="block-title">QR Kodas</h2>
                        <div className="code-wrapper">
                            {grave?.selectedGrave?.qrCode?.code
                                ? (
                                    <>
                                        <QRCode
                                            size={200}
                                            value={`https://virtualuskapas.lt/qr/${grave?.selectedGrave?.qrCode?.code}`} />
                                        <h3>Šis kodas atitinka kodą, kurį Jums išsiuntėme paštu.<br />Per 7 darbo dienas kodas turėtų pasiekti Jus.<br />Jeigu kils klausimų ar neaiškumų, visada galite susisiekti su mumis.<br />Ačiū, kad naudojatės mūsų paslaugomis.</h3>
                                    </>
                                )
                                : (
                                    <>
                                        {isEmpty(planOptions)
                                            ? (
                                                <>
                                                    <h3>Šiuo metu neturite jokių aktyvių mokėjimo planų. <br />Norėdami isigyti QR kodą kapui, galite rinktis iš keleto planų kuriuos siūlome.</h3>
                                                    <Button onClick={() => history.push({ pathname: '/payment-plans' })}>Įsigyti QR Kodą kapui</Button>
                                                </>
                                            )

                                            : (
                                                <Form onFinish={handleGenerateQRCode} layout="vertical">
                                                    <Form.Item label="Pasirinkite turimą planą" name="payment_plan" rules={[{ required: true, message: 'Prašome pasirinkti planą!' }]}>
                                                        <Select
                                                            options={planOptions} />
                                                    </Form.Item>
                                                    <div>
                                                        <CustomButton style={{ marginLeft: 15 }} type="primary" htmlType="submit" className="wide" isFetching={grave.isFetching}>
                                                            Sugeneruoti QR kodą panaudojant turimą planą
                                                        </CustomButton>
                                                    </div>
                                                </Form>
                                            )}
                                    </>
                                )}
                        </div>
                    </Card>
                </div>
            </Col>
        </Row>
    );

    const tabs = [
        {
            key: '1',
            label: 'Čia ilsisi',
            children: renderGeneralTab(),
        },
        {
            key: '2',
            label: 'Kapo informacija',
            children: renderInformationTab(),
        },
        {
            key: '3',
            label: 'Žemėlapis',
            children: renderMapTab(),
        },
        {
            key: '4',
            label: 'QR Kodas',
            children: renderQrCodeTab(),
        },
    ];

    return (
        <Main
            className="grave-page"
            title="Mano Kapai"
            headerTitle={<div>Graves</div>}
            menu={menu}
            menuWidth={350}
            menuPlacement="right"
            toolbar={toolbar}
            floatingHeader={false}
            floatingFooter={false}
            showFooter={false}
            backLink={backLink}>
            <Spin spinning={grave.isFetching}>
                <div className="grave-page-content">
                    <BreadcrumbNav separator=">" navigation={breadcrumbNavigation} />
                    <h2 className="board-title">
                        <EditableText
                            onChange={(graveTitle) => updateSingleGrave({ title: graveTitle })}
                            value={grave.selectedGrave?.title}
                            inputStyle={{ maxWidth: 300 }} />
                    </h2>
                    <div className="grave-action-col">
                        <Link to={`/kapaviete/${grave?.selectedGrave?.slug}`} target="_blank" className="btn btn-primary view-button">Peržiūrėti pakeitimus <EyeOutlined /></Link>
                    </div>
                    <Tabs
                        className="grave-tabs"
                        type="card"
                        items={tabs} />
                </div>
            </Spin>
            <Modal
                title="Pridėti brangų žmogų"
                centered
                width={540}
                footer={false}
                open={addGraveModalVisible}
                onOk={() => { setAddGraveModalVisible(false); }}
                onCancel={() => { setAddGraveModalVisible(false); }}>
                <div className="modal-form-wrap">
                    <Form onFinish={handleAddGravePeople} layout="vertical">
                        <Form.Item label="Vardas pavardė" name="title" rules={[{ required: true, message: 'Prašome įvesti pavadinimą!' }]}>
                            <Input />
                        </Form.Item>
                        <Row>
                            <Col span={12}>
                                <div className="date-left-col">
                                    <Form.Item label="Gimė" name="birth_date" rules={[{ required: true, message: 'Prašome įvesti gimimo datą!' }]}>
                                        <DatePicker
                                            className="full-date-picker"
                                            locale={locale} />
                                    </Form.Item>
                                </div>
                            </Col>
                            <Col span={12}>
                                <div className="date-right-col">
                                    <Form.Item label="Mirė" name="death_date" rules={[{ required: true, message: 'Prašome įvesti mirties datą!' }]}>
                                        <DatePicker
                                            className="full-date-picker"
                                            locale={locale} />
                                    </Form.Item>
                                </div>
                            </Col>
                        </Row>
                        <div className="modal-action-col">
                            <Button className="link-green btn-empty underlined" onClick={() => { setAddGraveModalVisible(false); }}>
                                Atšaukti
                            </Button>
                            <CustomButton style={{ marginLeft: 10 }} type="primary" htmlType="submit" className="wide" isFetching={grave.isFetching}>
                                Pridėti
                            </CustomButton>
                        </div>
                    </Form>
                </div>
            </Modal>
        </Main>
    );
};

export default Graves;

Graves.propTypes = {};

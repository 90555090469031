import React, { useEffect } from 'react';
import { Helmet } from 'react-helmet';
import { Col, Row } from 'antd';
// import { Link } from 'react-router-dom';
import ContactFormPublic from '../../components/Form/ContactFormPublic';
import SiteNavigation from '../../components/SiteNavigation';
import SiteFooter from '../../components/SiteFooter';
import ItsFuture from '../../components/ItsFuture';

import useUser from '../../core/user/useUser';
// import history from '../../core/utils/history';
import icon from '../../assets/favicon.ico';
import flowersLeft from '../../assets/images/contact_left.webp';
import flowersRight from '../../assets/images/contact_right.webp';
import hpImg4 from '../../assets/images/hp-img-4.webp';

import './contactUs.scss';

const { APP_NAME } = require('../../core/constants').default;

function ContactUs() {

    const { user } = useUser();

    useEffect(() => {

        if (user?.isLoggedIn === true) {
            // history.push('/dashboard');
        }
    }, []);

    return (
        <div className="homepage-wrap">
            <Helmet
                title="Kontaktai"
                titleTemplate={`${APP_NAME} - %s`}
                defaultTitle={`${APP_NAME}`}
                meta={[
                    { name: 'description', content: 'Susisiekite su Virtualus Kapas svetainės komanda ir sužinokite daugiau apie unikalų būdą pagerbti ir prisiminti savo artimuosius. Sukurkite asmeninį virtualų kapą ir išsaugokite mūsų istoriją ateities kartoms.' },
                    { property: 'og:type', content: 'website' },
                    { property: 'og:image', content: hpImg4 },
                ]}>
                <link rel="shortcut icon" href={icon} />
            </Helmet>
            <SiteNavigation />
            <div className="contact-us-block">
                <div className="block-inner">
                    <img src={flowersLeft} alt="flowers" className="flowers-left" />
                    <img src={flowersRight} alt="flowers" className="flowers-right" />
                    <Row>
                        <Col xs={24} sm={24} md={24} lg={24} xl={24}>
                            <div className="title-wrapper">
                                <h1>Kontaktai</h1>
                            </div>

                            <ContactFormPublic />

                            <div className="contact-details-wrapper">
                                <h3>Rekvizitai:</h3>
                                <p>Pardavėjas: Audrius Dobrovolskis</p>
                                <p>Telefonas: +370 685 23096</p>
                                <p>Elpaštas: info@virtualuskapas.lt</p>
                                <p>Individualios veiklos pažymėjimo numeris: 522651</p>
                            </div>

                        </Col>
                    </Row>
                </div>
            </div>
            <ItsFuture />
            <SiteFooter />
        </div>
    );
}
export default ContactUs;

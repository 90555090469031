import { Record } from 'immutable';
import { findIndex } from 'underscore';

const {
    GET_GRAVES_REQUEST,
    GET_GRAVES_SUCCESS,
    GET_GRAVES_FAILURE,

    GET_SINGLE_GRAVE_REQUEST,
    GET_SINGLE_GRAVE_SUCCESS,
    GET_SINGLE_GRAVE_FAILURE,

    SELECT_GRAVE_REQUEST,

    CREATE_GRAVE_REQUEST,
    CREATE_GRAVE_SUCCESS,
    CREATE_GRAVE_FAILURE,

    GET_GRAVE_PEOPLE_REQUEST,
    GET_GRAVE_PEOPLE_SUCCESS,
    GET_GRAVE_PEOPLE_FAILURE,

    ADD_GRAVE_PEOPLE_REQUEST,
    ADD_GRAVE_PEOPLE_SUCCESS,
    ADD_GRAVE_PEOPLE_FAILURE,

    SELECT_GRAVE_PEOPLE_REQUEST,
    UPDATE_SELECTED_GRAVE_PEOPLE_IMAGE_REQUEST,
    UPDATE_SELECTED_GRAVE_IMAGE_REQUEST,

    UPDATE_SINGLE_PEOPLE_REQUEST,
    UPDATE_SINGLE_PEOPLE_SUCCESS,
    UPDATE_SINGLE_PEOPLE_FAILURE,

    GET_SINGLE_PERSON_REQUEST,
    GET_SINGLE_PERSON_SUCCESS,
    GET_SINGLE_PERSON_FAILURE,

    REMOVE_PEOPLE_PICTURE_REQUEST,
    REMOVE_PEOPLE_PICTURE_SUCCESS,
    REMOVE_PEOPLE_PICTURE_FAILURE,

    ADD_PEOPLE_VIDEO_REQUEST,
    ADD_PEOPLE_VIDEO_SUCCESS,
    ADD_PEOPLE_VIDEO_FAILURE,

    REMOVE_PEOPLE_VIDEO_REQUEST,
    REMOVE_PEOPLE_VIDEO_SUCCESS,
    REMOVE_PEOPLE_VIDEO_FAILURE,

    SAVE_LIFE_EVENTS_REQUEST,
    SAVE_LIFE_EVENTS_SUCCESS,
    SAVE_LIFE_EVENTS_FAILURE,

    REMOVE_GUEST_BOOK_RECORD_REQUEST,
    REMOVE_GUEST_BOOK_RECORD_SUCCESS,
    REMOVE_GUEST_BOOK_RECORD_FAILURE,

    DELETE_PERSON_REQUEST,
    DELETE_PERSON_SUCCESS,
    DELETE_PERSON_FAILURE,

    UPDATE_SINGLE_GRAVE_REQUEST,
    UPDATE_SINGLE_GRAVE_SUCCESS,
    UPDATE_SINGLE_GRAVE_FAILURE,

    FIND_BY_QR_CODE_REQUEST,
    FIND_BY_QR_CODE_SUCCESS,
    FIND_BY_QR_CODE_FAILURE,

    FIND_GRAVE_BY_SLUG_REQUEST,
    FIND_GRAVE_BY_SLUG_SUCCESS,
    FIND_GRAVE_BY_SLUG_FAILURE,

    SEND_GUEST_BOOK_RECORD_REQUEST,
    SEND_GUEST_BOOK_RECORD_SUCCESS,
    SEND_GUEST_BOOK_RECORD_FAILURE,

    GENERATE_QR_CODE_FOR_GRAVE_REQUEST,
    GENERATE_QR_CODE_FOR_GRAVE_SUCCESS,
    GENERATE_QR_CODE_FOR_GRAVE_FAILURE,

} = require('./graveActions').constants;

const InitialState = Record({
    isFetching: null,
    graves: [],
    gravePeople: [],
    selectedGrave: {},
    selectedGravePeople: {},
    error: null,
    updated: 1,
    messageSent: 1,
});

const initialState = new InitialState();

/**
 * ## galleryReducer function
 * @param {Object} state - initialState
 * @param {Object} action - type and payload
 */
export default function galleryReducer(state = initialState, { payload, type }) {
    if (!(state instanceof InitialState)) return initialState.mergeDeep(state);

    switch (type) {
    case GET_GRAVES_REQUEST:
    case GET_SINGLE_GRAVE_REQUEST:
    case CREATE_GRAVE_REQUEST:
    case GET_GRAVE_PEOPLE_REQUEST:
    case ADD_GRAVE_PEOPLE_REQUEST:
    case UPDATE_SINGLE_PEOPLE_REQUEST:
    case GET_SINGLE_PERSON_REQUEST:
    case REMOVE_PEOPLE_PICTURE_REQUEST:
    case ADD_PEOPLE_VIDEO_REQUEST:
    case REMOVE_PEOPLE_VIDEO_REQUEST:
    case SAVE_LIFE_EVENTS_REQUEST:
    case REMOVE_GUEST_BOOK_RECORD_REQUEST:
    case DELETE_PERSON_REQUEST:
    case UPDATE_SINGLE_GRAVE_REQUEST:
    case FIND_BY_QR_CODE_REQUEST:
    case FIND_GRAVE_BY_SLUG_REQUEST:
    case SEND_GUEST_BOOK_RECORD_REQUEST:
    case GENERATE_QR_CODE_FOR_GRAVE_REQUEST:
        return state.set('isFetching', true)
            .set('error', null);

    case GET_GRAVES_SUCCESS: {
        return state.set('isFetching', false)
            .set('graves', payload.data);
    }

    case GET_GRAVE_PEOPLE_SUCCESS: {
        return state.set('isFetching', false)
            .set('gravePeople', payload.data);
    }

    case GET_SINGLE_GRAVE_SUCCESS:
    case CREATE_GRAVE_SUCCESS: {
        return state.set('isFetching', false)
            .set('selectedGrave', payload.data?.grave);
    }

    case SELECT_GRAVE_REQUEST: {
        let { selectedGrave } = state;
        const { graves } = state;

        const selectedGraveIndex = findIndex(graves, (val) => val.id === payload);
        if (selectedGraveIndex !== -1) {
            selectedGrave = graves[selectedGraveIndex];
        }

        return state.set('selectedGrave', selectedGrave);
    }

    case SELECT_GRAVE_PEOPLE_REQUEST: {
        let { selectedGravePeople } = state;
        const { gravePeople } = state;

        const selectedGravePeopleIndex = findIndex(gravePeople, (val) => val.id === payload);
        if (selectedGravePeopleIndex !== -1) {
            selectedGravePeople = gravePeople[selectedGravePeopleIndex];
        }

        return state.set('selectedGravePeople', selectedGravePeople);
    }

    case ADD_GRAVE_PEOPLE_SUCCESS: {
        return state.set('isFetching', false)
            .set('selectedGravePeople', payload.data?.gravePeople);
    }

    case UPDATE_SELECTED_GRAVE_PEOPLE_IMAGE_REQUEST: {
        const { selectedGravePeople, updated } = state;
        selectedGravePeople.pictureUrl = payload;
        selectedGravePeople.pictureUrlMedium = payload;

        return state.set('selectedGravePeople', selectedGravePeople)
            .set('updated', updated * -1);
    }

    case UPDATE_SELECTED_GRAVE_IMAGE_REQUEST: {
        const { selectedGrave, updated } = state;
        selectedGrave.pictureUrl = payload;
        selectedGrave.pictureUrlMedium = payload;

        return state.set('selectedGrave', selectedGrave)
            .set('updated', updated * -1);
    }

    case UPDATE_SINGLE_PEOPLE_SUCCESS: {
        return state
            .set('selectedGravePeople', payload?.data?.gravePeople)
            .set('isFetching', false);
    }

    case UPDATE_SINGLE_GRAVE_SUCCESS: {
        return state
            .set('selectedGrave', payload?.data?.grave)
            .set('isFetching', false);
    }

    case GET_SINGLE_PERSON_SUCCESS:
    case REMOVE_PEOPLE_PICTURE_SUCCESS:
    case ADD_PEOPLE_VIDEO_SUCCESS:
    case REMOVE_PEOPLE_VIDEO_SUCCESS:
    case SAVE_LIFE_EVENTS_SUCCESS:
    case REMOVE_GUEST_BOOK_RECORD_SUCCESS: {
        return state
            .set('selectedGravePeople', payload?.data)
            .set('isFetching', false);
    }

    case DELETE_PERSON_SUCCESS:
        return state.set('isFetching', false)
            .set('selectedGravePeople', {})
            .set('gravePeople', payload.data);

    case FIND_BY_QR_CODE_SUCCESS:
    case FIND_GRAVE_BY_SLUG_SUCCESS:
    case GENERATE_QR_CODE_FOR_GRAVE_SUCCESS: {
        return state
            .set('selectedGrave', payload?.data?.grave)
            .set('gravePeople', payload?.data?.gravePeople)
            .set('isFetching', false);
    }

    case SEND_GUEST_BOOK_RECORD_SUCCESS: {
        const { messageSent, selectedGravePeople } = state;

        console.log('payload', payload.data);
        console.log('selectedGravePeople', selectedGravePeople);

        selectedGravePeople.guestBookRecords.unshift(payload?.data?.guestBook);

        return state.set('isFetching', false)
            .set('messageSent', messageSent * -1)
            .set('selectedGravePeople', selectedGravePeople);
    }

    case GET_GRAVES_FAILURE:
    case GET_SINGLE_GRAVE_FAILURE:
    case CREATE_GRAVE_FAILURE:
    case GET_GRAVE_PEOPLE_FAILURE:
    case ADD_GRAVE_PEOPLE_FAILURE:
    case UPDATE_SINGLE_PEOPLE_FAILURE:
    case GET_SINGLE_PERSON_FAILURE:
    case REMOVE_PEOPLE_PICTURE_FAILURE:
    case ADD_PEOPLE_VIDEO_FAILURE:
    case REMOVE_PEOPLE_VIDEO_FAILURE:
    case SAVE_LIFE_EVENTS_FAILURE:
    case REMOVE_GUEST_BOOK_RECORD_FAILURE:
    case DELETE_PERSON_FAILURE:
    case UPDATE_SINGLE_GRAVE_FAILURE:
    case FIND_BY_QR_CODE_FAILURE:
    case FIND_GRAVE_BY_SLUG_FAILURE:
    case SEND_GUEST_BOOK_RECORD_FAILURE:
    case GENERATE_QR_CODE_FOR_GRAVE_FAILURE:
        return state.set('isFetching', false)
            .set('error', payload);

    default:
        return state;
    }
}

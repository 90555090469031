import React from 'react';
import PropTypes from 'prop-types';

import { CoreRouter, LogoutRoute, DefaultRoute, AuthRoute } from './utils/coreRouter';
import Login from '../pages/Login';
import Dashboard from '../pages/Dashboard';
import ResetPassword from '../pages/ResetPassword';
import Graves from '../pages/Graves';
import SingleGrave from '../pages/SingleGrave';
import SingleGravePeople from '../pages/SingleGravePeople';
import PaymentPlans from '../pages/PaymentPlans';
import Settings from '../pages/Settings';
import Checkout from '../pages/Checkout';
import Help from '../pages/Help';
import HomePage from '../pages/HomePage';
import AboutUs from '../pages/AboutUs';
import FrequentlyAskedQuestions from '../pages/FrequentlyAskedQuestions';
import GravesListPublic from '../pages/GravesListPublic';
import SingleGravePublic from '../pages/SingleGravePublic';
import SingleGravePeoplePublic from '../pages/SingleGravePeoplePublic';
import QrRedirect from '../pages/QrRedirect';
import NotFound from '../pages/NotFound';
import CancelOrder from '../pages/CancelOrder';
import ConfirmOrder from '../pages/ConfirmOrder';
import PrivacyPolicy from '../pages/PrivacyPolicy';
import BuyingRules from '../pages/BuyingRules';
import ContactUs from '../pages/ContactUs';
import PaymentPlansPublic from '../pages/PaymentPlansPublic';
import Videos from '../pages/Videos';

const Routes = ({ persistor }) => (
    <CoreRouter>
        <LogoutRoute exact path="/logout" persistor={persistor} />
        <DefaultRoute exact path="/" component={HomePage} />
        <DefaultRoute exact path="/apie-mus" component={AboutUs} />
        <DefaultRoute exact path="/prisijungti" component={Login} />
        <DefaultRoute exact path="/kapavietes" component={GravesListPublic} />
        <DefaultRoute exact path="/kapaviete/:slug" component={SingleGravePublic} />
        <DefaultRoute exact path="/kapaviete/:slug/:id" component={SingleGravePeoplePublic} />
        <DefaultRoute exact path="/dazniausiai-uzduodami-klausimai" component={FrequentlyAskedQuestions} />
        <DefaultRoute exact path="/kontaktai" component={ContactUs} />
        <DefaultRoute exact path="/reset-password/:token/:email" component={ResetPassword} />
        <DefaultRoute exact path="/qr/:slug" component={QrRedirect} />
        <DefaultRoute exact path="/cancel-order" component={CancelOrder} />
        <DefaultRoute exact path="/confirm-order/:orderId" component={ConfirmOrder} />
        <DefaultRoute exact path="/privatumo-politika" component={PrivacyPolicy} />
        <DefaultRoute exact path="/pirkimo-taisykles" component={BuyingRules} />
        <DefaultRoute exact path="/mokejimo-planai" component={PaymentPlansPublic} />
        <DefaultRoute exact path="/video" component={Videos} />
        <DefaultRoute exact path="/not-found" component={NotFound} />
        <AuthRoute exact path="/dashboard" component={Dashboard} />
        <AuthRoute exact path="/graves" component={Graves} />
        <AuthRoute exact path="/settings" component={Settings} />
        <AuthRoute exact path="/payment-plans" component={PaymentPlans} />
        <AuthRoute exact path="/help" component={Help} />
        <AuthRoute exact path="/single-grave" component={SingleGrave} />
        <AuthRoute exact path="/single-grave-people" component={SingleGravePeople} />
        <AuthRoute exact path="/checkout" component={Checkout} />
    </CoreRouter>
);

Routes.defaultProps = {
    persistor: null,
};

Routes.propTypes = {
    persistor: PropTypes.object,
};

export default Routes;

import React, { useEffect } from 'react';
import { Helmet } from 'react-helmet';
import { Col, Row } from 'antd';

import SiteNavigation from '../../components/SiteNavigation';
import SiteFooter from '../../components/SiteFooter';
import ItsFuture from '../../components/ItsFuture';

import useUser from '../../core/user/useUser';
// import history from '../../core/utils/history';
import icon from '../../assets/favicon.ico';
import aboutUsImage from '../../assets/images/about-us-1.webp';
import flowersLeft from '../../assets/images/contact_left.webp';
import flowersRight from '../../assets/images/contact_right.webp';

import './aboutUs.scss';

const { APP_NAME } = require('../../core/constants').default;

function AboutUs() {

    const { user } = useUser();

    useEffect(() => {

        if (user?.isLoggedIn === true) {
            // history.push('/dashboard');
        }
    }, []);

    return (
        <div className="homepage-wrap">
            <Helmet
                title="Kaip mes kuriame ilgalaikę duoklę jūsų artimiesiems"
                titleTemplate={`${APP_NAME} - %s`}
                defaultTitle={`${APP_NAME}`}
                meta={[
                    { name: 'description', content: 'Virtualus Kapas yra platforma, kuri leidžia jums kurti ir puoselėti savo artimųjų virtualius kapus. Virtualios kapinės siūlo sužinoti daugiau apie mūsų komandą, kelionę ir viziją, kaip mes siekiame sujungti fizinį ir skaitmeninį pasaulį, skatinti interaktyvų pasakojimą ir prisidėti prie aplinkos tvarumo. Prisijunkite prie mūsų bendruomenės ir patirkite naują būdą pagerbti ir prisiminti savo artimuosius.' },
                    { property: 'og:type', content: 'website' },
                    { property: 'og:image', content: aboutUsImage },
                ]}>
                <link rel="shortcut icon" href={icon} />
            </Helmet>
            <SiteNavigation />
            <div className="top-about-us-block">
                <div className="block-inner">
                    <img src={flowersLeft} alt="flowers" className="flowers-left" />
                    <img src={flowersRight} alt="flowers" className="flowers-right" />
                    <div className="content-wrapper">
                        <Row>
                            <Col xs={24} sm={24} md={24} lg={24} xl={24}>
                                <h1>Apie mus</h1>
                                <p>Esame komanda, pasiryžusi panaudoti technologijų galią iš naujo apibrėžti memorializacijos kraštovaizdį. Mūsų Virtualios Kapinės svetainė gimė iš gilios pagarbos prisiminimų išsaugojimui ir mūsų siekio sukurti ilgalaikį palikimą tiems, kurių nebėra su mumis. Mūsų novatoriška platforma skirta sujungti fizinį ir skaitmeninį pasaulį, suteikdama prasmingą, ilgalaikę duoklę jūsų artimiesiems, kurią galima pasiekti bet kada ir bet kur.</p>
                                <p>Mūsų kelionė prasidėjo, kai supratome tradicinių kapų lankymo ribotumą. Supratome, kad dėl fizinio atstumo ir laiko, kai kuriems žmonėms gali būti sudėtinga lankytis kapinėse ir reguliariai pagerbti mirusius artimuosius. Tikėjome, kad ryšys tarp gyvųjų ir mirusiųjų neturi būti ribojamas geografijos ar aplinkybių.</p>
                                <img src={aboutUsImage} alt="Virtualaus Kapo Komanda" className="img-responsive about-logo" />
                                <p>Atsižvelgdami į šią viziją, sukūrėme savo Virtualus Kapas svetainę – novatorišką platformą, kuri be pastangų sujungia fizinę ir virtualią sferą. Šiandien siūlome daugybę visapusiškų funkcijų, leidžiančių asmenims kurti individualizuotus virtualius kapus, tiksliai nustatyti kapų vietas interaktyviame žemėlapyje, generuoti pasirinktinius QR kodus fiziniams kapams sujungti su virtualiais ir dar daugiau.</p>
                                <p>Mūsų platforma skatina interaktyvų pasakojimą, leisdama vartotojams labai patraukliai dalytis brangiais prisiminimais, mirusiųjų artimųjų nuotraukomis ir vaizdo įrašais. Tai prideda atminimui gilumo ir turtingumo, kurio negalėjo suteikti tradicinė kapų lankymo praktika. Be to, kurdami palankią vartotojų bendruomenę, mūsų platforma siūlo erdvę, kurioje asmenys gali susisiekti, dalytis ir rasti paguodą sudėtingais laikais.</p>
                                <p>Kviečiame prisijungti prie mūsų bendruomenės ir patirti naują, įtraukiantį būdą pagerbti, prisiminti ir nepamiršti į anapilį išėjusių savo artimųjų. Kartu kurkime atminimo patirtį, kuri būtų ne tik novatoriška, bet ir labai asmeniška, įsimintina ir svarbiausia — amžina. Sveiki atvykę į mūsų Virtualus Kapas svetainę.</p>
                            </Col>
                        </Row>
                    </div>
                </div>
            </div>
            <ItsFuture />
            <SiteFooter />
        </div>
    );
}
export default AboutUs;

import React, { useEffect } from 'react';
import { Helmet } from 'react-helmet';
import { Col, Row, Collapse } from 'antd';
import { Link } from 'react-router-dom';

import SiteNavigation from '../../components/SiteNavigation';
import SiteFooter from '../../components/SiteFooter';
import ItsFuture from '../../components/ItsFuture';

import useUser from '../../core/user/useUser';
// import history from '../../core/utils/history';
import icon from '../../assets/favicon.ico';

import './frequentlyAskedQuestions.scss';

const { APP_NAME } = require('../../core/constants').default;

const { Panel } = Collapse;
function FrequentlyAskedQuestions() {

    const { user } = useUser();

    useEffect(() => {

        if (user?.isLoggedIn === true) {
            // history.push('/dashboard');
        }
    }, []);

    return (
        <div className="homepage-wrap">
            <Helmet
                title="Dažniausiai užduodami klausimai"
                titleTemplate={`${APP_NAME} - %s`}
                defaultTitle={`${APP_NAME}`}
                meta={[
                    { name: 'description', content: 'Sužinokite daugiau apie Virtualus Kapas - revoliucinę platformą, kuri leidžia kurti virtualius kapus ir prisiminti savo artimuosius. Atraskite, kaip tai veikia, kodėl tai svarbu ir kaip pradėti.' },
                    { property: 'og:type', content: 'website' },
                ]}>
                <link rel="shortcut icon" href={icon} />
            </Helmet>
            <SiteNavigation />
            <div className="top-faq-block">
                <div className="general-container">
                    <Row>
                        <Col xs={24} sm={24} md={24} lg={24} xl={24}>
                            <h1>Dažniausiai užduodami klausimai</h1>
                            <Collapse
                                accordion
                                size="large">
                                <Panel header="Kas tai yra virtualus kapas?" key="1">
                                    <p>Tai visame pasaulyje populiarėjantis būdas gauti pilnavertę informaciją apie velionį tiesiog kapinėse. Jau anksčiau buvo bandoma įvairiomis technologijomis pateikti informaciją ant velionio kapo, netgi mėginama į paminklus įmontuoti monitorius, bet tai nepasiteisino. Dabar,  ištobulėjus išmaniesiems telefonams, bei atsiradus planšetiniams kompiuteriams jau yra galimybė informaciją apie velionį peržiūrėti tiesiog kapinėse. Tereikia nuskaityti QR kodą ir jau galima matyti velionio biografiją, nekrologą, jo nuotraukas, video , skaityti ir palikti įrašus atminimo knygoje.</p>
                                </Panel>
                                <Panel header="Kokia virtualios kapavietės paskirtis?" key="2">
                                    <p>Mes esame įpratę lankyti artimųjų kapus, juos prižiūrėti, puoselėti. Taip mes išlaikome ryšį su išėjusiaisiais Amžinybėn. Tačiau laikas padaro savo: ilgainiui mirusiojo žmogaus bruožai atmintyje ima blėsti, prisiminimai apie jį tampa vis blankesni, o anūkai ir proanūkiai dažnai būna net nematę senelių, prosenelių. Virtualus kapas padeda informaciją apie velionį surinkti į vieną vietą, ją susisteminti ir patogiai , bei patraukliai pateikti.</p>
                                </Panel>
                                <Panel header="Kaip galima pagerbti artimąjį, aplankius jo virtualų kapą?" key="3">
                                    <p>Aplankius realų kapą, galima padėti ar pasodinti gėlių, uždegti žvakelę. O apsilankius virtualiose kapinėse artimąjį siūlome pagerbti paliekant įrašą atminimų knygoje</p>
                                </Panel>
                                <Panel header="Ar visi, nuskaitę QR kodą, galės apsilankyti virtualiame kape?" key="4">
                                    <p>Taip informaciją galės pamatyti visi, kadangi kapinės yra vieša vieta, kur gali apsilankyti kiekvienas ir asmuo nuskanavęs QR kodą galės pamatyti kas talpinama virtualiame kape</p>
                                </Panel>
                                <Panel header="Ar informacija, pateikta e. kapinėse yra apsaugota nuo nepagarbių ar pagiežingų jį aplankiusiųjų įrašų?" key="5">
                                    <p>Kadangi virtualų kapą tvarko velionio artimasis, jis gali nedelsdamas pašalinti netinkamą informaciją. Taip pat mes pasiliekame teisę šalinti, bet kokius nepagrabius ar smurtą propaguojančius įrašus be perpėjimo.</p>
                                </Panel>
                                <Panel header="Koks mūsų santykis su religinėmis bendruomenėmis?" key="6">
                                    <p>Mūsų platformos požiūris į religines bendruomenes ir jų nuostatas yra visiškai neutralus. Mes siekiame būti atviri visoms religijoms ir nepriklausomai nuo jūsų tikėjimo, yra sveikintini naudotis mūsų paslaugomis. Mūsų platforma nėra orientuota į jokias specifines religines grupes ir mes nešališki visais religijos klausimais. Mes užtikriname, kad mūsų internetinės kapinės bus vieta, kurioje visi gali rasti ramybę ir paguodą, nepriklausomai nuo jų religinės pripažinimo. Mūsų tikslas - sukurti saugią ir atvirą erdvę visiems, nepriklausomai nuo jų tikėjimo ir religinio pripažinimo. Mes visuomet laikomės principo nediskriminuoti jokių religinių bendruomenių.</p>
                                </Panel>
                                <Panel header="Kas tai yra unikalus QR kodas?" key="7">
                                    <p>Kai sukuriamas virtualus kapas,  kiekvienas QR kodas gaminamas individualiai. Tuomet nurodytu adresu jis išsiunčiamas įprastu paštu. QR kodas priklijuojamas prie paminklo ir bet kas jį nuskenavęs patenka į virtualią kapavietę.</p>
                                </Panel>
                                <Panel header="Kiek QR kodų man reikės vienam kapui jeigu kape palaidota keletą asmenų?" key="8">
                                    <p>Mūsų platforma leidžia kurti iki 7 asmenų vienam virtualiam kapui, todėl jums reikės tik vieno QR kodo vienam fiziniam kapui. Jei viename kape palaidoti daugiau nei 7 asmenys, prašome susisiekti su mumis.</p>
                                </Panel>
                                <Panel header="Ar QR kodas ilgaamžis?" key="9">
                                    <p>Medžiaga, iš kurios gaminami QR kodai ir jų graviravimo technologija buvo sukurta JAV armijai ir naudojama ne vieną dešimtmetį ( nerudyjantis plienas ). Mūsų pagamintas unikalus QR kodas taip pat tarnaus ilgus dešimtmečius.</p>
                                </Panel>
                                <Panel header="Ar QR kodus leidžiama klijuoti ant antkapių?" key="10">
                                    <p>Paminklas yra privatus statinys ir ant jo gali būti rašomi vardai, pavardės, klijuojamos nuotraukos, pateikiama informacija apie velionio gyvenimą, o kapinės yra vieša vieta, kur gali apsilankyti kiekvienas. Jokių kapinių vidaus taisyklės QR kodų ant paminklų tvirtinti nedraudžia.</p>
                                </Panel>
                                <Panel header="Kaip tvirtinamas QR kodas prie paminklo?" key="11">
                                    <p>QR kodas klijuojamas ant poliruoto, matinio ar glotnaus paviršiaus. Akmuo, ar medžiaga, ant kurios bus klijuojamas QR kodas, negali būti įšalę. Klijavimo metu vidutinė paros temperatūra rekomenduojama ne žemesnė kaip +5 laipsniai Celsijaus.
                                        Paviršius, ant kurio bus klijuojamas QR kodas, turi būti sausas, kruopščiai nuvalytas nuo dulkių, purvo. Rekomenduojama prieš klijuojant  paviršių pavalyti acetonu ar kita riebalus pašalinančia medžiaga.
                                        Paruošus paviršiaus vietą, ant kurios bus klijuojamas QR kodas, nuo jo nuplėšiama apsauginė plėvelė. QR kodą stipriai prispaudus palaikoma keletą sekundžių.
                                    </p>
                                </Panel>
                                <Panel header="Ką daryti pametus ar sugadinus QR kodą?" key="12">
                                    <p>Jei pametėte ar sugadinote QR kodą, susisiekite su mumis prisijungę prie sistemos <Link to="/help" className="nav-link footer-nav">Pagalba</Link> puslapyje ir mes Jums pagaminsime naują unikalų QR kodą. Papildomo QR kodo gamyba ir siuntimas yra mokama paslauga.</p>
                                </Panel>
                                <Panel header="Per kiek laiko aš gausiu QR kodą?" key="13">
                                    <p>Apmokėję pasirinktą planą, unikalų QR kodą įprastu paštu Jums išsiųsime per 5 darbo dienas.</p>
                                </Panel>
                            </Collapse>
                        </Col>
                    </Row>
                </div>
            </div>
            <ItsFuture />
            <SiteFooter />
        </div>
    );
}
export default FrequentlyAskedQuestions;

import { createActions } from 'redux-actions';
import keyMirror from 'key-mirror';

export const constants = keyMirror({
    GET_GRAVES_REQUEST: null,
    GET_GRAVES_SUCCESS: null,
    GET_GRAVES_FAILURE: null,

    GET_SINGLE_GRAVE_REQUEST: null,
    GET_SINGLE_GRAVE_SUCCESS: null,
    GET_SINGLE_GRAVE_FAILURE: null,

    SELECT_GRAVE_REQUEST: null,

    CREATE_GRAVE_REQUEST: null,
    CREATE_GRAVE_SUCCESS: null,
    CREATE_GRAVE_FAILURE: null,

    GET_GRAVE_PEOPLE_REQUEST: null,
    GET_GRAVE_PEOPLE_SUCCESS: null,
    GET_GRAVE_PEOPLE_FAILURE: null,

    ADD_GRAVE_PEOPLE_REQUEST: null,
    ADD_GRAVE_PEOPLE_SUCCESS: null,
    ADD_GRAVE_PEOPLE_FAILURE: null,

    SELECT_GRAVE_PEOPLE_REQUEST: null,
    UPDATE_SELECTED_GRAVE_PEOPLE_IMAGE_REQUEST: null,
    UPDATE_SELECTED_GRAVE_IMAGE_REQUEST: null,

    UPDATE_SINGLE_PEOPLE_REQUEST: null,
    UPDATE_SINGLE_PEOPLE_SUCCESS: null,
    UPDATE_SINGLE_PEOPLE_FAILURE: null,

    GET_SINGLE_PERSON_REQUEST: null,
    GET_SINGLE_PERSON_SUCCESS: null,
    GET_SINGLE_PERSON_FAILURE: null,

    REMOVE_PEOPLE_PICTURE_REQUEST: null,
    REMOVE_PEOPLE_PICTURE_SUCCESS: null,
    REMOVE_PEOPLE_PICTURE_FAILURE: null,

    ADD_PEOPLE_VIDEO_REQUEST: null,
    ADD_PEOPLE_VIDEO_SUCCESS: null,
    ADD_PEOPLE_VIDEO_FAILURE: null,

    REMOVE_PEOPLE_VIDEO_REQUEST: null,
    REMOVE_PEOPLE_VIDEO_SUCCESS: null,
    REMOVE_PEOPLE_VIDEO_FAILURE: null,

    SAVE_LIFE_EVENTS_REQUEST: null,
    SAVE_LIFE_EVENTS_SUCCESS: null,
    SAVE_LIFE_EVENTS_FAILURE: null,

    REMOVE_GUEST_BOOK_RECORD_REQUEST: null,
    REMOVE_GUEST_BOOK_RECORD_SUCCESS: null,
    REMOVE_GUEST_BOOK_RECORD_FAILURE: null,

    DELETE_PERSON_REQUEST: null,
    DELETE_PERSON_SUCCESS: null,
    DELETE_PERSON_FAILURE: null,

    UPDATE_SINGLE_GRAVE_REQUEST: null,
    UPDATE_SINGLE_GRAVE_SUCCESS: null,
    UPDATE_SINGLE_GRAVE_FAILURE: null,

    FIND_BY_QR_CODE_REQUEST: null,
    FIND_BY_QR_CODE_SUCCESS: null,
    FIND_BY_QR_CODE_FAILURE: null,

    FIND_GRAVE_BY_SLUG_REQUEST: null,
    FIND_GRAVE_BY_SLUG_SUCCESS: null,
    FIND_GRAVE_BY_SLUG_FAILURE: null,

    SEND_GUEST_BOOK_RECORD_REQUEST: null,
    SEND_GUEST_BOOK_RECORD_SUCCESS: null,
    SEND_GUEST_BOOK_RECORD_FAILURE: null,

    GENERATE_QR_CODE_FOR_GRAVE_REQUEST: null,
    GENERATE_QR_CODE_FOR_GRAVE_SUCCESS: null,
    GENERATE_QR_CODE_FOR_GRAVE_FAILURE: null,
});

export const {
    getGravesRequest,
    getGravesSuccess,
    getGravesFailure,
} = createActions(
    constants.GET_GRAVES_REQUEST,
    constants.GET_GRAVES_SUCCESS,
    constants.GET_GRAVES_FAILURE,
);

export const {
    getSingleGraveRequest,
    getSingleGraveSuccess,
    getSingleGraveFailure,
} = createActions(
    constants.GET_SINGLE_GRAVE_REQUEST,
    constants.GET_SINGLE_GRAVE_SUCCESS,
    constants.GET_SINGLE_GRAVE_FAILURE,
);

export const {
    selectGraveRequest,
} = createActions(
    constants.SELECT_GRAVE_REQUEST,
);

export const {
    createGraveRequest,
    createGraveSuccess,
    createGraveFailure,
} = createActions(
    constants.CREATE_GRAVE_REQUEST,
    constants.CREATE_GRAVE_SUCCESS,
    constants.CREATE_GRAVE_FAILURE,
);

export const {
    getGravePeopleRequest,
    getGravePeopleSuccess,
    getGravePeopleFailure,
} = createActions(
    constants.GET_GRAVE_PEOPLE_REQUEST,
    constants.GET_GRAVE_PEOPLE_SUCCESS,
    constants.GET_GRAVE_PEOPLE_FAILURE,
);

export const {
    addGravePeopleRequest,
    addGravePeopleSuccess,
    addGravePeopleFailure,
} = createActions(
    constants.ADD_GRAVE_PEOPLE_REQUEST,
    constants.ADD_GRAVE_PEOPLE_SUCCESS,
    constants.ADD_GRAVE_PEOPLE_FAILURE,
);

export const {
    selectGravePeopleRequest,
} = createActions(
    constants.SELECT_GRAVE_PEOPLE_REQUEST,
);

export const {
    updateSelectedGravePeopleImageRequest,
} = createActions(
    constants.UPDATE_SELECTED_GRAVE_PEOPLE_IMAGE_REQUEST,
);

export const {
    updateSelectedGraveImageRequest,
} = createActions(
    constants.UPDATE_SELECTED_GRAVE_IMAGE_REQUEST,
);

export const {
    updateSinglePeopleRequest,
    updateSinglePeopleSuccess,
    updateSinglePeopleFailure,
} = createActions(
    constants.UPDATE_SINGLE_PEOPLE_REQUEST,
    constants.UPDATE_SINGLE_PEOPLE_SUCCESS,
    constants.UPDATE_SINGLE_PEOPLE_FAILURE,
);

export const {
    getSinglePersonRequest,
    getSinglePersonSuccess,
    getSinglePersonFailure,
} = createActions(
    constants.GET_SINGLE_PERSON_REQUEST,
    constants.GET_SINGLE_PERSON_SUCCESS,
    constants.GET_SINGLE_PERSON_FAILURE,
);

export const {
    removePeoplePictureRequest,
    removePeoplePictureSuccess,
    removePeoplePictureFailure,
} = createActions(
    constants.REMOVE_PEOPLE_PICTURE_REQUEST,
    constants.REMOVE_PEOPLE_PICTURE_SUCCESS,
    constants.REMOVE_PEOPLE_PICTURE_FAILURE,
);

export const {
    addPeopleVideoRequest,
    addPeopleVideoSuccess,
    addPeopleVideoFailure,
} = createActions(
    constants.ADD_PEOPLE_VIDEO_REQUEST,
    constants.ADD_PEOPLE_VIDEO_SUCCESS,
    constants.ADD_PEOPLE_VIDEO_FAILURE,
);

export const {
    removePeopleVideoRequest,
    removePeopleVideoSuccess,
    removePeopleVideoFailure,
} = createActions(
    constants.REMOVE_PEOPLE_VIDEO_REQUEST,
    constants.REMOVE_PEOPLE_VIDEO_SUCCESS,
    constants.REMOVE_PEOPLE_VIDEO_FAILURE,
);

export const {
    saveLifeEventsRequest,
    saveLifeEventsSuccess,
    saveLifeEventsFailure,
} = createActions(
    constants.SAVE_LIFE_EVENTS_REQUEST,
    constants.SAVE_LIFE_EVENTS_SUCCESS,
    constants.SAVE_LIFE_EVENTS_FAILURE,
);

export const {
    removeGuestBookRecordRequest,
    removeGuestBookRecordSuccess,
    removeGuestBookRecordFailure,
} = createActions(
    constants.REMOVE_GUEST_BOOK_RECORD_REQUEST,
    constants.REMOVE_GUEST_BOOK_RECORD_SUCCESS,
    constants.REMOVE_GUEST_BOOK_RECORD_FAILURE,
);

export const {
    deletePersonRequest,
    deletePersonSuccess,
    deletePersonFailure,
} = createActions(
    constants.DELETE_PERSON_REQUEST,
    constants.DELETE_PERSON_SUCCESS,
    constants.DELETE_PERSON_FAILURE,
);

export const {
    updateSingleGraveRequest,
    updateSingleGraveSuccess,
    updateSingleGraveFailure,
} = createActions(
    constants.UPDATE_SINGLE_GRAVE_REQUEST,
    constants.UPDATE_SINGLE_GRAVE_SUCCESS,
    constants.UPDATE_SINGLE_GRAVE_FAILURE,
);

export const {
    findByQrCodeRequest,
    findByQrCodeSuccess,
    findByQrCodeFailure,
} = createActions(
    constants.FIND_BY_QR_CODE_REQUEST,
    constants.FIND_BY_QR_CODE_SUCCESS,
    constants.FIND_BY_QR_CODE_FAILURE,
);

export const {
    findGraveBySlugRequest,
    findGraveBySlugSuccess,
    findGraveBySlugFailure,
} = createActions(
    constants.FIND_GRAVE_BY_SLUG_REQUEST,
    constants.FIND_GRAVE_BY_SLUG_SUCCESS,
    constants.FIND_GRAVE_BY_SLUG_FAILURE,
);

export const {
    sendGuestBookRecordRequest,
    sendGuestBookRecordSuccess,
    sendGuestBookRecordFailure,
} = createActions(
    constants.SEND_GUEST_BOOK_RECORD_REQUEST,
    constants.SEND_GUEST_BOOK_RECORD_SUCCESS,
    constants.SEND_GUEST_BOOK_RECORD_FAILURE,
);

export const {
    generateQrCodeForGraveRequest,
    generateQrCodeForGraveSuccess,
    generateQrCodeForGraveFailure,
} = createActions(
    constants.GENERATE_QR_CODE_FOR_GRAVE_REQUEST,
    constants.GENERATE_QR_CODE_FOR_GRAVE_SUCCESS,
    constants.GENERATE_QR_CODE_FOR_GRAVE_FAILURE,
);

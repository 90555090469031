import React, { useState, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Form, Input, Button, Modal, Spin, Divider, message } from 'antd';
import './settingsForm.scss';

import {
    updateUserRequest,
    getUserDataRequest,
} from '../../../core/user/userActions';

const SettingsForm = () => {

    const dispatch = useDispatch();
    const user = useSelector(state => state.user);

    useEffect(() => {
        dispatch(getUserDataRequest());
    }, []);

    const [forgetPasswordModalVisible, setForgetPasswordModalVisible] = useState(false);

    const [form] = Form.useForm();

    const onFinish = (formData) => {
        const validate = true;

        if (validate) {
            const data = {
                full_name: formData.full_name,
                city: formData.city,
                street: formData.street,
                house_number: formData.house_number,
                post_code: formData.post_code,
                phone: formData.phone,
            };
            dispatch(updateUserRequest(data));
        }
    };

    const onFinishFailed = ({ errorFields }) => {
        form.scrollToField(errorFields[0].name);
    };

    const handleForgotFinish = (values) => {
        let error = false;

        if (values.new_password !== values.password_repeat) {
            error = true;
            message.warning('Slaptažodžiai nesutampa!');
        }

        if (!error) {
            dispatch(updateUserRequest(values));
            setForgetPasswordModalVisible(false);
        }
    };

    const forgotFinishFailed = ({ errorFields }) => {
        form.scrollToField(errorFields[0].name);
    };

    return (
        <>
            <Spin spinning={user.isFetching}>
                <div className="settings-form">
                    <Form
                        onFinish={onFinish}
                        onFinishFailed={onFinishFailed}
                        initialValues={{
                            full_name: user?.userData?.profile?.full_name,
                            email: user?.userData?.email,
                            city: user?.userData?.profile?.city,
                            street: user?.userData?.profile?.street,
                            house_number: user?.userData?.profile?.house_number,
                            post_code: user?.userData?.profile?.post_code,
                            phone: user?.userData?.profile?.phone,
                        }}
                        layout="vertical">
                        <Form.Item
                            name="full_name"
                            label="Vardas Pavardė"
                            style={{ marginTop: '30px' }}
                            rules={[{ required: true, message: 'Prašome įvesti savo vardą, pavardę.' }]}>
                            <Input placeholder="Vardas Pavardė" />
                        </Form.Item>
                        <Form.Item
                            label="El. paštas"
                            name="email"
                            rules={[{ type: 'email', message: 'The input is not a valid email!' }, { required: true, message: 'Please input your email.' }]}>
                            <Input placeholder="Enter your email" disabled />
                        </Form.Item>

                        <Divider />

                        <h3>Įsitikinkite, kad nurodėte pilną adresą bei pašto kodą. Nurodytu adresu Jums išsiųsime QR kodą.</h3>

                        <Form.Item
                            name="city"
                            label="Miestas"
                            style={{ marginTop: '30px' }}>
                            <Input placeholder="Miestas" />
                        </Form.Item>

                        <Form.Item
                            name="street"
                            label="Gatvė"
                            style={{ marginTop: '30px' }}>
                            <Input placeholder="Gatvė" />
                        </Form.Item>

                        <Form.Item
                            name="house_number"
                            label="Namo buto numeris"
                            style={{ marginTop: '30px' }}>
                            <Input placeholder="10A - 12" />
                        </Form.Item>

                        <Form.Item
                            name="post_code"
                            label="Pašto kodas"
                            style={{ marginTop: '30px' }}>
                            <Input />
                        </Form.Item>

                        <Form.Item
                            name="phone"
                            label="Telefonas"
                            style={{ marginTop: '30px' }}
                            rules={[
                                {
                                    pattern: /^[0-9+]*$/,
                                    message: 'Telefonas gali būti sudarytas tik iš skaičių ir simbolio \'+\'.',
                                },
                            ]}>
                            <Input />
                        </Form.Item>

                        <Form.Item>
                            <Button type="primary" size="large" htmlType="submit" block className="mt-3 primary-btn-style" loading={user.isFetching}>
                                Atnaujinti
                            </Button>
                        </Form.Item>
                    </Form>
                    <div className="text-center">
                        <Button onClick={setForgetPasswordModalVisible} type="link" className="button-link" href="#">
                            Keisti slaptažodį
                        </Button>
                    </div>
                </div>
            </Spin>

            <Modal
                centered
                width={540}
                footer={null}
                visible={forgetPasswordModalVisible}
                onOk={() => { setForgetPasswordModalVisible(false); }}
                onCancel={() => { setForgetPasswordModalVisible(false); }}>
                <div className="chnage-password-modal-content">
                    <h3 className="change-password-title">Keisti splatažodį</h3>
                    <div className="form-wrap">
                        <Form onFinish={handleForgotFinish} onFinishFailed={forgotFinishFailed} layout="vertical">
                            <Form.Item
                                label="Dabartinis slaptažodis"
                                name="old_password"
                                rules={[{ required: true, message: 'Prašome įvesti dabartinį slaptažodį' }]}>
                                <Input.Password />
                            </Form.Item>
                            <Form.Item
                                label="Naujas slaptažodis"
                                name="new_password"
                                rules={[
                                    { required: true, message: 'Prašome įvesti naują slaptažodį' },
                                    { min: 7, message: 'Slaptažodį turi sudaryti minimum 7 ženklai' },
                                    { pattern: /^(?=.*[a-zA-Z])(?=.*[0-9])/, message: 'Slaptažodį turi sudaryti raidės ir skaitmenys' },
                                ]}>
                                <Input.Password />
                            </Form.Item>
                            <Form.Item
                                label="Pakartokite naują slaptažodį"
                                name="password_repeat"
                                rules={[
                                    { required: true, message: 'Prašome pakartoti naują slaptažodį' },
                                    { min: 7, message: 'Slaptažodį turi sudaryti minimum 7 ženklai' },
                                    { pattern: /^(?=.*[a-zA-Z])(?=.*[0-9])/, message: 'Slaptažodį turi sudaryti raidės ir skaitmenys' },
                                ]}>
                                <Input.Password />
                            </Form.Item>

                            <Button type="primary" htmlType="submit" className="wide">
                                Pakeisti
                            </Button>
                            <Button className="link-green btn-empty underlined secondary-btn" onClick={() => { setForgetPasswordModalVisible(false); }}>
                                Atšaukti
                            </Button>

                        </Form>
                    </div>
                </div>
            </Modal>

        </>
    );
};

export default SettingsForm;

import React, { useEffect, useState } from 'react';
import { Helmet } from 'react-helmet';
import { Col, Row, Modal } from 'antd';
import Lottie from 'lottie-react';
import { Link, useHistory } from 'react-router-dom';
import { ArrowRightOutlined, PlayCircleOutlined, LoginOutlined } from '@ant-design/icons';
// import { Link } from 'react-router-dom';

import SiteNavigation from '../../components/SiteNavigation';
import SiteFooter from '../../components/SiteFooter';
import ItsFuture from '../../components/ItsFuture';

import useUser from '../../core/user/useUser';
// import history from '../../core/utils/history';
import icon from '../../assets/favicon.ico';
import familyGraveImage from '../../assets/images/header-2.webp';
import qrCodeAnimationTop from '../../assets/documents/animation_lna0wnub.json';
import tvgBgShape2 from '../../assets/images/tvg-bg-shape-2.svg';
import tvgBgShape3 from '../../assets/images/tvg-bg-shape-3.svg';
import tvgIcon1 from '../../assets/images/tvg-icon-1.svg';
import tvgIcon2 from '../../assets/images/tvg-icon-2.svg';
import tvgIcon3 from '../../assets/images/tvg-icon-3.svg';
import tvgIcon4 from '../../assets/images/tvg-icon-4.svg';
import tvgIcon5 from '../../assets/images/tvg-icon-5.svg';
import tvgIcon6 from '../../assets/images/tvg-icon-6.svg';
import hpImg3 from '../../assets/images/hp-img-3.png';

import howItWorksImg1 from '../../assets/images/how-it-works-1.webp';
import howItWorksImg2 from '../../assets/images/how-it-works-2.webp';
import howItWorksImg3 from '../../assets/images/how-it-works-3.webp';
import howItWorksImg4 from '../../assets/images/how-it-works-4.webp';

import './homePge.scss';

const { APP_NAME } = require('../../core/constants').default;

function HomePage() {

    const history = useHistory(); // Initialize the navigate function
    const { user } = useUser();
    const [isVideoModalOpen, setIsVideoModalOpen] = useState(false);

    useEffect(() => {

        if (user?.isLoggedIn === true) {
            // history.push('/dashboard');
        }
    }, []);

    return (
        <div className="homepage-wrap">
            <Helmet
                title="Naujas būdas prisiminti ir pagerbti savo artimuosius"
                titleTemplate={`${APP_NAME} - %s`}
                defaultTitle={`${APP_NAME}`}
                meta={[
                    { name: 'description', content: 'Virtualus Kapas yra revoliucinė platforma, kuri leidžia jums kurti ir puoselėti savo artimųjų virtualius kapus. Virtualios Kapinės leidžia sujunkti fizinį ir virtualųjį pasaulį su QR kodais, interaktyviais žemėlapiais ir individualiais profiliais. Prisijunkite prie mūsų bendruomenės ir išsaugokite savo artimųjų atminimą ateinančioms kartoms' },
                    { property: 'og:type', content: 'website' },
                    { property: 'og:image', content: familyGraveImage },
                ]}>
                <link rel="shortcut icon" href={icon} />
            </Helmet>
            <SiteNavigation />
            <div className="top-home-block">
                <div className="general-container">
                    <Row>
                        <Col xs={24} sm={24} md={24} lg={8} xl={8} className="left-container">
                            <h1>Sveiki atvykę į mūsų Virtualus Kapas svetainę</h1>
                            <p>
                                Mūsų Virtualios Kapinės leidžia jums sukurti virtualius kapus savo artimiesiems.
                                QR kodo pagalba jūs galite lengvai sujungti fizinį kapą su virtualiu
                                ir pasiekti jį bet kur ir bet kada. Jūs taip pat galite dalintis nuotraukomis,
                                vaizdo įrašais ir istorijomis su draugais ir šeima. Mūsų tikslas yra padėti
                                jums pagerbti ir išsaugoti savo artimųjų atminimą ilgam. Su mūsų platforma,
                                jūs galite pamatyti daug daugiau nei tik vardą ir datas. Nuskanavę QR kodą Jūs galėsite pamatyti
                                jų gyvenimo kelionę, asmenybę ir jų paliktą pėdsaką.
                            </p>
                            <div className="btn-wrapper">
                                <Link to="/prisijungti" className="btn-link-secondary">Išbandykite dabar <ArrowRightOutlined /></Link>
                            </div>
                            <div className="parallax-roundel-wrap">
                                <Lottie animationData={qrCodeAnimationTop} loop />
                            </div>
                        </Col>
                        <Col xs={24} sm={24} md={24} lg={16} xl={16} className="right-container">
                            <img src={familyGraveImage} alt="Šeima prie virtualaus kapo" className="img-responsive side-logo" />
                        </Col>
                    </Row>
                </div>
            </div>
            <div className="how-it-works-block section no-top">
                <img src={tvgBgShape2} loading="lazy" alt="" className="bg-shape _2" />
                <div className="ani-up">
                    <div className="copy-holder">
                        <h2>Virtualios Kapinės <br /> Kodėl verta mus rinktis</h2>
                        <p>Mūsų novatoriškas interneto portalas siūlo daugybę funkcijų, skirtų sukurti ilgalaikį atminimą jūsų artimiesiems:</p>
                    </div>
                </div>
                <div className="general-container">
                    <Row justify="space-between">
                        <Col className="ani-up custom-col">
                            <div className="card">
                                <div className="how-icon-wrap">
                                    <img src={tvgIcon1} loading="lazy" alt="" className="how-icon" />
                                </div>
                                <h3 className="how-h">Individualūs profiliai</h3>
                                <p>Kurkite suasmenintus virtualius kapus su išsamiais profiliais, nuotraukomis ir vaizdo įrašais</p>
                            </div>
                        </Col>
                        <Col className="ani-up custom-col">
                            <div className="card">
                                <div className="how-icon-wrap">
                                    <img src={tvgIcon4} loading="lazy" alt="" className="how-icon" />
                                </div>
                                <h3 className="how-h">Interaktyvūs žemėlapiai</h3>
                                <p>Interaktyviame žemėlapyje nurodykite tikslias kapų vietas, kad būtų lengviau naršyti</p>
                            </div>
                        </Col>
                        <Col className="ani-up custom-col">
                            <div className="card">
                                <div className="how-icon-wrap">
                                    <img src={tvgIcon5} loading="lazy" alt="" className="how-icon" />
                                </div>
                                <h3 className="how-h">QR Kodai</h3>
                                <p>Generuokite tinkintus QR kodus, kad sujungtumėte fizinius kapus su virtualiais kapais</p>
                            </div>
                        </Col>
                        <Col className="ani-up custom-col">
                            <div className="card">
                                <div className="how-icon-wrap">
                                    <img src={tvgIcon6} loading="lazy" alt="" className="how-icon" />
                                </div>
                                <h3 className="how-h">Virtuali prieiga</h3>
                                <p>Pasiekite virtualius kapus bet kada, bet kur, iš viso pasaulio</p>
                            </div>
                        </Col>
                        <Col className="ani-up custom-col">
                            <div className="card">
                                <div className="how-icon-wrap">
                                    <img src={tvgIcon3} loading="lazy" alt="" className="how-icon" />
                                </div>
                                <h3 className="how-h">Puoselėkite prisiminimus</h3>
                                <p>Dalinkitės brangiais prisiminimais ir istorijomis įdomiau</p>
                            </div>
                        </Col>
                        <Col className="ani-up custom-col">
                            <div className="card">
                                <div className="how-icon-wrap">
                                    <img src={tvgIcon2} loading="lazy" alt="" className="how-icon" />
                                </div>
                                <h3 className="how-h">Sukurkite bendruomenę</h3>
                                <p>Puoselėkite bendruomeniškumo ir palaikymo jausmą</p>
                            </div>
                        </Col>
                    </Row>
                </div>
            </div>
            <div className="video-block">
                <div className="general-container" onClick={() => setIsVideoModalOpen(true)}>
                    <div className="video-button">
                        <PlayCircleOutlined />
                        <div className="video-text">Peržiūrėkite mūsų video</div>
                    </div>
                </div>
            </div>
            <div className="how-it-works-block section no-top">
                <img src={tvgBgShape2} loading="lazy" alt="" className="bg-shape _2" />
                <div className="ani-up">
                    <div className="copy-holder">
                        <h2>Virtualios Kapinės <br /> Kaip tai veikia</h2>
                        <p>Žingsnis po žingsnio vadovas:</p>
                    </div>
                </div>
                <div className="steps-wrapper">
                    <div className="step-item">
                        <div className="step-image">
                            <img src={howItWorksImg1} alt="Šeima prie virtualaus kapo" className="img-responsive side-logo" />
                        </div>
                        <div className="step-description">
                            <h3 className="step-title-text"> 1. Prisiregistruokite nemokamai</h3>
                            <p className="step-description-text">Susikurkite nemokamą paskyrą naudodami el. pašto adresą ir saugų slaptažodį</p>
                        </div>
                    </div>
                    <div className="step-item">
                        <div className="step-image">
                            <img src={howItWorksImg2} alt="Šeima prie virtualaus kapo" className="img-responsive side-logo" />
                        </div>
                        <div className="step-description">
                            <h3 className="step-title-text">2. Sukurkite virtualų kapą</h3>
                            <p className="step-description-text">Pridėkite naują virtualų kapą ir užpildykite išsamią informaciją apie šiame kape palaidotus asmenis. Sudėkite gyvenimo aprašymus nuotraukas vaizo įrašus ir t.t.. Vienai kapavietei galėsite pridėti iki 7 asmenų. Taip pat galėsite peržiūrėti kaip atrodys Jūsų kapas</p>
                        </div>
                    </div>
                    <div className="step-item">
                        <div className="step-image">
                            <img src={howItWorksImg3} alt="Šeima prie virtualaus kapo" className="img-responsive side-logo" />
                        </div>
                        <div className="step-description">
                            <h3 className="step-title-text">3. Pasirinkite mokėjimo planą ir užsisakykite QR kodą</h3>
                            <p className="step-description-text">jei esate patenkinti savo virtualiu kapu, pasirinkite mokėjimo planą ir užbaikite užsakymą. Sukursime individualų nerūdijančio plieno QR kodą, kuris bus išsiųstas jūsų namų adresu.</p>
                        </div>
                    </div>
                    <div className="step-item">
                        <div className="step-image">
                            <img src={howItWorksImg4} alt="Šeima prie virtualaus kapo" className="img-responsive side-logo" />
                        </div>
                        <div className="step-description">
                            <h3 className="step-title-text">4. Priklijuokite savo QR kodą prie kapavietės</h3>
                            <p className="step-description-text">Gavę QR kodą, priklijuokite jį ant fizinio antkapio. Nuskaitytas QR kodas parodys visą jūsų pridėtą informaciją ir prisiminimus. Tai leis lankytojams pasiekti Jūsų sukurtą virtualų kapą ir užtikrinant, kad jūsų mylimųjų prisiminimai visada bus prieinami.</p>
                        </div>
                    </div>
                </div>
            </div>
            <div className="video-block">
                <div className="general-container" onClick={() => history.push('/prisijungti')}>
                    <div className="video-button">
                        <LoginOutlined />
                        <div className="video-text">Prisijunkite dabar</div>
                    </div>
                </div>
            </div>
            <div className="why-important-block">
                <div className="general-container">
                    <Row>
                        <Col xs={24} sm={24} md={16} lg={16} xl={16} className="left-container ani-up">
                            <img src={hpImg3} loading="lazy" alt="" className="side-img" />
                        </Col>
                        <Col xs={24} sm={24} md={8} lg={8} xl={8} className="right-container ani-up">
                            <div className="copy-holder">
                                <h2>Kodėl tai svarbu?</h2>
                                <p>Naudodamiesi mūsų virtualaus kapo svetaine galite išsaugoti savo artimųjų atminimą ateinančioms kartoms ir užtikrinti, kad jų istorijos niekada nebus pamirštos. Sujungdami fizinį ir virtualų pasaulius, lankytojams suteikiame galimybę lengvai atiduoti pagarbą, sužinoti daugiau apie mirusiuosius ir pagerbti jų gyvenimą įtraukiančiu ir prieinamu būdu.</p>
                            </div>
                        </Col>
                    </Row>
                </div>
                <img src={tvgBgShape3} loading="lazy" alt="" className="bg-shape _3" />
            </div>
            <ItsFuture />
            <SiteFooter />
            <Modal width={1000} className="video-modal" title="Video" open={isVideoModalOpen} onCancel={() => setIsVideoModalOpen(false)} footer={false}>
                <iframe width="560" height="600" src="https://www.youtube.com/embed/AphI1oII6P4?si=WN9V17yisJsStTBf" title="YouTube video player" frameBorder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share" allowFullScreen />
            </Modal>
        </div>
    );
}
export default HomePage;

import React, { useState, useEffect } from 'react';
import { Timeline, Button, Select, DatePicker, message, Input } from 'antd';
import { DeleteOutlined, PlusOutlined, SaveOutlined } from '@ant-design/icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { find, sortBy, isEmpty } from 'underscore';
import { useSelector, useDispatch } from 'react-redux';
import { PropTypes } from 'prop-types';

import 'moment/locale/lt';
import 'dayjs/locale/lt';
import locale from 'antd/es/date-picker/locale/lt_LT';
import moment from 'moment';

import {
    faBook,
    faGraduationCap,
    faChild,
    faHeartBroken,
    faHome,
    faBriefcase,
    faRing,
    faUserFriends,
    faWalking,
    faBaby,
    faRibbon,
    faPeopleGroup,
    faFlag,
    faCalendarDays,
    // faMonument,
    faSpa,
} from '@fortawesome/free-solid-svg-icons';
import './timeline.scss';

import {
    saveLifeEventsRequest,
} from '../../core/grave/graveActions';

const eventIcons = [
    {
        key: 'BORN',
        title: 'Gimė',
        icon: <FontAwesomeIcon icon={faBaby} />,
        isLocked: true,
    },
    {
        key: 'PASSED_AWAY',
        title: 'Mirė',
        icon: <FontAwesomeIcon icon={faSpa} />,
        isLocked: true,
    },
    {
        key: 'GRADUATED_ELEMENTARY_SCHOOL',
        title: 'Baigė pradinę mokyklą',
        icon: <FontAwesomeIcon icon={faBook} />,
    },
    {
        key: 'GRADUATED_MIDDLE_SCHOOL',
        title: 'Baigė vidurinę mokyklą',
        icon: <FontAwesomeIcon icon={faGraduationCap} />,
    },
    {
        key: 'GRADUATED_HIGH_SCHOOL',
        title: 'Baigė gimnaziją',
        icon: <FontAwesomeIcon icon={faGraduationCap} />,
    },
    {
        key: 'GRADUATED_COLLEGE',
        title: 'Baigė koledžą',
        icon: <FontAwesomeIcon icon={faGraduationCap} />,
    },
    {
        key: 'GRADUATED_UNIVERSITY',
        title: 'Baigė universitetą',
        icon: <FontAwesomeIcon icon={faGraduationCap} />,
    },
    {
        key: 'GRADUATED_GRAD_SCHOOL',
        title: 'Baigė aspirantūrą',
        icon: <FontAwesomeIcon icon={faGraduationCap} />,
    },
    {
        key: 'SIBLING_BORN',
        title: 'Gimė brolis/sesuo',
        icon: <FontAwesomeIcon icon={faBaby} />,
    },
    {
        key: 'SIBLING_DEATH',
        title: 'Mirė brolis/sesuo',
        icon: <FontAwesomeIcon icon={faRibbon} />,
    },
    {
        key: 'PARENT_DEATH',
        title: 'Mirė vienas iš tėvų',
        icon: <FontAwesomeIcon icon={faRibbon} />,
    },
    {
        key: 'MOVED',
        title: 'Persikraustė',
        icon: <FontAwesomeIcon icon={faHome} />,
    },
    {
        key: 'STARTED_JOB',
        title: 'Pradėjo dirbti',
        icon: <FontAwesomeIcon icon={faBriefcase} />,
    },
    {
        key: 'MARRIED',
        title: 'Susituokė',
        icon: <FontAwesomeIcon icon={faRing} />,
    },
    {
        key: 'DIVORCED',
        title: 'Išsiskyrė',
        icon: <FontAwesomeIcon icon={faHeartBroken} />,
    },
    {
        key: 'HAD_CHILD',
        title: 'Susilaukė vaiko',
        icon: <FontAwesomeIcon icon={faChild} />,
    },
    {
        key: 'HAD_GRANDCHILD',
        title: 'Sulaukė anūko (-ės)',
        icon: <FontAwesomeIcon icon={faPeopleGroup} />,
    },
    {
        key: 'HAD_GREAT_GRANDCHILD',
        title: 'Sulaukė proanūkio (-ės)',
        icon: <FontAwesomeIcon icon={faPeopleGroup} />,
    },
    {
        key: 'MET',
        title: 'Sutiko',
        icon: <FontAwesomeIcon icon={faUserFriends} />,
    },
    {
        key: 'RETIRED',
        title: 'Išėjo į pensiją',
        icon: <FontAwesomeIcon icon={faWalking} />,
    },
    {
        key: 'OTHER',
        title: 'Kita',
        icon: <FontAwesomeIcon icon={faFlag} />,
    },
];

const TimelineComponent = ({ disableAdmin }) => {
    const dispatch = useDispatch();
    const [events, setEvents] = useState([]);
    const [eventType, setEventType] = useState(null);
    const [eventDescription, setEventDescription] = useState('');
    const [eventDate, setEventDate] = useState('');
    const [messageApi, contextHolder] = message.useMessage();
    const grave = useSelector(state => state.grave);
    const selectedGravePeople = grave?.selectedGravePeople;

    useEffect(() => {
        const localEvents = selectedGravePeople.lifeEvents;
        if (isEmpty(localEvents)) {
            const currentEventType = 'BORN';
            const newEventBorn = {
                event_type: currentEventType,
                date: selectedGravePeople.birth_date,
                description: eventDescription,
                data: find(eventIcons, (eventIcon) => eventIcon.key === currentEventType),
            };

            const currentEventDeath = 'PASSED_AWAY';
            const newEventDeath = {
                event_type: currentEventDeath,
                date: selectedGravePeople.death_date,
                description: eventDescription,
                data: find(eventIcons, (eventIcon) => eventIcon.key === currentEventDeath),
            };

            setEvents([newEventBorn, newEventDeath]);
        } else {
            const newEvents = localEvents.map((event) => ({
                id: event.id,
                event_type: event.event_type,
                date: event.date,
                description: event.description,
                data: find(eventIcons, (eventIcon) => eventIcon.key === event.event_type),
            }));
            setEvents(newEvents);
        }
    }, []);

    const handleAddEvent = () => {
        let error = false;

        if (eventType === '' || eventType === null) {
            error = true;
            messageApi.open({
                type: 'error',
                content: 'Pasirinkite įvikį',
            });
        }

        if (eventDate === '') {
            error = true;
            messageApi.open({
                type: 'error',
                content: 'Pasirinkite įvikio datą',
            });
        }

        if (!error) {
            const newEvent = {
                event_type: eventType,
                date: eventDate,
                description: eventDescription,
                data: find(eventIcons, (eventIcon) => eventIcon.key === eventType),
            };
            setEvents([newEvent, ...events]);
            setEventType('');
            setEventDescription('');
            // setEventDate({ year: '', month: '', day: '' });
        }
    };

    const handleSelectChange = (value) => {
        setEventType(value);
    };

    const handleDateChange = (value) => {
        setEventDate(value.format('YYYY-MM-DD'));
    };

    const removeEvent = (eventKey) => {
        const sortedEvents = sortBy(events, 'date');
        sortedEvents.splice(eventKey, 1);
        setEvents(sortedEvents);
    };

    const saveAllEvents = () => {
        const allEvents = [...events];
        const sendEvents = allEvents.map(curVal => {
            const newCurVal = { ...curVal };
            delete newCurVal.data;
            return newCurVal;
        });
        dispatch(saveLifeEventsRequest({ events: sendEvents, grave_people_id: grave?.selectedGravePeople?.id }));
    };

    return (
        <div className="timeline-container">
            {contextHolder}
            {!disableAdmin
                ? (
                    <div>
                        <p className="description">Čia galite koreguoti asmens gyvenimo įvykius.<br />Atlikę visus norimus pakeitimus nepamirškite &quot;Viską Išsaugoti <SaveOutlined />&quot;</p>
                        <div className="event-form">
                            <Select
                                placeholder="Pasirinkite gyvenimo įvikį"
                                onChange={handleSelectChange}
                                value={eventType}>
                                {eventIcons.map((element) => {
                                    if (!element.isLocked) {
                                        return (
                                            <Select.Option key={element.key} value={element.key}>
                                                {element.title}
                                            </Select.Option>
                                        );
                                    }
                                    return null;
                                })}
                            </Select>
                            <DatePicker
                                locale={locale}
                                onChange={handleDateChange} />
                            <Button type="primary" onClick={() => saveAllEvents()}>Viską įšsaugoti <SaveOutlined /></Button>
                        </div>
                        <div className="event-form">
                            <Input
                                maxLength={255}
                                className="text-input"
                                placeholder="Trumpas aprašymas"
                                onChange={(e) => setEventDescription(e.target.value)}
                                value={eventDescription} />
                        </div>
                        <div className="event-form">
                            <Button onClick={handleAddEvent} icon={<PlusOutlined />}>Pridėti įvikį</Button>
                        </div>
                    </div>
                )
                : null}
            <Timeline
                mode="alternate">
                {sortBy(events, 'date').map((event, index) => (
                    <Timeline.Item color="black"
                        key={index}
                        dot={(
                            <span className="custom-dot-size">
                                {event.data.icon}
                            </span>
                        )}>
                        <div className="timeline-text-wrapper">
                            <div className="timeline-item-title">{event.data.title}</div>
                            <div className="timeline-item-date">
                                <FontAwesomeIcon icon={faCalendarDays} />
                                {moment(event.date).format('MMMM DD, YYYY')}
                            </div>
                            {event.description && event.description !== '' ? (
                                <div className="timeline-item-description">
                                    {event.description}
                                </div>
                            ) : null}
                            {!disableAdmin && event.event_type !== 'BORN' && event.event_type !== 'PASSED_AWAY' ? (
                                <Button
                                    onClick={() => removeEvent(index)}
                                    className="remove-event-button"
                                    title="Pašalinti">
                                    <DeleteOutlined />
                                </Button>
                            ) : null}
                        </div>
                    </Timeline.Item>
                ))}
            </Timeline>
        </div>
    );
};

TimelineComponent.propTypes = {
    disableAdmin: PropTypes.bool,
};

TimelineComponent.defaultProps = {
    disableAdmin: false,
};

export default TimelineComponent;

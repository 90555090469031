import React, { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Form, Input, Button, Spin, Checkbox } from 'antd';
import { Link } from 'react-router-dom'; // import useParams
import './checkoutForm.scss';

import {
    ShoppingCartOutlined,
} from '@ant-design/icons';

import {
    getUserDataRequest,
} from '../../../core/user/userActions';

import {
    confirmPlanRequest,
} from '../../../core/payment/paymentActions';

const SettingsForm = () => {

    const dispatch = useDispatch();
    const user = useSelector(state => state.user);
    const payment = useSelector(state => state.payment);

    useEffect(() => {
        dispatch(getUserDataRequest());
    }, []);

    const [form] = Form.useForm();

    const onFinish = (formData) => {
        const validate = true;

        if (validate) {
            const data = {
                full_name: formData.full_name,
                city: formData.city,
                street: formData.street,
                house_number: formData.house_number,
                post_code: formData.post_code,
                phone: formData.phone,
                payment_plan_id: payment.singlePlan.id,
            };
            dispatch(confirmPlanRequest(data));
        }
    };

    const onFinishFailed = ({ errorFields }) => {
        form.scrollToField(errorFields[0].name);
    };

    return (
        <>
            <Spin spinning={user.isFetching}>
                <div className="settings-form">
                    <Form
                        onFinish={onFinish}
                        onFinishFailed={onFinishFailed}
                        initialValues={{
                            full_name: user?.userData?.profile?.full_name,
                            email: user?.userData?.email,
                            city: user?.userData?.profile?.city,
                            street: user?.userData?.profile?.street,
                            house_number: user?.userData?.profile?.house_number,
                            post_code: user?.userData?.profile?.post_code,
                            phone: user?.userData?.profile?.phone,
                        }}
                        layout="vertical">
                        <Form.Item
                            name="full_name"
                            label="Vardas Pavardė"
                            style={{ marginTop: '30px' }}
                            rules={[{ required: true, message: 'Prašome įvesti savo vardą, pavardę.' }]}>
                            <Input placeholder="Vardas Pavardė" />
                        </Form.Item>

                        <h3>Įsitikinkite, kad nurodėte pilną adresą bei pašto kodą. Nurodytu adresu Jums išsiųsime QR kodą.</h3>

                        <Form.Item
                            name="city"
                            label="Miestas"
                            style={{ marginTop: '30px' }}
                            rules={[{ required: true, message: 'Prašome įvesti miestą.' }]}>
                            <Input placeholder="Miestas" />
                        </Form.Item>

                        <Form.Item
                            name="street"
                            label="Gatvė"
                            style={{ marginTop: '30px' }}
                            rules={[{ required: true, message: 'Prašome įvesti gatvę.' }]}>
                            <Input placeholder="Gatvė" />
                        </Form.Item>

                        <Form.Item
                            name="house_number"
                            label="Namo buto numeris"
                            style={{ marginTop: '30px' }}
                            rules={[{ required: true, message: 'Prašome įvesti namo - buto numerį.' }]}>
                            <Input placeholder="10A - 12" />
                        </Form.Item>

                        <Form.Item
                            name="post_code"
                            label="Pašto kodas"
                            style={{ marginTop: '30px' }}
                            rules={[{ required: true, message: 'Prašome įvesti pašto kodą.' }]}>
                            <Input />
                        </Form.Item>

                        <Form.Item
                            name="phone"
                            label="Telefonas"
                            style={{ marginTop: '30px' }}
                            rules={[
                                { required: true, message: 'Prašome įvesti telefono numerį.' },
                                {
                                    pattern: /^[0-9+]*$/,
                                    message: 'Telefonas gali būti sudarytas tik iš skaičių ir simbolio \'+\'.',
                                },
                            ]}>
                            <Input />
                        </Form.Item>

                        <Form.Item
                            name="privacyPolicy"
                            valuePropName="checked"
                            style={{ marginTop: '30px' }}
                            rules={[
                                {
                                    validator: (_, value) => (value ? Promise.resolve() : Promise.reject(new Error('Norėdami tęsti turite sutikti su pirkimo taisyklėmis bei privatumo politika.'))),
                                },
                            ]}>
                            <Checkbox>
                                Aš susipažinau ir sutinku su <Link className="link" to="/privatumo-politika" target="_blank" rel="noopener noreferrer">Privatumo Politika</Link> ir <Link className="link" to="/pirkimo-taisykles" target="_blank" rel="noopener noreferrer">Pirkimo Taisyklės</Link>
                            </Checkbox>
                        </Form.Item>

                        <Form.Item>
                            <Button icon={<ShoppingCartOutlined />} type="primary" size="large" htmlType="submit" block className="mt-3 primary-btn-style" loading={user.isFetching}>
                                Patvirtinti užsakymą naudojant Paysera
                            </Button>
                        </Form.Item>
                    </Form>
                </div>
            </Spin>
        </>
    );
};

export default SettingsForm;

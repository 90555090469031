import { call, put, takeEvery, all, select } from 'redux-saga/effects';
import { message } from 'antd';
import lockr from 'lockr';
import { isEmpty } from 'underscore';
import { fetchApi, fetchApiAuth } from '../utils/api';
import history from '../utils/history';

import {

    registerSuccess,
    registerFailure,

    loginSuccess,
    loginFailure,

    logoutSuccess,
    logoutFailure,

    forgotPasswordSuccess,
    forgotPasswordFailure,

    resetPasswordSuccess,
    resetPasswordFailure,

    updateUserSuccess,
    updateUserFailure,

    getUserDataSuccess,
    getUserDataFailure,

    contactSuccess,
    contactFailure,

    contactPublicSuccess,
    contactPublicFailure,

    loginGoogleSuccess,
    loginGoogleFailure,
} from './userActions';

const {
    AUTH_KEY,
    LOCALSTORAGE_EXPIRES_KEY,
} = require('../constants').default;

const {
    REGISTER_REQUEST,
    LOGIN_REQUEST,
    LOGOUT_REQUEST,
    FORGOT_PASSWORD_REQUEST,
    RESET_PASSWORD_REQUEST,
    UPDATE_USER_REQUEST,
    GET_USER_DATA_REQUEST,
    CONTACT_REQUEST,
    CONTACT_PUBLIC_REQUEST,
    LOGIN_GOOGLE_REQUEST,
} = require('./userActions').constants;

function saveSessionToken(action) {
    const authDate = new Date();
    lockr.set(LOCALSTORAGE_EXPIRES_KEY, authDate);
    lockr.set(AUTH_KEY, action.data.token);
}

function* register({ payload }) {
    try {
        const response = yield call(fetchApi, {
            method: 'POST',
            url: '/register',
            body: payload,
        });

        if (response?.data?.token) {
            yield call(saveSessionToken, response);
            yield put(loginSuccess(response.data));
            history.push('/dashboard');
        }
        yield put(registerSuccess(response.data));

        setTimeout(() => {
            message.success('Registracija sėkmingai pavyko');
        }, 100);
    } catch (e) {
        message.error(e.response ? e.response.data.message : e);
        yield put(registerFailure(e.response ? e.response.data.message : e));
    }
}

function* makeLoginRequest(action) {
    try {
        const response = yield call(fetchApi, {
            method: 'POST',
            url: '/login',
            body: { username: action.payload.email, password: action.payload.password },
        });

        const state = yield select();
        if (response?.data?.token) {
            yield call(saveSessionToken, response);
            yield put(loginSuccess(response.data));

            if (!isEmpty(state?.user?.rememberPath)) {
                history.push(state?.user?.rememberPath);
            } else {
                history.push('/dashboard');
            }
        } else {
            yield put(loginFailure('login request failed'));
        }
    } catch (e) {
        message.error(e.response ? e.response.data.message : e);
        yield put(loginFailure(e.response ? e.response.data.message : e));
    }
}

function* logoutRequest() {
    try {
        // const response = yield call(fetchApiAuth, {
        //     method: 'POST',
        //     url: '/logout',
        //     body: {},
        // });
        lockr.rm(AUTH_KEY);
        lockr.rm(LOCALSTORAGE_EXPIRES_KEY);
        localStorage.clear();
        yield put(logoutSuccess());
        history.push('/');
    } catch (e) {
        yield put(logoutFailure(e.response ? e.response.data.message : e));
    }
}

function* forgotPassword({ payload }) {
    try {
        const response = yield call(fetchApi, {
            method: 'POST',
            url: '/reset-password',
            body: { email: payload.email },
        });

        yield put(forgotPasswordSuccess(response));
        message.success('Jeigu paskyra egzistuoja, netrukus gausite el. laišką su slaptažodžio atstatymo nuoroda.');
    } catch (e) {
        message.error(e.response ? e.response.data.message : e);
        yield put(forgotPasswordFailure(e.response ? e.response?.data?.errors?.email[0] : e));
    }
}

function* resetPassword({ payload }) {
    try {
        const response = yield call(fetchApi, {
            method: 'POST',
            url: 'reset-password',
            body: {
                email: payload.email,
                password: payload.password,
                password_confirmation: payload.passwordConfirmation,
                token: payload.token,
            },
        });

        yield put(resetPasswordSuccess(response));
        message.success('Password updated successfully');
        history.push('/');
    } catch (e) {
        message.error(e.response ? e.response.data.message : e);
        yield put(resetPasswordFailure(e.response ? e.response.data.message : e));
    }
}

function* updateUser({ payload }) {
    try {
        const response = yield call(fetchApiAuth, {
            method: 'POST',
            url: '/account/update-user',
            body: payload,
        });

        message.success('Informacija sėkmingai atnaujinta');
        yield put(updateUserSuccess(response));
    } catch (e) {
        message.error(e.response ? e.response.data.message : e);
        yield put(updateUserFailure(e.response ? e.response.data.message : e));
    }
}

function* getUserData() {
    try {
        const response = yield call(fetchApiAuth, {
            method: 'GET',
            url: '/account/get-user-data',
        });

        yield put(getUserDataSuccess(response));
    } catch (e) {
        message.error(e.response ? e.response.data.message : e);
        yield put(getUserDataFailure(e.response ? e.response.data.message : e));
    }
}

function* contact({ payload }) {
    try {
        const response = yield call(fetchApiAuth, {
            method: 'POST',
            url: '/contact',
            body: payload,
        });

        setTimeout(() => message.success('Jūsų žinutė sėkmingai išsiūsta'), 10);
        yield put(contactSuccess(response));
    } catch (e) {
        message.error(e.response ? e.response.data.message : e);
        yield put(contactFailure(e.response ? e.response.data.message : e));
    }
}

function* contactPublic({ payload }) {
    try {
        const response = yield call(fetchApi, {
            method: 'POST',
            url: '/contact-public',
            body: payload,
        });

        message.success('Jūsų žinutė sėkmingai išsiūsta');
        yield put(contactPublicSuccess(response));
    } catch (e) {
        message.error(e.response ? e.response.data.message : e);
        yield put(contactPublicFailure(e.response ? e.response.data.message : e));
    }
}

function* loginGoogle({ payload }) {
    try {
        const response = yield call(fetchApi, {
            method: 'POST',
            url: 'auth/login-google',
            body: payload,
        });

        const state = yield select();
        if (response?.data?.token) {
            yield call(saveSessionToken, response);
            yield put(loginGoogleSuccess(response.data));

            if (!isEmpty(state?.user?.rememberPath)) {
                history.push(state?.user?.rememberPath);
            } else {
                history.push('/dashboard');
            }
        } else {
            yield put(loginGoogleFailure('login request failed'));
        }
    } catch (e) {
        message.error(e.response ? e.response.data.message : e);
        yield put(loginGoogleFailure(e.response ? e.response.data.message : e));
    }
}

/**
 * Watch actions
 */
export default function* userSaga() {
    yield all([
        takeEvery(REGISTER_REQUEST, register),
        takeEvery(LOGIN_REQUEST, makeLoginRequest),
        takeEvery(LOGOUT_REQUEST, logoutRequest),
        takeEvery(FORGOT_PASSWORD_REQUEST, forgotPassword),
        takeEvery(RESET_PASSWORD_REQUEST, resetPassword),
        takeEvery(UPDATE_USER_REQUEST, updateUser),
        takeEvery(GET_USER_DATA_REQUEST, getUserData),
        takeEvery(CONTACT_REQUEST, contact),
        takeEvery(CONTACT_PUBLIC_REQUEST, contactPublic),
        takeEvery(LOGIN_GOOGLE_REQUEST, loginGoogle),
    ]);
}

import React, { useEffect } from 'react';
import { Helmet } from 'react-helmet';

import useUser from '../../core/user/useUser';
import history from '../../core/utils/history';
import LoginForm from '../../components/Form/LoginForm';
import logoSrcNew from '../../assets/images/tvg-lotus-white-3.svg';
// import bgSrcMob from '../../assets/images/bg-mobile@2x.png';
import bgSrcDeskt from '../../assets/images/bg@2x.png';
import icon from '../../assets/favicon.ico';
import './login.scss';
import gravePlaceholder from '../../assets/images/grave-placeholder.webp';

const { APP_NAME } = require('../../core/constants').default;

function LoginPage() {

    const { user } = useUser();

    useEffect(() => {

        if (user?.isLoggedIn === true) {
            history.push('/dashboard');
        }
    }, []);

    return (
        <div className="login-wrap">
            <Helmet
                title="Prisijunkite arba registruokitės"
                titleTemplate={`${APP_NAME} - %s`}
                defaultTitle={`${APP_NAME}`}
                meta={[
                    { name: 'description', content: 'Prisijunkite arba registruokitės Virtualus Kapas - revoliucinėje platformoje, kuri leidžia kurti virtualius kapus ir prisiminti savo artimuosius. Sukurkite savo paskyrą ir pradėkite kurti savo virtualų kapą šiandien.' },
                    { property: 'og:type', content: 'website' },
                ]}>
                <link rel="shortcut icon" href={icon} />
            </Helmet>
            <div className="login-info" style={{ backgroundImage: `url(${bgSrcDeskt})` }}>
                <div className="login-info__text">
                    <div className="login-info-inner">
                        <div className="logo-wrap white" onClick={() => history.push('/')}>
                            <img src={logoSrcNew} loading="lazy" alt="" className="lotus-img" />
                            <div className="logo-text">Virtualus Kapas</div>
                        </div>
                        { /* <p className="logo-title">VIRTUALUS KAPAS</p> */ }
                        <img className="image-virtual-grave" src={gravePlaceholder} alt="virtualus kapas" />
                        <p className="description">
                            Sveiki atvykę į mūsų „Virtualus Kapas“ svetainę – platformą, kuri iš naujo apibrėžia, kaip prisimename ir gerbiame savo artimuosius.
                            Sklandžiai sujungdami fizinę ir skaitmeninę atminimo sferas, suteikiame unikalią, interaktyvią ir ilgalaikę patirtį draugams ir šeimos nariams,
                            kad galėtų puoselėti prisiminimus apie anapilin išėjusius artimuosius.
                        </p>
                    </div>
                </div>
            </div>
            <LoginForm />
        </div>
    );
}
export default LoginPage;

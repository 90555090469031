import React from 'react';
import { Link } from 'react-router-dom';
import { Col, Row } from 'antd';
import Lottie from 'lottie-react';

import hpImg4 from '../../assets/images/hp-img-4.webp';
import mobileAnimationBottom from '../../assets/documents/animation_lna0wyjp.json';

import './itsFuture.scss';

const ItsFuture = () => (
    <div className="future-block">
        <div className="general-container">
            <div className="card with-img">
                <Row>
                    <Col xs={24} sm={24} md={12} lg={12} xl={12} className="left-container">
                        <div className="copy-side">
                            <h2>Tai ateitis</h2>
                            <p>Priimkite įamžinimo ateitį ir prisijunkite prie augančios vartotojų bendruomenės, kuri atranda mūsų unikalios platformos pranašumus. Pradėkite šiandien kurdami virtualų kapą savo mylimam žmogui ir išbandykite naują būdą pagerbti ir prisiminti jį, kad ir kur būtumėte.</p>
                            <Link to="/prisijungti" className="button w-button">Prisijungti dabar</Link>
                        </div>
                    </Col>
                    <Col xs={24} sm={24} md={12} lg={12} xl={12} className="right-container">
                        <div className="img-side">
                            <img src={hpImg4} loading="lazy" alt="" className="side-img" />
                        </div>
                    </Col>
                </Row>
                <div className="parallax-roundel-wrap future">
                    <Lottie animationData={mobileAnimationBottom} loop />
                </div>
            </div>
        </div>
    </div>
);

export default ItsFuture;

import React, { useRef, useState } from 'react';
import PropTypes from 'prop-types';
import Helmet from 'react-helmet';
import { Layout, Menu, Drawer } from 'antd';
import {
    DesktopOutlined,
    CreditCardOutlined,
    SettingOutlined,
    QuestionCircleOutlined,
    HomeOutlined,
    CloseOutlined,
} from '@ant-design/icons';

import { Link, useLocation } from 'react-router-dom';

import history from '../../../core/utils/history';
import BreadcrumbNav from '../BreadcrumbNav';
import Header from '../Header';
import Footer from '../Footer';
import './main.css';
import Logout from '../../Logout';

import LogoWrap from '../../LogoWrap';
import favIcon from '../../../assets/favicon.ico';
import logoImage from '../../../assets/images/tvg-lotus-3.svg';

import { GraveIcon } from '../../Elements/CustomSVGIcon';

const {
    APP_NAME,
} = require('../../../core/constants').default;

const Main = (props) => {

    const { Sider } = Layout;
    const location = useLocation();
    const contentRef = useRef();
    const [open, setOpen] = useState(false);

    const getItem = (label, key, icon, children) => ({
        key,
        icon,
        children,
        label,
    });

    const showDrawer = () => {
        setOpen(true);
    };

    const onClose = () => {
        setOpen(false);
    };

    const items = [
        getItem('Darbalaukis', '/dashboard', <DesktopOutlined />),
        getItem('Mano Kapai', '/graves', <GraveIcon w={18} h={18} className="custom-icon" />),
        getItem('Mokėjimo Planai', '/payment-plans', <CreditCardOutlined />),
        // getItem('User', 'sub1', <UserOutlined />, [
        //     getItem('Tom', '3'),
        //     getItem('Bill', '4'),
        //     getItem('Alex', '5'),
        // ]),
        getItem('Nustatymai', '/settings', <SettingOutlined />),
        getItem('Pagalba', '/help', <QuestionCircleOutlined />),
        getItem('<-- Grįžti į pagrindį puslapį', '/', <HomeOutlined />),
    ];

    const {
        children, title, toolbar, breadcrumb, className, menu, backLink,
        showHeader, beforeHeader, afterHeader, floatingHeader, headerTitle,
        showFooter, beforeFooter, afterFooter, floatingFooter, footerContent,
        menuPlacement, menuWidth,
    } = props;

    return (
        <div className={`page-wrap ${className}`}>
            <Helmet
                title={typeof title === 'object' ? title.props.children : title}
                titleTemplate={`%s - ${APP_NAME}`}
                defaultTitle={`${APP_NAME}`}
                meta={[
                    { name: 'description', content: `${APP_NAME} website` },
                    { property: 'og:type', content: 'website' },
                ]}>
                <link rel="shortcut icon" href={favIcon} />
            </Helmet>
            <Layout>
                <Sider
                    className="sider"
                    theme="light">
                    <LogoWrap />
                    <div className="menu-wrapper">
                        <Menu
                            theme="light"
                            defaultSelectedKeys={[location?.pathname]}
                            mode="inline"
                            items={items}
                            onClick={(data) => history.push(data.key)} />
                    </div>
                </Sider>
                <Layout className="site-layout">
                    {showHeader
                        ? (
                            <Header
                                beforeheader={beforeHeader}
                                afterHeader={afterHeader}
                                floating={floatingHeader}
                                title={headerTitle || title}
                                toolbar={toolbar}
                                backLink={backLink}
                                menu={menu}
                                menuPlacement={menuPlacement}
                                menuWidth={menuWidth}
                                showDrawer={() => showDrawer()} />
                        )
                        : null}
                    <Layout.Content className="main-content" ref={contentRef}>
                        {breadcrumb ? <BreadcrumbNav navigation={breadcrumb} /> : null}
                        {children}
                        {showFooter
                            ? (
                                <Footer
                                    beforeFooter={beforeFooter}
                                    afterFooter={afterFooter}
                                    floating={floatingFooter}>
                                    {footerContent}
                                </Footer>
                            )
                            : null}
                    </Layout.Content>
                </Layout>
            </Layout>
            <Drawer
                placement="left"
                closable={false}
                onClose={onClose}
                open={open}>
                <div className="main-site-drawer">
                    <div className="side-menu-header">
                        <div className="close-button" onClick={() => onClose()}>
                            <CloseOutlined />
                        </div>
                    </div>
                    <div className="drawer-header">
                        <Link to="/" className="logo-link">
                            <div className="logo-wrap">
                                <img src={logoImage} loading="lazy" alt="" className="lotus-img" />
                                <div className="logo-text">Virtualus Kapas</div>
                            </div>
                        </Link>
                    </div>
                    <div className="drawer-menu">
                        <div className="menu-wrapper">
                            <Menu
                                theme="light"
                                defaultSelectedKeys={[location?.pathname]}
                                mode="inline"
                                items={items}
                                onClick={(data) => history.push(data.key)} />
                            <Logout />
                        </div>
                    </div>
                </div>
            </Drawer>
        </div>
    );
};

Main.defaultProps = {
    afterFooter: null,
    afterHeader: null,
    backLink: null,
    beforeFooter: null,
    beforeHeader: null,
    breadcrumb: null,
    children: false,
    className: '',
    floatingFooter: false,
    floatingHeader: false,
    footerContent: null,
    headerTitle: null,
    logo: null,
    menu: [],
    menuPlacement: 'left',
    menuWidth: 256,
    showFooter: true,
    showHeader: true,
    toolbar: [],
};

Main.propTypes = {
    afterFooter: PropTypes.object,
    afterHeader: PropTypes.object,
    backLink: PropTypes.oneOfType([PropTypes.object, PropTypes.func]),
    beforeFooter: PropTypes.object,
    beforeHeader: PropTypes.object,
    breadcrumb: PropTypes.oneOfType([PropTypes.object, PropTypes.array]),
    children: PropTypes.node,
    className: PropTypes.string,
    floatingFooter: PropTypes.bool,
    floatingHeader: PropTypes.bool,
    footerContent: PropTypes.oneOfType([PropTypes.string, PropTypes.object]),
    headerTitle: PropTypes.oneOfType([PropTypes.string, PropTypes.object]),
    logo: PropTypes.oneOfType([PropTypes.string, PropTypes.object]),
    menu: PropTypes.array,
    menuPlacement: PropTypes.string,
    menuWidth: PropTypes.number,
    showFooter: PropTypes.bool,
    showHeader: PropTypes.bool,
    title: PropTypes.node.isRequired,
    toolbar: PropTypes.array,
};

export default Main;

import React from 'react';
import { Button } from 'antd';
import {
    LogoutOutlined,
} from '@ant-design/icons';

import useUser from '../../core/user/useUser';
import './logout.scss';

const Logout = () => {

    const { logoutRequest } = useUser();

    const handleLogout = (e) => {
        e.preventDefault();
        logoutRequest();
    };

    return (
        <>
            <Button icon={<LogoutOutlined />} onClick={handleLogout} type="text" htmlType="submit" className="wide">
                Atsijungti
            </Button>
        </>
    );
};

export default Logout;

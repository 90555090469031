import React from 'react';
import PropTypes from 'prop-types';
import { Dropdown, Button } from 'antd';
import {
    UserOutlined,
    SettingOutlined,
    MenuOutlined,
} from '@ant-design/icons';

import { useSelector } from 'react-redux';

import './header.css';

import { Link } from 'react-router-dom';
import history from '../../../core/utils/history';
import logoImage from '../../../assets/images/tvg-lotus-3.svg';
import Logout from '../../Logout';

const items = [
    {
        key: '2',
        label: (
            <Button icon={<SettingOutlined />} onClick={() => history.push('/settings')} type="text" htmlType="submit" className="wide">
                Nustatymai
            </Button>
        ),
    },
    {
        key: '1',
        label: (
            <Logout />
        ),
    },
];

// import logo from '../../../assets/img/brand/logo.png';

const Header = (props) => {
    const {
        beforeHeader, afterHeader, showDrawer,
    } = props;
    const user = useSelector(state => state.user);

    return (
        <div className="header">
            {beforeHeader}
            <div className="header-content-wrapper">
                <Link to="/dashboard" className="logo-link">
                    <div className="logo-wrap">
                        <img src={logoImage} loading="lazy" alt="" className="lotus-img" />
                        <div className="logo-text">Virtualus Kapas</div>
                    </div>
                </Link>
                <p className="header-disclaimer">Gyventi širdyse! Čia mes galime visada prisiminti ir pagerbti mūsų mylimus žmones.</p>
                <Dropdown
                    overlayClassName="dropdown-overlay"
                    menu={{
                        items,
                    }}
                    placement="bottomLeft">
                    <div className="profile-title"><UserOutlined /><span>{user?.userData?.profile?.full_name}</span></div>
                </Dropdown>
                <div className="w-nav-button" onClick={() => showDrawer()}>
                    <MenuOutlined />
                </div>
            </div>
            {afterHeader}
        </div>
    );
};

Header.defaultProps = {
    toolbar: [],
    menu: [],
    backLink: null,
    logo: null,
    menuPlacement: 'left',
    menuWidth: 256,
    floating: false,
    beforeHeader: null,
    afterHeader: null,
    title: null,
    showDrawer: () => {},
};

Header.propTypes = {
    title: PropTypes.oneOfType([PropTypes.object, PropTypes.string]),
    toolbar: PropTypes.array,
    menu: PropTypes.array,
    backLink: PropTypes.oneOfType([PropTypes.object, PropTypes.func]),
    logo: PropTypes.oneOfType([PropTypes.object, PropTypes.string]),
    menuPlacement: PropTypes.string,
    menuWidth: PropTypes.number,
    floating: PropTypes.bool,
    beforeHeader: PropTypes.object,
    afterHeader: PropTypes.object,
    showDrawer: PropTypes.func,
};

export default Header;

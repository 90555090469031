import React, { useEffect, useState } from 'react';
import { Col, Row, Spin, Modal } from 'antd';
import { useDispatch, useSelector } from 'react-redux';
import { Helmet } from 'react-helmet';
import { PlayCircleFilled } from '@ant-design/icons';

import SiteNavigation from '../../components/SiteNavigation';
import SiteFooter from '../../components/SiteFooter';
import ItsFuture from '../../components/ItsFuture';

import {
    getPaymentPlansRequest,
} from '../../core/payment/paymentActions';

import icon from '../../assets/favicon.ico';
import flowersLeft from '../../assets/images/videos-bg.webp';
import hpImg4 from '../../assets/images/hp-img-4.webp';

/* eslint-disable no-unused-vars */
import video1 from '../../assets/images/video-1.webp';
import video2 from '../../assets/images/video-2.webp';
import video3 from '../../assets/images/video-3.webp';
import video4 from '../../assets/images/video-4.webp';
import video5 from '../../assets/images/video-5.webp';

import './videos.scss';

const { APP_NAME } = require('../../core/constants').default;

const videoPlaceholders = [video1, video2, video3, video4, video5];

const videos = [
    {
        title: 'Virtualaus kapo veikimo pavyzdys',
        videoUrl: 'https://www.youtube.com/embed/YDWZFtl_9os?si=jx-lyu9UQjjDhNXv',
    },
    {
        title: 'Virtualaus kapo veikimo pavyzdys',
        videoUrl: 'https://www.youtube.com/embed/AphI1oII6P4?si=WN9V17yisJsStTBf',
    },
];

const Videos = () => {

    const dispatch = useDispatch();
    const payment = useSelector(state => state.payment);
    const [selectedVideo, setSelectedVideo] = useState(null);
    const [isVideoModalOpen, setIsVideoModalOpen] = useState(false);

    useEffect(() => {
        dispatch(getPaymentPlansRequest());
    }, []);

    const openVideo = (video) => {
        setSelectedVideo(video);
        setIsVideoModalOpen(true);
    };

    const renderSingleVideo = (video, key) => {
        const imageSrc = videoPlaceholders[key]; // key - 1 to match index (assuming key starts from 1)
        return (
            <Col xs={24} sm={24} md={12} lg={12} xl={12} key={`single-video-${key}`}>
                <div className="single-video-wrapper" onClick={() => openVideo(video)}>
                    <div className="placeholder-wrapper">
                        <img src={imageSrc} alt="video placeholder" className="video-placeholder" />
                        <PlayCircleFilled fill="white" />
                    </div>
                    <p className="video-title">{video?.title}</p>
                </div>
            </Col>
        );
    };

    return (
        <div className="videos-page">
            <Helmet
                title="Video"
                titleTemplate={`${APP_NAME} - %s`}
                defaultTitle={`${APP_NAME}`}
                meta={[
                    { name: 'description', content: 'Atraskite „Virtualus Kapas“ – unikalų būdą išsaugoti ir pagerbti artimųjų atminimą su mūsų instrukciniais video. Žiūrėkite mūsų platformos pristatomąjį video ir mokymosi filmukus, kaip efektyviai naudotis virtualių kapų sukūrimo funkcijomis. Sukurkite tiltą tarp praeities ir dabarties, bet kada ir bet kur.' },
                    { property: 'og:type', content: 'website' },
                    { property: 'og:image', content: hpImg4 },
                ]}>
                <link rel="shortcut icon" href={icon} />
            </Helmet>
            <SiteNavigation />
            <div className="videos-block">
                <img src={flowersLeft} alt="flowers" className="videos-background" />
                <div className="block-inner">
                    <Row>
                        <Col xs={24} sm={24} md={24} lg={24} xl={24}>
                            <div className="title-wrapper">
                                <h1>Video</h1>
                            </div>

                            <Spin spinning={payment.isFetching}>
                                <div className="videos-wrapper">
                                    <Row className="videos-row">
                                        {videos
                                            .map((item, index) => renderSingleVideo(item, index))}
                                    </Row>
                                </div>
                            </Spin>

                        </Col>
                    </Row>
                </div>
            </div>
            <ItsFuture />
            <SiteFooter />
            <Modal width={1000} className="video-modal" title="Video" open={isVideoModalOpen} onCancel={() => setIsVideoModalOpen(false)} footer={false}>
                <iframe width="560" height="600" src={selectedVideo?.videoUrl} title="YouTube video player" frameBorder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share" allowFullScreen />
            </Modal>
        </div>
    );
};

export default Videos;

Videos.propTypes = {};
